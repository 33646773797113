import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as Const from '../constants';
import { create_feature } from '../utilities/Features';
import { geodetic2enu, enu2geodetic } from '../utilities/Geodetic';
import {
  distance_between_points,
  interpolate_along_line,
  percentage_along_line,
  project_point_to_line,
} from '../utilities/Geometry';
import * as turf from '@turf/turf';

import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';

const doubleClickZoom = {
  enable: (ctx) => {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map ||
        !ctx.map.doubleClickZoom ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      )
        return;

      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
      ctx.map.doubleClickZoom.enable();
    }, 0);
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;

      ctx.map.doubleClickZoom.disable();
    }, 0);
  },
};

const removeMetaProperties = (feature) => {
  delete feature.properties.id;
  delete feature.properties.meta;
  delete feature.properties.parent;
  delete feature.properties.coord_path;
  delete feature.properties.active;
  return feature;
};

const MINIMUM_WIDTH = 1.0;

const ParkingDesignerMode = {
  onSetup: function (opts) {
    var feature = create_feature(Const.StaticObjectType.PARKING_REGION, [[]]);
    const rectangle = this.newFeature(feature);
    this.addFeature(rectangle);

    const parking_spaces = [];
    const width_offset1 = MINIMUM_WIDTH;
    const width_offset2 = 0.0;

    this.clearSelectedFeatures();
    this.map.getCanvas().style.cursor = 'crosshair';
    doubleClickZoom.disable(this);
    this.setActionableState({
      trash: true,
    });
    return {
      rectangle,
      currentVertexPosition: 0,
      parking_spaces,
      width_offset1,
      width_offset2,
    };
  },

  getProjectedPoint: function (state, lnglat, offset) {
    const origin = state.rectangle.getCoordinate('0.0');
    const mouse_enu = geodetic2enu(lnglat, origin);

    const point1 = [0.0, 0.0, 0.0];
    const point2 = geodetic2enu(state.rectangle.getCoordinate('0.1'), origin);
    const point3 = geodetic2enu(
      state.rectangle.getCoordinate(
        `0.${state.rectangle.coordinates[0].length + offset - 2}`
      ),
      origin
    );
    const point4 = geodetic2enu(
      state.rectangle.getCoordinate(
        `0.${state.rectangle.coordinates[0].length + offset - 1}`
      ),
      origin
    );
    const line1 = [point1, point2];
    const line2 = [point4, point3];
    const [projected_point, t] = project_point_to_line(mouse_enu, line2);
    const projected_point_geo = enu2geodetic(projected_point, origin);
    return [
      projected_point_geo,
      distance_between_points(projected_point, line2[1]),
    ];
  },

  onTap: function (state, e) {
    this.onClick(state, e);
  },

  onClick: function (state, e) {
    if (state.currentVertexPosition < 2) {
      state.rectangle.updateCoordinate(
        `0.${state.currentVertexPosition}`,
        e.lngLat.lng,
        e.lngLat.lat
      );
      state.currentVertexPosition++;
      state.rectangle.updateCoordinate(
        `0.${state.currentVertexPosition}`,
        e.lngLat.lng,
        e.lngLat.lat
      );
    } else if (state.currentVertexPosition === 2) {
      const [getpXY2, getpXY3] = this.calculatepXY3(state, e, false);
      if (getpXY3) {
        state.rectangle.updateCoordinate(
          `0.${state.currentVertexPosition}`,
          getpXY2[0],
          getpXY2[1]
        );
        state.currentVertexPosition++;
        state.rectangle.updateCoordinate(
          `0.${state.currentVertexPosition}`,
          getpXY3[0],
          getpXY3[1]
        );
      }
    }
  },
  onMouseMove: function (state, e) {
    if (state.currentVertexPosition < 3) {
      state.rectangle.updateCoordinate(
        `0.${state.currentVertexPosition}`,
        e.lngLat.lng,
        e.lngLat.lat
      );
    }
    if (state.currentVertexPosition && state.currentVertexPosition > 0) {
      this.calculateOrientedAnglePolygon(state);
    }

    if (state.currentVertexPosition === 2) {
      const [getpXY2, getpXY3] = this.calculatepXY3(state, e, true);
      if (getpXY3) {
        state.rectangle.updateCoordinate(
          `0.${state.currentVertexPosition + 1}`,
          getpXY3[0],
          getpXY3[1]
        );
      }

      const [projected_point_geo, width] = this.getProjectedPoint(
        state,
        [e.lngLat.lng, e.lngLat.lat],
        0
      );
      state.width_offset1 = Math.max(width, MINIMUM_WIDTH);
    } else if (state.currentVertexPosition > 2) {
      const [projected_point_geo, width] = this.getProjectedPoint(
        state,
        [e.lngLat.lng, e.lngLat.lat],
        -1
      );
      state.width_offset2 = width;
    }
  },

  calculateOrientedAnglePolygon: function (state) {
    const p1 = geodetic2enu(
      state.rectangle.getCoordinate('0.1'),
      state.rectangle.getCoordinate('0.0')
    );
    state.angle = Math.atan2(p1[1], p1[0]);
  },

  calculatepXY3: function (state, e, tmp, set_points = true) {
    const origin = state.rectangle.getCoordinate('0.0');
    const pXY0_3857 = [0.0, 0.0, 0.0];
    const pXY1_3857 = geodetic2enu(
      state.rectangle.getCoordinate('0.1'),
      origin
    );

    let pXY2_3857 = geodetic2enu([e.lngLat.lng, e.lngLat.lat], origin);
    const mouse_3857 = geodetic2enu([e.lngLat.lng, e.lngLat.lat], origin);

    if (pXY0_3857[0] === pXY1_3857[0]) {
      pXY2_3857 = [mouse_3857[0], pXY1_3857[1]];
    } else if (pXY0_3857[1] === pXY1_3857[1]) {
      pXY2_3857 = [pXY1_3857[0], mouse_3857[1]];
    } else {
      const vector1_3857 =
        (pXY1_3857[1] - pXY0_3857[1]) / (pXY1_3857[0] - pXY0_3857[0]);
      const vector2_3857 = -1.0 / vector1_3857;

      if (Math.abs(vector2_3857) < 1) {
        pXY2_3857[1] =
          vector2_3857 * (mouse_3857[0] - pXY1_3857[0]) + pXY1_3857[1];
      } else {
        pXY2_3857[0] =
          pXY1_3857[0] + (pXY2_3857[1] - pXY1_3857[1]) / vector2_3857;
      }
    }

    const vector_3857 = [
      pXY1_3857[0] - pXY0_3857[0],
      pXY1_3857[1] - pXY0_3857[1],
    ];
    const pXY3_3857 = [
      pXY2_3857[0] - vector_3857[0],
      pXY2_3857[1] - vector_3857[1],
    ];

    const pXY2G = enu2geodetic(pXY2_3857, origin);
    const pXY3G = enu2geodetic(pXY3_3857, origin);

    if (set_points) {
      state.rectangle.updateCoordinate('0.2', pXY2G[0], pXY2G[1]);
      state.rectangle.updateCoordinate(
        `0.${state.currentVertexPosition + 1}`,
        pXY3G[0],
        pXY3G[1]
      );
    }

    state.rectangle.properties.length = distance_between_points(
      pXY1_3857,
      pXY2_3857
    );

    return [pXY2G, pXY3G];
  },

  onKeyUp: function (state, e) {
    if (e.keyCode === 27) return this.changeMode('simple_select');
  },
  onStop: function (state) {
    doubleClickZoom.enable(this);
    this.map.getCanvas().style.cursor = 'unset';
    this.activateUIButton();

    // check to see if we've deleted this feature
    if (this.getFeature(state.rectangle.id) === undefined) return;

    //remove last added coordinate
    if (state.rectangle.isValid()) {
      this.map.fire('draw.create', {
        features: [state.rectangle.toGeoJSON()],
      });
    } else {
      this.deleteFeature([state.rectangle.id], {
        silent: true,
      });
      this.changeMode(
        'simple_select',
        {},
        {
          silent: true,
        }
      );
    }
  },
  toDisplayFeatures: function (state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.rectangle.id;
    geojson.properties.active = isActivePolygon ? 'true' : 'false';
    geojson.properties.angle = state.angle;
    geojson.angle = state.angle;
    if (!isActivePolygon) return display(geojson);

    const coordinateCount = geojson.geometry.coordinates[0].length;
    if (coordinateCount < 3) {
      return;
    }
    if (coordinateCount >= 3 && coordinateCount <= 4) {
      const lineCoordinates = [
        [
          geojson.geometry.coordinates[0][0][0],
          geojson.geometry.coordinates[0][0][1],
        ],
        [
          geojson.geometry.coordinates[0][1][0],
          geojson.geometry.coordinates[0][1][1],
        ],
      ];

      var feature = turf.lineString(lineCoordinates, geojson.properties);
      feature.angle = state.angle;
      display(feature);
      if (coordinateCount === 3) {
        return;
      }
    }
    if (coordinateCount >= 5 && coordinateCount <= 7) {
      // Interpolate line
      const origin = geojson.geometry.coordinates[0][0];
      const point1 = [0.0, 0.0, 0.0];
      const point2 = geodetic2enu(geojson.geometry.coordinates[0][1], origin);
      const point3 = geodetic2enu(
        geojson.geometry.coordinates[0][
          geojson.geometry.coordinates[0].length - 3
        ],
        origin
      );
      const point4 = geodetic2enu(
        geojson.geometry.coordinates[0][
          geojson.geometry.coordinates[0].length - 2
        ],
        origin
      );
      const line1 = [point1, point2];
      const line2 = [point4, point3];
      const line_length = distance_between_points(line1[0], line1[1]);

      const n_segments = parseInt(line_length / state.width_offset1);
      var width = line_length / n_segments;

      state.rectangle.properties.rough_width = width;

      const polygons = [];
      for (var i = 1; i <= n_segments; i++) {
        const front_left = enu2geodetic(
          interpolate_along_line(i * width, line1),
          origin
        ).slice(0, 2);
        const rear_left = enu2geodetic(
          interpolate_along_line(i * width, line2),
          origin
        ).slice(0, 2);
        const rear_right = enu2geodetic(
          interpolate_along_line((i - 1) * width, line2),
          origin
        ).slice(0, 2);
        const front_right = enu2geodetic(
          interpolate_along_line((i - 1) * width, line1),
          origin
        ).slice(0, 2);

        var polygon = create_feature(
          Const.StaticObjectType.PARKING,
          [[front_left, rear_left, rear_right, front_right, front_left]],
          {
            id: `${i - 1}`,
            parking_id: `parking_${i - 1}`,
            meta: Constants.meta.FEATURE,
            parent: state.rectangle.id,
            coord_path: `0.0`,
            active: 'false',
          }
        );
        polygons.push(polygon);
        display(polygon);
      }
      state.parking_spaces = polygons;
    }
    return display(geojson);
  },
  onTrash: function (state) {
    this.deleteFeature([state.rectangle.id], {
      silent: true,
    });
    this.changeMode('simple_select');
  },
};

export default ParkingDesignerMode;
