import React, { useContext, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import AppContext from './AppContext';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {
  fixDockDoors,
  sortFeatures,
  validateFeatures,
  cleanFeature,
  clean_all_features,
} from '../../utilities/Validator';
import stringify from 'json-stable-stringify';

import '../css/Export.css';

import * as Const from '../../constants';

const Export = (props) => {
  const { isMapboxLoaded, currentFileName, draw } = useContext(AppContext);

  var temp = { All: false };
  Object.values(Const.StaticObjectType).map((s) => (temp[s] = true));
  temp['Survey'] = false;
  temp['Path'] = false;
  temp['ParkingRegion'] = false;
  delete temp['Debug'];
  const [layers, setLayers, layersRef] = useStateRef(temp);

  const [reduceFileSize, setReduceFileSize] = useState(true);
  const [fileSize, setFileSize] = useState(null);

  const handleReduceFileSizeClick = () => {
    setReduceFileSize(!reduceFileSize);
  };

  useEffect(() => {
    if (isMapboxLoaded) {
      var data = filteredData();
      const space = reduceFileSize === true ? 0 : 2;
      const size = formatBytes(
        jsonSize(stringify(data, { replacer: null, space: space }))
      );
      setFileSize(size);
    }
  }, [props, reduceFileSize, layers]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const bytes = (s) => {
    return ~-encodeURI(s).split(/%..|./).length;
  };

  const jsonSize = (s) => {
    return bytes(stringify(s));
  };

  const filteredData = () => {
    var data = draw.getAll();
    data.features = data.features.filter(
      (f) => f.properties.static_type !== Const.StaticObjectType.DEBUG
    );
    data.features = clean_all_features(data.features);
    data.features.forEach((f) => cleanFeature(f));
    data.features = fixDockDoors(data.features);
    var layersToRemove = Object.keys(layersRef.current).filter(
      (key) => layersRef.current[key] === false && key !== 'All'
    );
    for (var i = data.features.length - 1; i >= 0; i--) {
      // Remove unwanted layers
      if (
        layersToRemove.includes(data.features[i].properties.static_type) ===
        true
      ) {
        data.features.splice(i, 1);
        continue;
      }

      // Remove disabled layers
      if (data.features[i].properties.disabled === true) {
        data.features.splice(i, 1);
        continue;
      } else {
        // Remove the disabled property before exporting
        delete data.features[i].properties.disabled;
      }
    }
    return data;
  };

  const onExportForBeyondClicked = () => {
    var data = filteredData();
    if (data.features.length > 0) {
      const space = reduceFileSize === true ? 0 : 2;
      data.features = sortFeatures(data.features);
      var [validFeatures, invalidFeatures] = validateFeatures(data.features);
      if (invalidFeatures.length !== 0)
        alert(invalidFeatures.length + ' invalid feature(s)!');
      data.connections = {};
      var file = new Blob(
        [stringify(data, { replacer: null, space: space }) + '\n'],
        {
          type: 'application/json',
        }
      );
      var a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = currentFileName;
      a.click();
      props.onHide();
    }
  };

  const handleFilterClick = ({ target }) => {
    const id = target.id.replace('export_', '');
    setLayers((s) => ({ ...s, [id]: !s[id] }));

    if (id === 'All') {
      const layerCopy = { ...layersRef.current };
      Object.keys(layerCopy).forEach(
        (key) => (layerCopy[key] = target.checked)
      );
      setLayers(layerCopy);
    } else {
      const visibleLayers = Object.keys(layersRef.current)
        .filter((k) => layersRef.current[k])
        .filter((l) => l !== 'All');
      if (visibleLayers.length < Object.keys(layersRef.current).length - 1) {
        setLayers((s) => ({ ...s, All: false }));
      } else {
        setLayers((s) => ({ ...s, All: true }));
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            as={Row}
            className="mx-2 bg-transparent"
            controlId="formLayers"
          >
            <Form.Label column sm={2}>
              <h6>Layers</h6>
            </Form.Label>
            <Col sm={10} className="export-layers-container">
              {Object.keys(layersRef.current).map((key) => (
                <Form.Check
                  key={'export_' + key}
                  label={key}
                  name="group1"
                  type="checkbox"
                  id={'export_' + key}
                  checked={layersRef.current[key]}
                  onChange={handleFilterClick}
                />
              ))}
            </Col>
          </Form.Group>
          <hr className="mt-5 mb-5" />
          <Form.Group
            as={Row}
            className="mx-2 bg-transparent"
            controlId="formOutput"
          >
            <Form.Label column sm={2}>
              <h6>Reduce File Size</h6>
            </Form.Label>
            <Col sm={10}>
              <Form.Check
                key="reduce_file_size"
                label={fileSize}
                name="reduce_file_size"
                type="checkbox"
                id="reduce_file_size"
                checked={reduceFileSize}
                onChange={handleReduceFileSizeClick}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={onExportForBeyondClicked}>
          Export
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Export;
