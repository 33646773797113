import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from './AppContext';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faChartColumn,
  faMap,
  faCheck,
  faKeyboard,
  faFile,
  faFileImport,
  faFileExport,
  faArrowRightArrowLeft,
  faUndo,
  faRedo,
  faCut,
  faCopy,
  faPaste,
  faFolderOpen,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons';

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as turf from '@turf/turf';
import * as math from 'mathjs';

import * as Const from '../../constants';
import Export from './Export';
import Statistics from './Statistics';
import CompareMaps from './CompareMaps';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import {
  cleanFeature,
  clean_all_features,
  fixDockDoors,
  isValid,
  lintGeoJSON,
  sortFeatures,
  validate_map,
} from '../../utilities/Validator';
import { createSurvey } from '../../utilities/Features';
import { convert_to_latlng } from '../../utilities/Survey';
import stringify from 'json-stable-stringify';

import logo from '../../assets/isee-logo-rgb-white.png';

import '../css/Header.css';

// TODO (update these to link to backend)
// const defaultMaps = [
//   {
//     id: 'bmw',
//     name: 'BMW',
//     file: 'bmw_yard.json',
//   },
//   {
//     id: 'carson',
//     name: 'Carson',
//     file: 'carson3_yard.json',
//   },
//   {
//     id: 'dallas',
//     name: 'Dallas (Office)',
//     file: 'dallas_office_demo_yard.json',
//   },
//   {
//     id: 'kcc',
//     name: 'Kimberly Clark',
//     file: 'kimberly_clark_yard.json',
//   },
//   {
//     id: 'png',
//     name: 'P&G',
//     file: 'png_dayton_yard.json',
//   },
// ];

const Header = (props) => {
  const {
    isMapboxLoaded,
    map,
    draw,
    autoSave,
    setAutoSave,
    currentFileName,
    selectedFeatures,
    setSelectedFeatures,
    setCurrentFileName,
    setSidebarContentType,
    setOpenSidebar,
    selectedMenuButton,
    setSelectedMenuButton,
    undoHistory,
    redoHistory,
    resetHistory,
    historyStateIndex,
    historyStateLastIndex,
    historyLength,
  } = useContext(AppContext);
  const jsonInputFile = useRef(null);

  const [show, setShow] = useState(false);
  const [showKeyboardShortcutsModal, setShowKeyboardShortcutsModal] =
    useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [showCompareMaps, setShowCompareMaps] = useState(false);

  useEffect(() => {
    if (isMapboxLoaded) {
      if (selectedMenuButton === 'save') {
        onSaveClicked();
      } else if (selectedMenuButton === 'open') {
        onOpenClicked();
      } else if (selectedMenuButton === 'export') {
        setShowExport(true);
      } else if (selectedMenuButton === 'undo') {
        onUndoClicked();
      } else if (selectedMenuButton === 'redo') {
        onRedoClicked();
      } else if (selectedMenuButton === 'cut') {
        onCutClicked();
      } else if (selectedMenuButton === 'copy') {
        onCopyClicked();
      } else if (selectedMenuButton === 'paste') {
        onPasteClicked();
      }
      if (selectedMenuButton !== '') {
        setSelectedMenuButton('');
      }
    }
  }, [selectedMenuButton, historyStateIndex]);

  // const onMapClicked = async (e) => {
  //   fetch(defaultMaps[e.target.id].file)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       processFile(data);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  // Duplicated in CompareMaps
  const clearAllFeatures = () => {
    draw.deleteAll();
    Object.keys(map.getStyle().sources).forEach((source) => {
      // Try-catch is here because 'mapbox' is a map source that cannot be removed
      // and this will throw an error if we don't catch it.
      try {
        map
          .getSource(source)
          .setData({ type: 'FeatureCollection', features: [] });
      } catch {}
    });
    resetHistory();
  };

  const handleClose = (e) => {
    setShow(false);
    if (e.target.id === 'clear') {
      clearAllFeatures();
    }
  };

  const handleShow = () => {
    if (draw.getAll().features.length === 0) return;
    setShow(true);
  };

  const onOpenClicked = () => {
    jsonInputFile.current.click();
  };

  const onSaveClicked = () => {
    var data = draw.getAll();
    if (data.features.length > 0) {
      data.features = data.features.filter(
        (f) => f.properties.static_type !== Const.StaticObjectType.DEBUG
      );
      data.features = clean_all_features(data.features);
      data.features.forEach((f, i, arr) => (arr[i] = cleanFeature(f, false)));
      data.features = fixDockDoors(data.features);
      var message = validate_map(data.features);
      if (message != '') {
        alert(message);
      }
      data.features = sortFeatures(data.features);
      var file = new Blob([stringify(data, { replacer: null, space: 0 })], {
        type: 'application/json',
      });
      var a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = currentFileName;
      a.click();
    }
  };

  const onCutClicked = (e) => {
    var fc = draw.getSelected();
    if (fc.features.length > 0) {
      setSelectedFeatures(fc);
      setOpenSidebar(false);
      navigator.clipboard.writeText(JSON.stringify(fc));
      map.fire(Constants.events.DELETE, {
        features: fc.features,
      });
      fc.features.forEach((f) => draw.delete(f.id));
    }
  };

  const onCopyClicked = (e) => {
    var fc = draw.getSelected();
    if (fc.features.length > 0) {
      setSelectedFeatures(fc);
      navigator.clipboard.writeText(JSON.stringify(fc));
    }
  };

  const onPasteClicked = (e) => {
    try {
      const text = navigator.clipboard.readText();
      var features = [];
      if (selectedFeatures.features.length !== 0) {
        for (var i = 0; i < selectedFeatures.features.length; i++) {
          delete selectedFeatures.features[i].id;
          features.push(selectedFeatures.features[i]);
        }
      } else {
        try {
          if (text.result !== undefined) {
            const json = JSON.parse(text.result);
            if (json.features !== undefined) {
              var valid = json.features.every((f) => isValid(f));
              if (valid) {
                features = json.features;
              } else {
                console.error('Pasted JSON map features are not valid', text);
              }
            }
          }
        } catch (error) {
          console.error(error);
          var lnglat = text.split(',').reverse().map(Number);
          if (lnglat.length === 2) {
            var feature = create_feature(Const.StaticObjectType.SURVEY, lnglat);
            features.push(feature);
          } else {
            console.error(
              'Invalid map features or latitude/longitude values pasted',
              text
            );
          }
        }
      }

      var featureIds = draw.add({
        type: 'FeatureCollection',
        features,
      });
      var features = featureIds.map((i) => draw.get(i));
      map.fire(Constants.events.CREATE, {
        features: features,
      });
      setSelectedFeatures(turf.featureCollection(features));
      draw.changeMode('simple_select', { featureIds: featureIds });
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
      console.error('https://stackoverflow.com/a/70045589');
    }
  };

  const onUndoClicked = () => {
    undoHistory();
  };

  const onRedoClicked = () => {
    redoHistory();
  };

  const processFile = async (data) => {
    var body = {};
    var inds = [];
    for (var i = 0; i < data.features.length; i++) {
      if (
        data.features[i].properties.static_type ===
        Const.StaticObjectType.SURVEY
      ) {
        var input_epsg = data.features[i].properties.epsg;
        input_epsg =
          input_epsg === undefined ? String(Const.defaultEPSG) : input_epsg;
        if (!(data.features[i].properties.epsg in body)) {
          body[input_epsg] = { ind: [], x: [], y: [], z: [], time: [] };
        }
        body[input_epsg].ind.push(i);
        body[input_epsg].x.push(data.features[i].geometry.coordinates[0]);
        body[input_epsg].y.push(data.features[i].geometry.coordinates[1]);
        if (data.features[i].geometry.coordinates[2] !== undefined) {
          body[input_epsg].z.push(data.features[i].geometry.coordinates[2]);
        } else {
          body[input_epsg].z.push(0.0);
        }
        var timestamp;
        if (data.features[i].properties.timestamp !== undefined) {
          timestamp = new Date(data.features[i].properties.timestamp);
        } else {
          timestamp = new Date();
        }
        const time = parseFloat(
          timestamp.getFullYear().toString() +
            '.' +
            timestamp.getMonth().toString()
        );
        body[input_epsg].time.push(time);
      }
    }
    for (const input_epsg of Object.keys(body)) {
      var coordinates = await convert_to_latlng(
        body[input_epsg],
        parseInt(input_epsg)
      );
      for (var i = 0; i < coordinates.x.length; i++) {
        var ind = body[input_epsg].ind[i];
        if (data.features[ind].properties.epsg !== Const.defaultEPSG) {
          data.features[ind].properties._coordinates =
            data.features[ind].geometry.coordinates;
          data.features[ind].geometry.coordinates = [
            coordinates.x[i],
            coordinates.y[i],
            coordinates.z[i],
          ];
        } else {
          data.features[ind].properties._coordinates =
            data.features[ind].geometry.coordinates;
        }
      }
    }
    // TODO: Remove this once UUIDs for parking spaces is standard
    for (var i = 0; i < data.features.length; i++) {
      if (
        data.features[i].properties.static_type ===
          Const.StaticObjectType.PARKING &&
        data.features[i].properties.parking_id === undefined
      ) {
        data.features[i].properties.parking_id = data.features[i].id;
      }
      if (
        data.features[i].properties.static_type ===
          Const.StaticObjectType.DOCK &&
        data.features[i].properties.dock_id === undefined
      ) {
        data.features[i].properties.dock_id = data.features[i].id;
      }
    }
    data.features = clean_all_features(data.features);
    clearAllFeatures();
    draw.set(data);
    map.fire('draw.create', { features: draw.getAll().features });
    zoomToMap();
  };

  const openFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const fileName = e.target.files.length !== 0 ? e.target.files[0].name : '';
    reader.onload = async (e) => {
      const data = JSON.parse(e.target.result);
      processFile(data);
      setCurrentFileName(fileName.replace(/-?_?\d/g, ''));
    };
    reader.readAsText(e.target.files[0]);
    e.target.value = ''; // Clear input so a different file can be loaded
  };

  const zoomToMap = () => {
    var bounds = turf.bbox(draw.getAll());
    map.fitBounds(bounds, {
      ...Const.zoomToMapOffset,
      animate: false,
    });
  };

  const onAutoSaveClicked = (e) => {
    setAutoSave(!autoSave);
    const interval = setInterval(() => {
      onSaveClicked();
    }, Const.autoSaveDuration);
    return () => clearInterval(interval);
  };

  const navDropdownSettingsTitle = <FontAwesomeIcon icon={faCog} />;

  return (
    <Navbar expand="lg" variant="dark" className="py-1">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Clear current map?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button id="cancel" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button id="clear" variant="primary" onClick={handleClose}>
            Clear
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="px-3" fluid>
        <Navbar.Brand>
          <img
            src={logo}
            id="logo"
            alt="ISEE"
            className="d-inline-block align-top"
          />{' '}
          Map Editor
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-auto">
            <NavDropdown
              title="File"
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={handleShow}>
                <FontAwesomeIcon icon={faFile} /> New
              </NavDropdown.Item>
              <NavDropdown.Item id="open" onClick={onOpenClicked}>
                <FontAwesomeIcon icon={faFolderOpen} /> Open...
              </NavDropdown.Item>
              <NavDropdown.Item
                id="import-survey"
                onClick={() => props.setOpenSidebar(true)}
              >
                <FontAwesomeIcon icon={faFileImport} /> Import Survey...
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={onSaveClicked}>
                <FontAwesomeIcon icon={faFloppyDisk} /> Save
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setShowExport(true)}>
                <FontAwesomeIcon icon={faFileExport} /> Export...
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Edit"
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              <NavDropdown.Item
                onClick={onUndoClicked}
                disabled={historyStateIndex === 0}
              >
                <FontAwesomeIcon icon={faUndo} /> Undo
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={onRedoClicked}
                disabled={historyStateIndex === historyLength - 1}
              >
                <FontAwesomeIcon icon={faRedo} /> Redo
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={onCutClicked}
                disabled={selectedFeatures.features.length === 0}
              >
                <FontAwesomeIcon icon={faCut} /> Cut
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={onCopyClicked}
                disabled={selectedFeatures.features.length === 0}
              >
                <FontAwesomeIcon icon={faCopy} /> Copy
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={onPasteClicked}
                disabled={selectedFeatures.features.length === 0}
              >
                <FontAwesomeIcon icon={faPaste} /> Paste
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Tools"
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={() => setShowStatistics(true)}>
                <FontAwesomeIcon icon={faChartColumn} /> Statistics
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setShowCompareMaps(true)}>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} /> Compare Maps
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Help"
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              <NavDropdown.Item
                onClick={() => setShowKeyboardShortcutsModal(true)}
              >
                <FontAwesomeIcon icon={faKeyboard} /> Keyboard Shortcuts
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://code.isee.ai/isee/frontend/map-editor/-/tree/main/services/frontend/docs"
                target="_blank"
                rel="noopener"
                aria-label="Documentation"
                active={false}
              >
                <FontAwesomeIcon icon={faFile} /> Documentation
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link disabled={true}>{currentFileName}</Nav.Link>
            </Nav.Item>
            <div id="geocoder" className="geocoder"></div>
          </Nav>
          <Nav>
            {/*<NavDropdown
              title="Select Site"
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              {defaultMaps.map((m, key) => {
                return (
                  <NavDropdown.Item
                    href="#"
                    key={key}
                    id={key}
                    title={m.name}
                    onClick={onMapClicked}
                  >
                    {m.name}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>*/}
            <NavDropdown
              title={navDropdownSettingsTitle}
              id="basic-nav-dropdown"
              align="end"
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={onAutoSaveClicked}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Auto-Save"
                  className="me-2"
                  checked={autoSave}
                  readOnly
                />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <input
        type="file"
        accept="application/json"
        ref={jsonInputFile}
        style={{ display: 'none' }}
        onChange={openFile}
      />
      {showKeyboardShortcutsModal && (
        <KeyboardShortcutsModal
          show={showKeyboardShortcutsModal}
          onHide={() => setShowKeyboardShortcutsModal(false)}
        />
      )}
      {showExport && (
        <Export show={showExport} onHide={() => setShowExport(false)} />
      )}
      {showStatistics && (
        <Statistics
          show={showStatistics}
          onHide={() => setShowStatistics(false)}
        />
      )}
      {showCompareMaps && (
        <CompareMaps
          show={showCompareMaps}
          onHide={() => setShowCompareMaps(false)}
        />
      )}
    </Navbar>
  );
};

export default Header;
