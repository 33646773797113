import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import '../css/Sidebar.css';

const Sidebar = (props) => {
  const toggleSidebar = () => {
    props.setOpen(!props.open);
  };

  return (
    props.open && (
      <div
        id="right"
        className={`sidebar flex-center right ${props.open ? '' : 'collapsed'}`}
      >
        <div className="sidebar-container rounded-rect flex-center">
          <div className="sidebar-content">{props.children}</div>
          <div className="sidebar-toggle right" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={props.open ? faCaretRight : faCaretLeft} />
          </div>
        </div>
      </div>
    )
  );
};

export default Sidebar;
