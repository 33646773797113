import MapboxDraw from '@mapbox/mapbox-gl-draw';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';

const SimpleSelectKeyboardInputMode = {
  ...MapboxDraw.modes.simple_select,
};

SimpleSelectKeyboardInputMode.onSetup = function (opts) {
  // turn the opts into state.
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: false,
    canBoxSelect: false,
    dragMoving: false,
    canDragMove: false,
    initiallySelectedFeatureIds: opts.featureIds || [],
    isShiftHeldDown: false,
  };

  this.setSelected(
    state.initiallySelectedFeatureIds.filter(
      (id) => this.getFeature(id) !== undefined
    )
  );
  this.fireActionable();

  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true,
  });

  return state;
};

// SimpleSelectKeyboardInputMode.toDisplayFeatures = function (
//   state,
//   geojson,
//   display
// ) {
//   geojson.properties.active = this.isSelected(geojson.properties.id)
//     ? Constants.activeStates.ACTIVE
//     : Constants.activeStates.INACTIVE;
//   display(geojson);
//   this.fireActionable();
//   if (
//     geojson.properties.active !== Constants.activeStates.ACTIVE ||
//     geojson.geometry.type === Constants.geojsonTypes.POINT
//   )
//     return;

//   // If ETA3 curve create supplementary points for node points instead
//   const supplementaryPoints = geojson.properties.user_graph
//     ? createSupplementaryPointsForBezier(geojson)
//     : createSupplementaryPoints(geojson);
//   if (supplementaryPoints) {
//     supplementaryPoints.forEach(display);
//   }
// };

SimpleSelectKeyboardInputMode.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  state.dragMoveLocation = e.lngLat;
};

SimpleSelectKeyboardInputMode.onKeyDown = function (state, e) {
  if (e.keyCode === 16) {
    // Shift
    state.isShiftHeldDown = true;
  }
  if (
    e.keyCode === 38 || // Up
    e.keyCode === 40 || // Down
    e.keyCode === 37 || // Left
    e.keyCode === 39 // Right
  ) {
    if (this.getSelected().length > 0) {
      const delta = this.setDelta(state, e);
      this.dragFeature(state, e, delta);
      this.fireUpdate();
    }
  }
};

SimpleSelectKeyboardInputMode.setDelta = function (state, e) {
  const offset = 0.0000001;
  var lat = 0;
  var lng = 0;
  switch (e.keyCode) {
    case 38: // Up
      lat += offset;
      break;
    case 40: // Down
      lat -= offset;
      break;
    case 37: // Left
      lng -= offset;
      break;
    case 39: // Right
      lng += offset;
      break;
    default:
      break;
  }
  var scale = state.isShiftHeldDown ? 1 : 10;
  return { lng: scale * lng, lat: scale * lat };
};

SimpleSelectKeyboardInputMode.onKeyUp = function (state, e) {
  if (e.keyCode === 16) {
    // Shift
    state.isShiftHeldDown = false;
  }
};

export default SimpleSelectKeyboardInputMode;
