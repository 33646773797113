import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';

import * as Const from '../constants';
import { create_feature } from '../utilities/Features';

// This is "subclassed" to allow adding additional properties
// and to set the default cursor to be crosshairs making it easier
// to pinpoint specific locations.

const PolygonMode = { ...MapboxDraw.modes.draw_polygon };

PolygonMode.onSetup = function (static_type) {
  var feature = create_feature(static_type, [[]], {});
  const polygon = this.newFeature(feature);

  this.addFeature(polygon);

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  // this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.map.getCanvas().style.cursor = 'crosshair';
  // this.activateUIButton(static_type);
  this.activateUIButton(Constants.types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  return {
    polygon,
    currentVertexPosition: 0,
  };
};

PolygonMode.onStop = function (state) {
  // this.updateUIClasses({ mouse: Constants.cursors.NONE });
  this.map.getCanvas().style.cursor = 'unset';
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) return;

  //remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
  if (state.polygon.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.polygon.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

export default PolygonMode;
