import * as Const from '../../constants';

const colors = [];
const keys = Object.keys(Const.StaticObjectType);
const values = Object.values(Const.StaticObjectType);
for (var i = 0; i < keys.length; i++) {
  const color = Const.FeatureColor[keys[i]];
  if (color === undefined) {
    alert('Define ' + keys[i] + ' colors');
    continue;
  }
  colors.push(values[i]);
  colors.push(color);
}
colors.push(Const.FeatureColor.DEFAULT);

const static_type_filter = [
  'case',
  [
    'all',
    [
      'any',
      ['==', ['has', 'user_disabled'], false],
      ['==', ['get', 'user_disabled'], false],
    ],
    ['in', ['get', 'user_static_type'], ['literal', values]],
  ],
  ['match', ['get', 'user_static_type'], ...colors],
  '#555',
];

export default [
  {
    id: 'extrusion',
    type: 'fill-extrusion',
    filter: [
      'all',
      ['has', 'user_height'],
      // ['match', ['typeof', ['get', 'user_height']], 'number', true, false],
      // ['==', ['to-boolean', ['get', 'user_height']], true],
    ],
    paint: {
      'fill-extrusion-color': ['match', ['get', 'user_static_type'], ...colors],
      'fill-extrusion-height': ['get', 'user_height'],
      'fill-extrusion-base': 0.0,
      'fill-extrusion-opacity': 0.75,
    },
  },
  {
    id: 'stop-line',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', 'user_static_type', 'Stop'],
    ],
    layout: {
      'symbol-placement': 'line-center',
      'icon-allow-overlap': false,
      'icon-ignore-placement': false,
      'icon-image': 'oneway-small',
      'icon-offset': [-8, 0],
      'icon-anchor': 'center',
      'icon-size': 2,
      'icon-rotate': -90,
      'text-field': 'STOP',
      'text-offset': [0, 2.4],
      'text-anchor': 'center',
      'text-justify': 'center',
    },
    paint: {
      'text-color': '#fff',
    },
  },
  // {
  //   'id': 'parking-id-labels',
  //   'type': 'symbol',
  //   'filter': ['all',
  //     ['has', 'user_label'],
  //     ['==', '$type', 'Point']
  //   ],
  //   'paint': {
  //     'text-color': 'black',
  //     'text-halo-color': 'white',
  //     'text-halo-width': 1
  //   },
  //   'layout': {
  //     'text-field': '{label}',
  //     'text-size': 16
  //   }
  // },
  // {
  //   'id': 'parking-front-line',
  //   'type': 'line',
  //   'filter': ['all',
  //     ['==', '$type', 'LineString'],
  //     ['has', 'parking_front_line']
  //   ],
  //   'paint': {
  //     'line-color': '#00FF00',
  //     'line-width': 5
  //   },
  //   'layout': {
  //     'line-join': 'round',
  //     'line-cap': 'round'
  //   }
  // },
  // Mapbox GL Draw styles (some custom)
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': static_type_filter,
      'fill-outline-color': static_type_filter,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#fbb03b',
      'fill-outline-color': '#fbb03b',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': static_type_filter,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': static_type_filter,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 2,
      'circle-color': [
        'case',
        ['==', ['get', 'user__tmp'], true],
        Const.FeatureColor.DEBUG,
        ['==', ['get', 'user_disabled'], true],
        '#666',
        ['==', ['get', 'user_removal'], true],
        Const.Color.PINK,
        ['==', ['get', 'user_annotated'], true],
        Const.Color.NEON_GREEN,
        ['==', ['get', 'user_ignore'], true],
        '#666',
        Const.FeatureColor.SURVEY,
      ],
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': static_type_filter,
      'fill-outline-color': static_type_filter,
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': static_type_filter,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': static_type_filter,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 2,
      'circle-color': static_type_filter,
    },
  },
];
