import React, { useContext, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import AppContext from './AppContext';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

import * as turf from '@turf/turf';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as Const from '../../constants';

import _ from 'lodash';

const TOLERANCE = 0.00000001; // Approximately 1mm

const CompareMaps = (props) => {
  const { isMapboxLoaded, map, draw } = useContext(AppContext);
  const [firstMap, setFirstMap] = useState(null);
  const [secondMap, setSecondMap] = useState(null);

  useEffect(() => {
    compareMaps();
  }, [firstMap, secondMap]);

  const zoomToMap = (features) => {
    var bounds = turf.bbox(features);
    map.fitBounds(bounds, {
      padding: {
        top: 100,
        right: 50,
        bottom: 50,
        left: 50,
      },
      animate: false,
    });
  };

  const firstMapstyle = {
    color: Const.CompareMapColor.FIRST,
  };

  const secondMapstyle = {
    color: Const.CompareMapColor.SECOND,
  };

  const onFirstMapChange = async (e) => {
    const data = await loadMap(e);
    setFirstMap(data);
  };

  const onSecondMapChange = async (e) => {
    const data = await loadMap(e);
    setSecondMap(data);
  };

  const loadMap = async (e) => {
    const data = await e.target.files[0].text();
    return JSON.parse(data);
  };

  // Duplicated in Header
  const clearAllFeatures = () => {
    draw.deleteAll();
    Object.keys(map.getStyle().sources).forEach((source) => {
      // Try-catch is here because 'mapbox' is a map source that cannot be remove
      // and this will throw an error if we don't catch it.
      try {
        map
          .getSource(source)
          .setData({ type: 'FeatureCollection', features: [] });
      } catch {}
    });
  };

  const compareMaps = () => {
    if (firstMap != null && secondMap != null) {
      clearAllFeatures();
      var features = [];
      firstMap.features.forEach((f1) => {
        var found = false;
        secondMap.features.forEach((f2) => {
          if (f1.id === f2.id) {
            found = true;
            var same_properties = _.isEqual(f1.properties, f2.properties);

            var f1_coordinates =
              f1.geometry.type === Constants.geojsonTypes.POLYGON
                ? f1.geometry.coordinates[0]
                : f1.geometry.coordinates;
            var f2_coordinates =
              f2.geometry.type === Constants.geojsonTypes.POLYGON
                ? f2.geometry.coordinates[0]
                : f2.geometry.coordinates;
            var same_coordinates = false;
            if (f1_coordinates.length === f2_coordinates.length) {
              for (var i = 0; i < f1_coordinates.length; i++) {
                if (
                  Math.abs(f1_coordinates[i][0] - f2_coordinates[i][0]) <
                    TOLERANCE &&
                  Math.abs(f1_coordinates[i][1] - f2_coordinates[i][1]) <
                    TOLERANCE
                ) {
                  same_coordinates = true;
                }
              }
            }

            if (same_properties === false || same_coordinates === false) {
              f1.properties.compare = false;
              f2.properties.compare = true;
              features.push(f1);
              features.push(f2);
            }

            // if (!_.isEqual(f1, f2)) {
            //   var f1_coordinates = f1.geometry.type === Constants.geojsonTypes.POLYGON ? f1.geometry.coordinates[0] : f1.geometry.coordinates;
            //   var f2_coordinates = f2.geometry.type === Constants.geojsonTypes.POLYGON ? f2.geometry.coordinates[0] : f2.geometry.coordinates;

            //   f1.properties.compare = true;
            //   f2.properties.compare = false;
            //   features.push(f1);
            //   features.push(f2);
            // }
          }
        });
        if (found === false) {
          f1.properties.compare = true;
          features.push(f1);
        }
      });
      secondMap.features.forEach((f2) => {
        var found = false;
        firstMap.features.forEach((f1) => {
          if (f1.id === f2.id) {
            found = true;
          }
        });
        if (found === false) {
          f2.properties.compare = false;
          features.push(f2);
        }
      });
      if (features.length > 0) {
        var data = {
          type: 'FeatureCollection',
          features: features,
        };
        zoomToMap(data);
        map.getSource('map-compare').setData(data);
      }
    }
  };

  return (
    isMapboxLoaded &&
    props.show && (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Compare Maps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label style={firstMapstyle}>First Map</Form.Label>
              <Form.Control type="file" size="sm" onChange={onFirstMapChange} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label style={secondMapstyle}>Second Map</Form.Label>
              <Form.Control
                type="file"
                size="sm"
                onChange={onSecondMapChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            onClick={props.onHide}
            disabled={firstMap === null || secondMap === null}
          >
            Compare
          </Button>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default CompareMaps;
