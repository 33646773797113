import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as Const from '../constants';

import * as math from 'mathjs';
import * as turf from '@turf/turf';
import { Matrix } from 'ml-matrix';
import { smallestSurroundingRectangleByArea } from 'geojson-minimum-bounding-rectangle';

import { geodetic2enu, enu2geodetic, pointFeaturesToENU } from './Geodetic';
import {
  angle_between,
  angle_diff,
  create_grid,
  createTree,
  estimate_angle,
  project_offset_from_line,
  project_point_to_line,
  project_points_to_line,
  point_to_line_distance,
  percentage_along_line,
  distance_between_points,
  interpolate_along_line,
  order_points_along_line,
  nearest_neighbor,
} from './Geometry';
import { create_feature } from './Features';
import { icp } from './IterativeClosestPoint';
import { line_fit } from './LineFit';
import { rotation, translation } from './Transform';

const MINIMUM_WIDTH = 2.4384; // 8.5 ft
const MAXIMUM_WIDTH = 4.4196; // 14.5 ft
const OFFSET = 0.1; // 10%
const ASSOCIATION_MAX_DISTANCE = 0.5; // m
const FILL_MISSING_MAX_DISTANCE = 1.0; // m
const DUPLICATE_POINT_DISTANCE_THRESHOLD = 0.15; // m
const DEBUG = false;

const ParkingSpace = {};

ParkingSpace.create_rectangles = function (
  line1_points,
  line2_points,
  line1_inds,
  line2_inds,
  angle
) {
  var result;
  var rectangles = [];

  var n = Math.min(
    line1_inds.length,
    line2_inds.length,
    line1_points.length,
    line2_points.length
  );

  if (
    n !== line1_inds.length ||
    n !== line2_inds.length ||
    n !== line1_points.length ||
    n !== line2_points.length
  ) {
    console.error(
      'Unequal number of matching points. Some parking spaces may be missing.'
    );
  }

  for (var i = 1; i < n; i++) {
    var coordinates = [];
    var front_left = line1_points[line1_inds[i - 1]];
    var back_left = line2_points[line2_inds[i - 1]];
    var back_right = line2_points[line2_inds[i]];
    var front_right = line1_points[line1_inds[i]];

    if (
      front_left !== undefined &&
      back_left !== undefined &&
      back_right !== undefined &&
      front_right !== undefined
    ) {
      // For projecting angled parking spaces so they remain
      // as a rectangle and not a rhombus
      if (angle != null && angle != 0.0) {
        result = project_point_to_line(front_right, [front_left, back_left]);
        if (result[1] >= 0 && result[1] <= 1) {
          front_left = result[0];
          result = project_point_to_line(back_left, [front_right, back_right]);
          if (result[1] >= 0 && result[1] <= 1) {
            back_right = result[0];
          }
        }

        result = project_point_to_line(front_left, [front_right, back_right]);
        if (result[1] >= 0 && result[1] <= 1) {
          front_right = result[0];
          result = project_point_to_line(back_right, [front_left, back_left]);
          if (result[1] >= 0 && result[1] <= 1) {
            back_left = result[0];
          }
        }
      }

      coordinates = [
        front_left,
        back_left,
        back_right,
        front_right,
        front_left,
      ];

      if (
        turf.booleanClockwise(turf.polygonToLine(turf.polygon([coordinates])))
      ) {
        coordinates = [
          front_right,
          back_right,
          back_left,
          front_left,
          front_right,
        ];
      }

      rectangles.push(coordinates);
    }
  }
  return rectangles;
};

ParkingSpace.create_polygons = function (
  rectangles,
  prefix,
  start_id,
  id_mulitple,
  origin,
  removalFeatures
) {
  var polygons = [];
  var current_id = start_id;
  for (var i = 0; i < rectangles.length; i++) {
    var coordinates = rectangles[i].map((r) =>
      enu2geodetic(r, origin).slice(0, 2)
    );
    var id;
    if (!isNaN(parseInt(start_id))) {
      current_id = parseInt(current_id);
      id =
        prefix +
        String(current_id).padStart(rectangles.length.toString().length, '0');
      // id = prefix + String(current_id);
    } else {
      id = prefix + current_id;
    }
    var polygon = create_feature(
      Const.StaticObjectType.PARKING,
      [coordinates],
      {
        parking_id: id,
      }
    );
    polygon = this.filter_polygons([polygon], removalFeatures);
    if (polygon.length !== 0) {
      polygons.push(polygon[0]);
      if (!isNaN(parseInt(start_id))) {
        current_id += parseInt(id_mulitple);
      } else {
        current_id = String.fromCharCode(
          current_id.charCodeAt(0) + parseInt(id_mulitple)
        );
      }
    }
  }
  return polygons;
};

ParkingSpace.assign_lines = function (features, lines) {
  var line_inds = [];
  for (var i = 0; i < features.length; i++) {
    const pt = turf.point(features[i].geometry.coordinates);
    var minimum_distance = Infinity;
    var line_ind = undefined;
    for (var j = 0; j < lines.length; j++) {
      var line = turf.lineString(lines[j]);
      var distance = turf.pointToLineDistance(pt, line, { units: 'meters' });
      if (distance < minimum_distance) {
        minimum_distance = distance;
        line_ind = j;
      }
    }
    line_inds.push(line_ind);
  }
  return line_inds;
};

ParkingSpace.remove_duplicate_points = function (
  points,
  weights,
  distance = 0.1
) {
  var new_points = [...points];
  var new_weights = [...weights];
  var inds_to_remove = [];
  for (var i = 0; i < points.length; i++) {
    var tmp_inds_to_remove = [];
    tmp_inds_to_remove.push(i);
    for (var j = 0; j < points.length; j++) {
      if (j > i) {
        const dist = distance_between_points(points[i], points[j]);
        if (dist < distance) {
          tmp_inds_to_remove.push(j);
        }
      }
    }

    var tmp_weights = tmp_inds_to_remove.map((ind) => weights[ind]);
    var max_weight = Math.max(...tmp_weights);
    var max_ind = tmp_weights.indexOf(max_weight);
    inds_to_remove.push(...tmp_inds_to_remove.filter((_, k) => k !== max_ind));
  }

  new_points = new_points.filter((_, i) => !inds_to_remove.includes(i));
  new_weights = new_weights.filter((_, i) => !inds_to_remove.includes(i));
  return [new_points, new_weights];
};

ParkingSpace.remove_outliers = function (
  line1,
  line2,
  line1_points,
  line2_points,
  line1_weights,
  line2_weights,
  width,
  angle,
  origin
) {
  var result = this.estimate_parking_space_width(
    line1,
    line2,
    line1_points,
    line2_points,
    width,
    angle
  );
  width = result[0] * Math.cos(angle);

  var result = order_points_along_line(line1_points, line1);
  line1_points = result.points;
  result = order_points_along_line(line2_points, line2);
  line2_points = result.points;

  var all_points = [...line1_points, ...line2_points];
  var [grid, n_spaces] = create_grid(line1, line2, width, angle);
  grid.forEach((p, i) => (grid[i][2] = 0.0));
  all_points.forEach((p, i) => (all_points[i][2] = 0.0));
  var r = icp(grid, all_points);

  var debug = [];
  if (DEBUG) {
    var grid_line1_points = grid.filter((_, i) => i < n_spaces);
    var grid_line2_points = grid.filter((_, i) => i >= n_spaces);
    var temp = this.create_temp_points(
      grid_line1_points,
      grid_line2_points,
      origin
    );
    // debug.push(...temp);
  }

  r.dst_indicies = r.dst_indicies.sort(function (a, b) {
    return a - b;
  });
  var best_inliers1 = r.dst_indicies.filter((i, j) => i < line1_points.length);
  var best_inliers2 = r.dst_indicies
    .filter((i, j) => i >= line1_points.length)
    .map((i) => i - line1_points.length);

  line1_points = best_inliers1.map((i) => line1_points[i]);
  line1_weights = best_inliers1.map((i) => line1_weights[i]);
  line2_points = best_inliers2.map((i) => line2_points[i]);
  line2_weights = best_inliers2.map((i) => line2_weights[i]);

  var grid_line1_points = r.transformed_points.filter((_, i) => i <= n_spaces);
  var grid_line2_points = r.transformed_points.filter((_, i) => i > n_spaces);

  var grid_line1 = [
    grid_line1_points[0],
    grid_line1_points[grid_line1_points.length - 1],
  ];
  var grid_line2 = [
    grid_line2_points[0],
    grid_line2_points[grid_line2_points.length - 1],
  ];

  if (DEBUG) {
    var temp = this.create_temp_points(
      grid_line1_points,
      grid_line2_points,
      origin
    );
    // debug.push(...temp);
  }

  var result = this.remove_unmatched_projected_points(
    line1_points,
    line1_weights,
    grid_line1_points,
    grid_line1
  );
  line1_points = result[0];
  line1_weights = result[1];

  var result = this.remove_unmatched_projected_points(
    line2_points,
    line2_weights,
    grid_line2_points,
    grid_line2
  );
  line2_points = result[0];
  line2_weights = result[1];

  var result = order_points_along_line(line1_points, line1);
  line1_points = result.points;
  result = order_points_along_line(line2_points, line2);
  line2_points = result.points;

  result = this.estimate_parking_space_width(
    line1,
    line2,
    line1_points,
    line2_points,
    width,
    angle
  );
  width = result[0] * Math.cos(angle);

  console.log(
    `Parking space width ${width.toFixed(3)} +/- ${result[1].toFixed(3)}`
  );

  return [
    line1_points,
    line2_points,
    line1_weights,
    line2_weights,
    width,
    debug,
  ];
};

ParkingSpace.remove_unmatched_projected_points = function (
  line_points,
  line_weights,
  grid_line_points,
  grid_line
) {
  var tree = createTree(grid_line_points);
  var inds_to_keep = [];
  var used_inds = [];
  line_points.forEach((p, i) => {
    var p1 = project_point_to_line(p, grid_line)[0];
    var ind = nearest_neighbor(p1, tree, 1, ASSOCIATION_MAX_DISTANCE);
    if (ind.length === 1 && !used_inds.includes(ind[0])) {
      var p2 = grid_line_points[ind[0]];
      if (
        point_to_line_distance(p, grid_line)[0] <
        3 * ASSOCIATION_MAX_DISTANCE
      ) {
        inds_to_keep.push(i);
        used_inds.push(ind[0]);
      }
    }
  });
  if (grid_line_points.length > 0) tree.dispose();
  line_points = line_points.filter((_, i) => inds_to_keep.includes(i));
  line_weights = line_weights.filter((_, i) => inds_to_keep.includes(i));
  return [line_points, line_weights];
};

ParkingSpace.estimate_parking_space_width = function (
  line1,
  line2,
  line1_points,
  line2_points,
  rough_width = null,
  angle = 0
) {
  if (rough_width !== null) {
    rough_width = rough_width / Math.cos(angle);
  }

  var result = order_points_along_line(line1_points, line1);
  line1_points = result.points;
  result = order_points_along_line(line2_points, line2);
  line2_points = result.points;

  var widths = [];
  var i, tmp_widths;

  const dists1 = line1_points
    .slice(1)
    .map((p, i) => distance_between_points(p, line1_points[i]));
  const dists2 = line2_points
    .slice(1)
    .map((p, i) => distance_between_points(p, line2_points[i]));

  widths = [...dists1, ...dists2];
  tmp_widths = widths.filter(
    (w) =>
      w > (MINIMUM_WIDTH / Math.cos(angle)) * (1 - OFFSET) &&
      w < (MAXIMUM_WIDTH / Math.cos(angle)) * (1 + OFFSET)
  );

  if (tmp_widths.length !== 0) {
    const m = 2;
    var median = math.median(tmp_widths);
    const d = tmp_widths.map((w) => Math.abs(w - median));
    const mdev = math.median(d);
    const s = d.map((d) => d / mdev);
    tmp_widths = tmp_widths.filter((_, i) => s[i] < m);
  }

  var mean = tmp_widths.length > 0 ? math.mean(tmp_widths) : widths[0];
  var median = tmp_widths.length > 0 ? math.median(tmp_widths) : widths[0];
  var std = tmp_widths.length > 0 ? math.std(tmp_widths) : 0.0;

  mean = mean * Math.cos(angle);

  if (rough_width === null) {
    return [mean, std];
  } else {
    if (Math.abs(rough_width - mean) < OFFSET * rough_width) {
      return [mean, std];
    } else if (Math.abs(rough_width - median) < OFFSET * rough_width) {
      return [median, std];
    } else {
      return [rough_width, std];
    }
  }
};

ParkingSpace.fill_missing_points = function (
  line1,
  line2,
  line1_points,
  line2_points,
  line1_weights,
  line2_weights,
  angle,
  width
) {
  var missing_line1_points = [];
  var missing_line1_weights = [];
  var missing_line2_points = [];
  var missing_line2_weights = [];

  var result = order_points_along_line(line1_points, line1, line1_weights);
  line1_points = result.points;
  line1_weights = result.weights;

  result = order_points_along_line(line2_points, line2, line2_weights);
  line2_points = result.points;
  line2_weights = result.weights;

  var extended_width = width / Math.cos(angle);

  var i, j;
  for (i = 1; i < line1_points.length; i++) {
    const dist = distance_between_points(line1_points[i - 1], line1_points[i]);
    const line = [line1_points[i - 1], line1_points[i]];
    const n_spaces_rough = dist / extended_width;
    const n_spaces = parseInt(Math.round(n_spaces_rough)) - 1;
    const new_width = dist / (n_spaces + 1);
    for (j = 0; j < n_spaces; j++) {
      const new_point = interpolate_along_line((j + 1) * new_width, line);
      missing_line1_points.push(new_point);
      missing_line1_weights.push(0.0);
    }
  }

  for (i = 1; i < line2_points.length; i++) {
    const dist = distance_between_points(line2_points[i - 1], line2_points[i]);
    const line = [line2_points[i - 1], line2_points[i]];
    const n_spaces_rough = dist / extended_width;
    const n_spaces = parseInt(Math.round(n_spaces_rough)) - 1;
    const new_width = dist / (n_spaces + 1);
    for (j = 0; j < n_spaces; j++) {
      const new_point = interpolate_along_line((j + 1) * new_width, line);
      missing_line2_points.push(new_point);
      missing_line2_weights.push(0.0);
    }
  }

  line1_points.push(...missing_line1_points);
  line1_weights.push(...missing_line1_weights);

  line2_points.push(...missing_line2_points);
  line2_weights.push(...missing_line2_weights);

  result = order_points_along_line(line1_points, line1, line1_weights);
  line1_points = result.points;
  line1_weights = result.weights;

  result = order_points_along_line(line2_points, line2, line2_weights);
  line2_points = result.points;
  line2_weights = result.weights;

  return [line1_points, line2_points, line1_weights, line2_weights];
};

ParkingSpace.fill_parking_region = function (
  parking_region,
  line1,
  line2,
  line1_points,
  line2_points,
  line1_weights,
  line2_weights,
  width,
  angle,
  origin
) {
  var result = order_points_along_line(line1_points, line1, line1_weights);
  var line1_points = result.points;
  var line1_weights = result.weights;

  var result = order_points_along_line(line2_points, line2, line2_weights);
  var line2_points = result.points;
  var line2_weights = result.weights;

  var polygon = turf.polygon(parking_region.geometry.coordinates);

  var inside = true;
  var n_spaces = 1;
  var missing_line1_points = [];
  var missing_line1_weights = [];
  var missing_line2_points = [];
  var missing_line2_weights = [];

  var corrected_width = width / Math.cos(angle);

  var [_, t] = project_point_to_line(line1_points[0], line1);
  var start_dist =
    Math.sign(t) * distance_between_points(line1[0], line1_points[0]);
  var direction = -1;
  while (inside === true) {
    var new_point = interpolate_along_line(
      start_dist + direction * n_spaces * corrected_width,
      line1
    );
    const ptWithin = turf.pointsWithinPolygon(
      turf.points([enu2geodetic(new_point, origin).slice(0, 2)]),
      polygon
    );
    n_spaces += 1;
    if (ptWithin.features.length !== 0) {
      missing_line1_points.push(new_point);
      missing_line1_weights.push(0.0);
      const [projected_point, t] = project_point_to_line(
        new_point,
        line2,
        angle + Math.PI
      );
      missing_line2_points.push(projected_point);
      missing_line2_weights.push(0.0);
    } else {
      inside = false;
    }
  }

  var inside = true;
  var n_spaces = 1;

  var [_, t] = project_point_to_line(
    line1_points[line1_points.length - 1],
    line1
  );
  var start_dist =
    Math.sign(t) *
    distance_between_points(line1[0], line1_points[line1_points.length - 1]);
  var direction = 1;
  while (inside === true) {
    var new_point = interpolate_along_line(
      start_dist + direction * n_spaces * corrected_width,
      line1
    );
    const ptWithin = turf.pointsWithinPolygon(
      turf.points([enu2geodetic(new_point, origin).slice(0, 2)]),
      polygon
    );
    n_spaces += 1;
    if (ptWithin.features.length !== 0) {
      missing_line1_points.push(new_point);
      missing_line1_weights.push(0.0);
      const [projected_point, t] = project_point_to_line(
        new_point,
        line2,
        angle + Math.PI
      );
      missing_line2_points.push(projected_point);
      missing_line2_weights.push(0.0);
    } else {
      inside = false;
    }
  }

  line1_points.push(...missing_line1_points);
  line1_weights.push(...missing_line1_weights);
  line2_points.push(...missing_line2_points);
  line2_weights.push(...missing_line2_weights);

  result = order_points_along_line(line1_points, line1, line1_weights);
  line1_points = result.points;
  line1_weights = result.weights;

  result = order_points_along_line(line2_points, line2, line2_weights);
  line2_points = result.points;
  line2_weights = result.weights;

  return [line1_points, line2_points, line1_weights, line2_weights];
};

ParkingSpace.fill_projected_points = function (
  line1,
  line2,
  line1_points,
  line2_points,
  line1_weights,
  line2_weights,
  angle
) {
  var result = order_points_along_line(line1_points, line1, line1_weights);
  var line1_points_ = result.points;
  var line1_weights_ = result.weights;

  result = order_points_along_line(line2_points, line2, line2_weights);
  var line2_points_ = result.points;
  var line2_weights_ = result.weights;

  result = this.associate_front_rear_points(
    line1,
    line2,
    line1_points_,
    line2_points_,
    angle
  );
  var line1_inds = result[0];
  var line2_inds = result[1];
  var line1_unassociated_inds = result[2];
  var line2_unassociated_inds = result[3];

  if (line2_unassociated_inds.length > 0) {
    const missing_points = project_points_to_line(
      line2_unassociated_inds.map((i) => line2_points_[i]),
      line1,
      angle
    );
    const missing_weights = line2_unassociated_inds.map((i) => 0);
    line1_points_.push(...missing_points);
    line1_weights_.push(...missing_weights);
  }

  if (line1_unassociated_inds.length > 0) {
    const missing_points = project_points_to_line(
      line1_unassociated_inds.map((i) => line1_points_[i]),
      line2,
      angle
    );
    const missing_weights = line1_unassociated_inds.map((i) => 0);
    line2_points_.push(...missing_points);
    line2_weights_.push(...missing_weights);
  }

  result = order_points_along_line(line1_points_, line1, line1_weights_);
  line1_points_ = result.points;
  line1_weights_ = result.weights;
  result = order_points_along_line(line2_points_, line2, line2_weights_);
  line2_points_ = result.points;
  line2_weights_ = result.weights;

  result = this.associate_front_rear_points(
    line1,
    line2,
    line1_points_,
    line2_points_,
    angle
  );
  line1_inds = result[0];
  line2_inds = result[1];
  line1_unassociated_inds = result[2];
  line2_unassociated_inds = result[3];

  return [
    line1_points_,
    line2_points_,
    line1_weights_,
    line2_weights_,
    line1_inds,
    line2_inds,
  ];
};

ParkingSpace.associate_front_rear_points = function (
  line1,
  line2,
  line1_points,
  line2_points,
  angle
) {
  var line1_inds = [],
    line2_inds = [],
    line1_unassociated_inds = [],
    line2_unassociated_inds = [];

  var result = order_points_along_line(line1_points, line1);
  line1_points = result.points;
  result = order_points_along_line(line2_points, line2);
  line2_points = result.points;

  var projected_points = project_points_to_line(line1_points, line2, angle);
  var line2_points_tree = createTree(line2_points);
  for (var i = 0; i < projected_points.length; i++) {
    var closest_ind = line2_points_tree.nn(
      projected_points[i],
      ASSOCIATION_MAX_DISTANCE
    );
    if (closest_ind !== -1) {
      line1_inds.push(i);
      line2_inds.push(closest_ind);
    }
  }

  var tmp_inds = [...Array(line1_points.length).keys()];
  // Symmetric difference between array sets
  line1_unassociated_inds = line1_inds
    .filter((x) => !tmp_inds.includes(x))
    .concat(tmp_inds.filter((x) => !line1_inds.includes(x)));

  tmp_inds = [...Array(line2_points.length).keys()];
  // Symmetric difference between array sets
  line2_unassociated_inds = line2_inds
    .filter((x) => !tmp_inds.includes(x))
    .concat(tmp_inds.filter((x) => !line2_inds.includes(x)));

  projected_points = project_points_to_line(
    line2_unassociated_inds.map((i) => line2_points[i]),
    line1,
    angle + Math.PI
  );

  var line1_points_tree = createTree(line1_points);
  var inds1_to_add = [];
  var inds2_to_add = [];
  for (var i = 0; i < projected_points.length; i++) {
    var closest_ind = line1_points_tree.nn(
      projected_points[i],
      ASSOCIATION_MAX_DISTANCE
    );
    if (closest_ind !== -1) {
      inds1_to_add.push(closest_ind);
      inds2_to_add.push(line2_unassociated_inds[i]);
    }
  }

  line1_inds.push(...inds1_to_add);
  line2_inds.push(...inds2_to_add);

  line1_unassociated_inds = line1_unassociated_inds.filter(
    (i) => !inds1_to_add.includes(i)
  );
  line2_unassociated_inds = line2_unassociated_inds.filter(
    (i) => !inds2_to_add.includes(i)
  );

  if (line1_points.length > 0) line1_points_tree.dispose();
  if (line2_points.length > 0) line2_points_tree.dispose();

  return [
    line1_inds,
    line2_inds,
    line1_unassociated_inds,
    line2_unassociated_inds,
  ];
};

ParkingSpace.filter_rectangles = function (rectangles, rough_width, angle) {
  var widths = [];
  var tmp_widths = [];
  var tmp_lengths = [];
  var output = [];
  var remaining_rectangles = [];
  var remaining_widths = [];
  for (var i = 0; i < rectangles.length; i++) {
    const width =
      (Math.sqrt(
        Math.pow(rectangles[i][0][0] - rectangles[i][3][0], 2) +
          Math.pow(rectangles[i][0][1] - rectangles[i][3][1], 2)
      ) +
        Math.sqrt(
          Math.pow(rectangles[i][1][0] - rectangles[i][2][0], 2) +
            Math.pow(rectangles[i][1][1] - rectangles[i][2][1], 2)
        )) /
      2;
    const length =
      (Math.sqrt(
        Math.pow(rectangles[i][0][0] - rectangles[i][1][0], 2) +
          Math.pow(rectangles[i][0][1] - rectangles[i][1][1], 2)
      ) +
        Math.sqrt(
          Math.pow(rectangles[i][2][0] - rectangles[i][3][0], 2) +
            Math.pow(rectangles[i][2][1] - rectangles[i][3][1], 2)
        )) /
      2;
    if (
      width > MINIMUM_WIDTH * (1 - OFFSET) &&
      width < MAXIMUM_WIDTH * (1 + OFFSET)
    ) {
      tmp_widths.push(width);
      tmp_lengths.push(length);
    }
    widths.push(width);
  }

  if (
    tmp_widths.length > 0 &&
    math.std(tmp_lengths) / math.mean(tmp_lengths) < OFFSET
  ) {
    const mean_width = math.mean(tmp_widths);
    for (var i = 0; i < rectangles.length; i++) {
      if (
        widths[i] > mean_width * (1 - OFFSET) &&
        widths[i] < mean_width * (1 + OFFSET)
      ) {
        output.push(rectangles[i]);
      } else {
        if (
          widths[i] > MINIMUM_WIDTH * (1 - OFFSET) &&
          widths[i] < MAXIMUM_WIDTH * (1 + OFFSET)
        ) {
          remaining_rectangles.push(rectangles[i]);
          remaining_widths.push(widths[i]);
        }
      }
    }
  }

  // In some cases there may be two different parking
  // space widths in a particular parking region,
  // and the above filters out the second set.
  // Below grabs the median value of the remaining
  // widths and checks if they are within the valid
  // range for parking spaces.
  if (remaining_widths.length > 0) {
    const mean_width = math.median(remaining_widths);
    for (var i = 0; i < remaining_rectangles.length; i++) {
      if (
        remaining_widths[i] > mean_width * (1 - OFFSET) &&
        remaining_widths[i] < mean_width * (1 + OFFSET)
      ) {
        output.push(remaining_rectangles[i]);
      }
    }
  }

  // Sort the output rectangles in spatial order because
  // the above addition of different width parking spaces
  // get stacked onto the end, but may not spatial be in order.
  output = this.sort_rectangles(output);

  return output;
};

ParkingSpace.sort_rectangles = function (rectangles) {
  if (rectangles.length === 0) {
    return rectangles;
  }
  const points = rectangles.map((r) => r[0]);
  const line = [points[0], points[points.length - 1]];
  const projected_points = project_points_to_line(points, line);

  var result = Array.from(Array(projected_points.length).keys()).sort((a, b) =>
    percentage_along_line(projected_points[a], line) <
    percentage_along_line(projected_points[b], line)
      ? -1
      : (percentage_along_line(projected_points[b], line) <
          percentage_along_line(projected_points[a], line)) |
        0
  );
  var ordered_rectangles = [];
  for (var i = 0; i < result.length; i++) {
    ordered_rectangles.push(rectangles[result[i]]);
  }
  return ordered_rectangles;
};

ParkingSpace.filter_polygons = function (polygons, removal_points) {
  var output = [];
  const points = turf.points(removal_points.map((f) => f.geometry.coordinates));
  for (var i = 0; i < polygons.length; i++) {
    const ptsWithin = turf.pointsWithinPolygon(points, polygons[i]);
    if (ptsWithin.features.length === 0) {
      output.push(polygons[i]);
    }
  }
  return output;
};

ParkingSpace.generate_survey_features = function (
  parking_region,
  parking_width,
  parking_length,
  parking_angle
) {
  var features = [];
  const origin = parking_region.geometry.coordinates[0][0];
  const global_parking_region = parking_region.geometry.coordinates[0].map(
    (c) => geodetic2enu(c, origin)
  );
  const dist1 = distance_between_points(
    global_parking_region[0],
    global_parking_region[1]
  );
  const dist2 = distance_between_points(
    global_parking_region[1],
    global_parking_region[2]
  );
  var width, length, line1, line2, angle, p3;
  if (dist1 < dist2) {
    width = dist1;
    length = dist2;
    line1 = [global_parking_region[1], global_parking_region[2]];
    p3 = global_parking_region[0];
  } else {
    width = dist2;
    length = dist1;
    line1 = [global_parking_region[0], global_parking_region[1]];
    p3 = global_parking_region[2];
  }

  if (
    (line1[1][0] - line1[0][0]) * (p3[1] - line1[0][1]) -
      (line1[1][1] - line1[0][1]) * (p3[0] - line1[0][0]) <
    0
  ) {
    line1 = [line1[1], line1[0]];
  }

  var vec = math.subtract(line1[1], line1[0]);
  var tmp = vec[0];
  vec[0] = -vec[1];
  vec[1] = tmp;
  vec = math.divide(vec, math.norm(vec));

  var corrected_length =
    parking_length * Math.cos(parking_angle) +
    Math.abs(parking_width * Math.sin(parking_angle));

  var offset = math.multiply(vec, corrected_length);
  var p1 = math.add(line1[0], offset);
  var p2 = math.add(line1[1], offset);
  var line2 = [p1, p2];

  var [grid, n_spaces] = create_grid(
    line1,
    line2,
    parking_width,
    parking_angle
  );

  for (var i = 0; i < grid.length; i++) {
    var feature = create_feature(
      Const.StaticObjectType.SURVEY,
      enu2geodetic(grid[i], origin).slice(0, 2),
      { _tmp: true }
    );
    features.push(feature);
  }
  return features;
};

ParkingSpace.associate_points_to_lines = function (surveyFeatures, lines) {
  var line_ids = this.assign_lines(surveyFeatures, lines);

  var counts = Array(lines.length).fill(0);
  line_ids.forEach((el) => (counts[el] = 1 + (counts[el] || 0)));

  var result = Array.from(Array(counts.length).keys()).sort((a, b) =>
    counts[a] > counts[b] ? -1 : (counts[b] > counts[a]) | 0
  );

  var lines = result.map((i) => lines[i]);

  var line1 = lines[0];
  var line1_length = turf.distance(line1[0], line1[1], { units: 'meters' });
  var line_lengths = lines
    .filter((_, i) => i != 0)
    .map((l) =>
      Math.abs(turf.distance(l[0], l[1], { units: 'meters' }) - line1_length)
    );
  var line2_ind = line_lengths.indexOf(Math.min(...line_lengths)) + 1;

  var line2 = lines[line2_ind];

  // Flip line2 points if the first points are not near each other
  if (
    turf.distance(line1[0], line2[1], { units: 'meters' }) <
    turf.distance(line1[0], line2[0], { units: 'meters' })
  ) {
    line2 = [line2[1], line2[0]];
  }

  lines = [line1];
  if (line2 !== undefined) {
    lines.push(line2);
  }
  line_ids = this.assign_lines(surveyFeatures, lines);

  var line1_features = [],
    line2_features = [];
  for (var i = 0; i < surveyFeatures.length; i++) {
    if (line_ids[i] === 0) line1_features.push(surveyFeatures[i]);
    if (line_ids[i] === 1) line2_features.push(surveyFeatures[i]);
  }

  return [line1, line1_features, line2, line2_features];
};

ParkingSpace.create_temp_points = function (
  line1_points,
  line2_points,
  origin
) {
  var points = [];
  var all_points = [...line1_points, ...line2_points];
  var coordinates = all_points.map((r) => enu2geodetic(r, origin).slice(0, 2));
  for (var i = 0; i < coordinates.length; i++) {
    var p = create_feature(Const.StaticObjectType.DEBUG, coordinates[i]);
    points.push(p);
  }
  return points;
};

ParkingSpace.rotate_points_and_lines = function (
  line1,
  line1_points,
  line2,
  line2_points,
  angle
) {
  var R = rotation(-angle, 2);
  var mid_point = math.divide(math.add(line1[0], line1[1]), 2.0);
  var T = translation(-mid_point[0], -mid_point[1], -mid_point[2]);
  var T_minus = translation(mid_point[0], mid_point[1], mid_point[2]);
  var transform = math.multiply(math.multiply(T, R), T_minus);
  line1[0] = math.multiply(transform, [...line1[0], 1.0])._data.slice(0, 3);
  line1[1] = math.multiply(transform, [...line1[1], 1.0])._data.slice(0, 3);

  mid_point = math.divide(math.add(line2[0], line2[1]), 2.0);
  T = translation(-mid_point[0], -mid_point[1], -mid_point[2]);
  T_minus = translation(mid_point[0], mid_point[1], mid_point[2]);
  transform = math.multiply(math.multiply(T, R), T_minus);
  line2[0] = math.multiply(transform, [...line2[0], 1.0])._data.slice(0, 3);
  line2[1] = math.multiply(transform, [...line2[1], 1.0])._data.slice(0, 3);

  line1_points = line1_points.map((p) => {
    return math.multiply(transform, [...p, 1.0])._data.slice(0, 3);
  });
  line2_points = line2_points.map((p) => {
    return math.multiply(transform, [...p, 1.0])._data.slice(0, 3);
  });
  return [line1, line1_points, line2, line2_points];
};

ParkingSpace.fit_parking_spaces = function (
  features,
  parking_spaces_in_region,
  parking_region,
  rough_width,
  angle,
  prefix,
  start_id,
  id_mulitple,
  reverse_ids,
  length,
  flip_direction,
  rotate
) {
  console.time('fit_parking_spaces');

  if (angle === '' || angle === undefined || isNaN(angle)) {
    angle = null;
  } else {
    angle = turf.degreesToRadians(angle);
  }

  if (rotate === '' || rotate === undefined || isNaN(rotate)) {
    rotate = null;
  } else {
    rotate = turf.degreesToRadians(rotate);
  }

  if (
    rough_width === 0 ||
    rough_width === '' ||
    rough_width === undefined ||
    isNaN(rough_width)
  ) {
    rough_width = null;
  }

  if (
    id_mulitple === 0 ||
    id_mulitple === '' ||
    id_mulitple === undefined ||
    isNaN(id_mulitple)
  ) {
    id_mulitple = 1;
  }

  var removalFeatures = features.filter((f) => f.properties.removal);
  var surveyFeatures = features.filter(
    (f) =>
      !f.properties.removal &&
      !f.properties.ignore &&
      !f.properties.disabled &&
      f.properties._tmp === undefined // && !f.properties.annotated
  );

  var generated_survey_points = false;
  if (surveyFeatures.length === 0) {
    var surveyFeatures = this.generate_survey_features(
      parking_region,
      rough_width,
      length,
      angle
    );
    generated_survey_points = true;
  }

  var polygons = [];
  if (surveyFeatures.length > 3) {
    var features_with_elevation = surveyFeatures
      .filter(
        (f) =>
          f.geometry.coordinates.length === 3 &&
          f.geometry.coordinates[2] !== 0.0
      )
      .map((f) => f.geometry.coordinates[2]);
    var average_elevation =
      features_with_elevation.length > 0
        ? features_with_elevation.reduce((a, b) => a + b) /
          features_with_elevation.length
        : 0.0;

    surveyFeatures.forEach((f, i) => {
      if (
        f.geometry.coordinates.length === 2 ||
        f.geometry.coordinates[2] === 0.0
      ) {
        // const elevation = map.queryTerrainElevation(f.geometry.coordinates);
        surveyFeatures[i].geometry.coordinates[2] = average_elevation;
      }
    });

    var fc = turf.featureCollection(surveyFeatures);
    const rectangle = smallestSurroundingRectangleByArea(fc);

    var i, j;
    var lines = [];
    for (i = 0, j = i + 1; i < 4; i++, j = (j + 1) % 4) {
      var line = turf.lineString([
        rectangle.geometry.coordinates[0][i],
        rectangle.geometry.coordinates[0][j],
      ]);
      var len = turf.length(line, { units: 'meters' });
      if (len > MINIMUM_WIDTH || generated_survey_points === true) {
        lines.push([
          rectangle.geometry.coordinates[0][i],
          rectangle.geometry.coordinates[0][j],
        ]);
      }
    }

    var single_line = false;
    if (lines.length === 2) {
      lines.pop();
      single_line = true;
    }

    var [line1, line1_features, line2, line2_features] =
      this.associate_points_to_lines(surveyFeatures, lines);

    // const origin = line1[0];
    const origin = surveyFeatures[0].geometry.coordinates;
    line1 = [geodetic2enu(line1[0], origin), geodetic2enu(line1[1], origin)];
    if (line2 !== undefined) {
      line2 = [geodetic2enu(line2[0], origin), geodetic2enu(line2[1], origin)];
    }

    // Convert to ENU
    var line1_points = pointFeaturesToENU(line1_features, origin);
    var line2_points = pointFeaturesToENU(line2_features, origin);

    var line1_weights = line1_features.map((f) => {
      return f.properties.annotated === true ? 1 : 100;
    });
    var line2_weights = line2_features.map((f) => {
      return f.properties.annotated === true ? 1 : 100;
    });

    var result = order_points_along_line(line1_points, line1, line1_weights);
    line1_points = result.points;
    line1_weights = result.weights;
    if (line2 !== undefined) {
      result = order_points_along_line(line2_points, line2, line2_weights);
      line2_points = result.points;
      line2_weights = result.weights;
    }

    if (angle === null) {
      angle = estimate_angle(line1, line2, line1_points, line2_points);
      if (DEBUG) {
        console.log('Estimated Angle (deg):', (angle * 180.0) / Math.PI);
      }
    }
    var result = this.estimate_parking_space_width(
      line1,
      line2,
      line1_points,
      line2_points,
      rough_width,
      angle
    );
    if (rough_width === null) {
      rough_width = result[0];
    }

    if (single_line === false) {
      var width = rough_width;
      result = this.remove_outliers(
        line1,
        line2,
        line1_points,
        line2_points,
        line1_weights,
        line2_weights,
        width,
        angle,
        origin
      );
      if (result[0].length !== 0 && result[1].length !== 0) {
        line1_points = result[0];
        line2_points = result[1];
        line1_weights = result[2];
        line2_weights = result[3];
        rough_width = result[4];
      } else {
        console.error(
          'Outlier removal removed all points from at least one side'
        );
      }
      if (DEBUG) {
        // polygons.push(...result[5]);
      }
    }

    var line1_fit = line_fit(line1_points, line1_weights);
    var line2_fit = line_fit(line2_points, line2_weights);

    if (!Number.isNaN(length) && line1_fit !== null && line2_fit === null) {
      var vec = line1_fit.model.vec.getColumn(0);
      var tmp = vec[0];
      vec[0] = -vec[1];
      vec[1] = tmp;

      var corrected_length =
        length * Math.cos(angle) + Math.abs(rough_width * Math.sin(angle));
      var offset = math.multiply(vec, corrected_length);

      var enu1 = math.add(line1_fit.p1, offset);
      var enu2 = math.add(line1_fit.p2, offset);

      line2 = [enu1, enu2];

      // Check if new line is within the defined parking region, otherwise flip projection
      var points = turf.points([
        enu2geodetic(line2[0], origin).slice(0, 2),
        enu2geodetic(line2[1], origin).slice(0, 2),
      ]);
      var polygon = turf.buffer(
        turf.polygon(parking_region.geometry.coordinates),
        1.0,
        { units: 'meters' }
      );
      var ptsWithin = turf.pointsWithinPolygon(points, polygon);
      if (ptsWithin.features.length < 1) {
        vec[0] = -vec[0];
        vec[1] = -vec[1];
        offset = math.multiply(vec, corrected_length);
        enu1 = math.add(line1_fit.p1, offset);
        enu2 = math.add(line1_fit.p2, offset);

        line2 = [enu1, enu2];
      }

      points = turf.points([
        enu2geodetic(line2[0], origin).slice(0, 2),
        enu2geodetic(line2[1], origin).slice(0, 2),
      ]);
      ptsWithin = turf.pointsWithinPolygon(points, polygon);
      if (ptsWithin.features.length >= 1) {
        line2_points = project_points_to_line(line1_points, line2, angle);
        line2_weights = line1_weights;
        line2_fit = line_fit(line2_points, line2_weights);
      } else {
        line2_fit = null;
      }
    }

    if (line1_fit !== null && line2_fit !== null) {
      line1 = [line1_fit.p1, line1_fit.p2];
      line2 = [line2_fit.p1, line2_fit.p2];

      if (!Number.isNaN(length)) {
        var p1 = project_offset_from_line(
          length,
          [line1_fit.p1, line1_fit.p2],
          0.0
        );
        var p2 = project_offset_from_line(
          length,
          [line1_fit.p1, line1_fit.p2],
          1.0
        );
        var polygon = turf.buffer(
          turf.polygon(parking_region.geometry.coordinates),
          0.1,
          { units: 'meters' }
        );
        var points = turf.points([
          enu2geodetic(p1, origin).slice(0, 2),
          enu2geodetic(p2, origin).slice(0, 2),
        ]);
        var ptWithin = turf.pointsWithinPolygon(points, polygon);
        if (ptWithin.features.length !== 0) {
          line2 = [p1, p2];
          line2_points = project_points_to_line(line2_points, line2, angle);
        } else {
          p1 = project_offset_from_line(
            -length,
            [line1_fit.p1, line1_fit.p2],
            0.0
          );
          p2 = project_offset_from_line(
            -length,
            [line1_fit.p1, line1_fit.p2],
            1.0
          );
          points = turf.points([
            enu2geodetic(p1, origin).slice(0, 2),
            enu2geodetic(p2, origin).slice(0, 2),
          ]);
          ptWithin = turf.pointsWithinPolygon(points, polygon);
          if (ptWithin.features.length !== 0) {
            line2 = [p1, p2];
            line2_points = project_points_to_line(line2_points, line2, angle);
          } else {
            alert(
              'Length extends outside of ParkingRegion. Parking spaces will not be extended.'
            );
          }
        }
      }

      result = this.fill_projected_points(
        line1,
        line2,
        line1_points,
        line2_points,
        line1_weights,
        line2_weights,
        angle
      );
      line1_points = result[0];
      line2_points = result[1];
      line1_weights = result[2];
      line2_weights = result[3];

      if (DEBUG) {
        var temp = this.create_temp_points(line1_points, line2_points, origin);
        polygons.push(...temp);
      }

      if (single_line === false) {
        result = this.fill_missing_points(
          line1,
          line2,
          line1_points,
          line2_points,
          line1_weights,
          line2_weights,
          angle,
          rough_width
        );
        line1_points = result[0];
        line2_points = result[1];
        line1_weights = result[2];
        line2_weights = result[3];

        result = this.fill_parking_region(
          parking_region,
          line1,
          line2,
          line1_points,
          line2_points,
          line1_weights,
          line2_weights,
          rough_width,
          angle,
          origin
        );
        line1_points = result[0];
        line2_points = result[1];
        line1_weights = result[2];
        line2_weights = result[3];
      }

      result = this.associate_front_rear_points(
        line1,
        line2,
        line1_points,
        line2_points,
        angle
      );
      var line1_inds = result[0];
      var line2_inds = result[1];

      line1_points = project_points_to_line(line1_points, line1);
      line2_points = project_points_to_line(line2_points, line2);

      result = this.rotate_points_and_lines(
        line1,
        line1_points,
        line2,
        line2_points,
        rotate
      );
      line1 = result[0];
      line1_points = result[1];
      line2 = result[2];
      line2_points = result[3];

      if (flip_direction === true) {
        const tmp = line1_points;
        line1_points = line2_points;
        line2_points = tmp;
      }

      var rectangles = this.create_rectangles(
        line1_points,
        line2_points,
        line1_inds,
        line2_inds,
        angle
      );

      rectangles = this.filter_rectangles(rectangles, rough_width, angle);

      if (reverse_ids === true) {
        rectangles.reverse();
      }

      var parking_spaces = this.create_polygons(
        rectangles,
        prefix,
        start_id,
        id_mulitple,
        origin,
        removalFeatures
      );

      if (parking_spaces_in_region.length !== 0) {
        parking_spaces.forEach((r, i) => {
          var feature_id = null;
          var matched_parking_space = parking_spaces_in_region.filter(
            (f) => f.properties.user_parking_id === r.properties.parking_id
          );
          if (matched_parking_space.length === 1) {
            if (turf.booleanIntersects(r, matched_parking_space[0])) {
              feature_id = matched_parking_space[0].properties.id;
            }
          }
          if (feature_id !== null) {
            parking_spaces[i].id = feature_id;
          }
        });
      }

      polygons.push(...parking_spaces);
    }
  }

  console.timeEnd('fit_parking_spaces');
  return polygons;
};

export default ParkingSpace;
