import * as Const from '../constants';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as turf from '@turf/turf';
import * as geojsonhint from '@mapbox/geojsonhint/lib/object';
import * as rewind from '@mapbox/geojson-rewind';

import { geodetic2enu } from './Geodetic';

export function isValid(feature) {
  try {
    const id = feature.id;
    const static_type = feature.properties.static_type;
    const coordinates = feature.geometry.coordinates;
    const geojson_type = feature.geometry.type;
    const properties = feature.properties;

    if (!Object.values(Const.StaticObjectType).includes(static_type)) {
      return false;
    }

    if (
      (static_type === Const.StaticObjectType.BUILDING ||
        static_type === Const.StaticObjectType.OBSTACLE ||
        static_type === Const.StaticObjectType.PARKING_REGION ||
        static_type === Const.StaticObjectType.PERIMETER) &&
      (coordinates[0].length < Const.POLYGON_MINIMUM_NUMBER_OF_POINTS ||
        geojson_type !== Constants.geojsonTypes.POLYGON)
    ) {
      return false;
    }

    if (
      static_type === Const.StaticObjectType.DOCK &&
      coordinates.length !== Const.DOCK_NUMBER_OF_POINTS
    ) {
      return false;
    }

    // TODO: Once we remove limitation of rectangular obstacles
    // we can remove this check.
    if (
      static_type === Const.StaticObjectType.OBSTACLE &&
      coordinates[0].length !== 5
    ) {
      return false;
    }

    if (
      static_type === Const.StaticObjectType.PARKING &&
      (coordinates[0].length !== Const.PARKING_NUMBER_OF_POINTS ||
        !properties.parking_id.includes('parking_'))
    ) {
      return false;
    }

    if (
      static_type === Const.StaticObjectType.SURVEY &&
      ([coordinates].length !== Const.SURVEY_NUMBER_OF_POINTS ||
        isFinite(coordinates[0]) !== true)
    ) {
      return false;
    }

    if (
      static_type === Const.StaticObjectType.STOP &&
      coordinates.length !== Const.STOP_NUMBER_OF_POINTS
    ) {
      return false;
    }

    return true;
  } catch (e) {
    console.error(feature);
    console.error(e);
    return false;
  }
}

export function lintGeoJSON(geojson) {
  return geojsonhint.hint(geojson, { precisionWarning: false });
}

export function sortFeatures(features) {
  function sortFunc(a, b) {
    // Sort by static type
    if (a.properties.static_type < b.properties.static_type) return -1;
    // If PARKING type, sort by the number in the ID (parking_123, parking_222, etc.)
    else if (
      a.properties.static_type === Const.StaticObjectType.PARKING &&
      b.properties.static_type === Const.StaticObjectType.PARKING
    ) {
      if (parseInt(a.id.split('_')[1]) > parseInt(b.id.split('_')[1])) return 1;
      else return -1;
    }
    // If DOCK type, sort by the parking_id number in the same fashion as the PARKING type
    else if (
      a.properties.static_type === Const.StaticObjectType.DOCK &&
      b.properties.static_type === Const.StaticObjectType.DOCK
    ) {
      if (
        parseInt(a.properties.parking_id.split('_')[1]) >
        parseInt(b.properties.parking_id.split('_')[1])
      )
        return 1;
      else return -1;
    } else return 1;
  }
  return features.sort(sortFunc);
}

export function validateFeatures(features) {
  return [
    features.filter((feature) => isValid(feature)),
    features.filter((feature) => !isValid(feature)),
  ];
}

export const isBoolean = (val) =>
  (val === true || val === false) && 'boolean' === typeof val;

export const cleanFeature = (feature, exporting = true) => {
  // Replace Survey point lat/lng coordinate with original measurement coordinates
  if (
    feature.properties.static_type === Const.StaticObjectType.SURVEY &&
    feature.properties.annotated === false
  ) {
    feature.geometry.coordinates = feature.properties._coordinates;
  }

  if (feature.properties.static_type === Const.StaticObjectType.PARKING) {
    // Check if the polygon is in clockwise order and reverse points
    // For parking spaces, we want to make sure the point ordering follows:
    //
    // 1/5    4
    // |      | 1: First point
    // |      | Coordinate convention: counter-clockwise from first point
    // |      |
    // --------
    // 2      3
    if (turf.booleanClockwise(turf.polygonToLine(feature))) {
      var coordinates = [
        feature.geometry.coordinates[0][3],
        feature.geometry.coordinates[0][2],
        feature.geometry.coordinates[0][1],
        feature.geometry.coordinates[0][0],
        feature.geometry.coordinates[0][3],
      ];
      feature.geometry.coordinates = [coordinates];
    }
  }

  // Polygon types should be counter-clockwise
  // For Multi-Polygons, outer should be counter-clockwise and inner clockwise
  if (feature.geometry.type === Constants.geojsonTypes.POLYGON) {
    // feature = turf.rewind(feature);
  }

  // TODO: Eventually this should be removed, but for now the "access" field is appended
  // to the DrivableArea type so the occupancy grid can understand whether an area
  // is open or restricted. In the future, the DrivableArea could be stored as a
  // MultiPolygon and the properties of each map feature be published in Beyond
  if (feature.properties.static_type === Const.StaticObjectType.DRIVABLE_AREA) {
    feature.id =
      feature.id
        .replace('_' + Const.DrivableAreaType.OPEN, '')
        .replace('_' + Const.DrivableAreaType.RESTRICTED, '') +
      '_' +
      feature.properties.access;
  }

  // Remove any internal properties
  Object.keys(feature.properties).forEach((property) => {
    if (property[0] === '_') {
      delete feature.properties[property];
    }
  });

  if (!('timestamp' in feature.properties)) {
    feature.properties.timestamp = new Date().toISOString();
  }

  if (exporting === true) {
    // Remove the timestamp
    delete feature.properties.timestamp;

    // TODO: Once we move fully to UUIDs for parking spaces, this can be removed
    if (feature.properties.static_type === Const.StaticObjectType.PARKING) {
      feature.id = feature.properties.parking_id;
    }

    if (feature.properties.static_type === Const.StaticObjectType.DOCK) {
      feature.id = feature.properties.dock_id;
    }
  } else {
    // If a map file is open that the parking space IDs were of type "parking_XYZ", we want to
    // keep that the case, so copy the current parking_id back over to the ID
    // TODO: Once we move fully to UUIDs for parking spaces, this can be removed
    if (
      feature.properties.static_type === Const.StaticObjectType.PARKING &&
      feature.id.includes('_')
    ) {
      feature.id = feature.properties.parking_id;
    }
    // Same for dock doors
    if (
      feature.properties.static_type === Const.StaticObjectType.DOCK &&
      feature.id.includes('_')
    ) {
      feature.id = feature.properties.dock_id;
    }
  }

  return feature;
};

export const clean_all_features = (features) => {
  if (features.length === 0) {
    return features;
  }
  const N_DECIMAL_PLACES = 3; // centimeter
  var origins = {};
  for (var i = 0; i < features.length; i++) {
    var epsg;
    if (features[i].properties.epsg === undefined) {
      epsg = Const.defaultEPSG.toString();
    } else {
      epsg = features[i].properties.epsg.toString();
    }
    if (!origins.hasOwnProperty(epsg)) {
      var origin;
      var coordinates = features[i].geometry.coordinates;
      if (features[i].geometry.type === 'LineString') {
        origin = coordinates[0];
      } else if (features[i].geometry.type === 'Polygon') {
        origin = coordinates[0][0];
      } else {
        origin = coordinates;
      }
      origins[epsg] = origin;
    }
  }
  const seen = {};
  var filtered_features = features.filter((f) => {
    var coords;
    if (turf.getType(f) === 'Point') coords = [f.geometry.coordinates];
    if (turf.getType(f) === 'LineString') coords = f.geometry.coordinates;
    if (turf.getType(f) === 'Polygon') coords = f.geometry.coordinates[0];
    var epsg;
    if (f.properties.epsg === undefined) {
      epsg = Const.defaultEPSG.toString();
    } else {
      epsg = f.properties.epsg.toString();
    }
    var value = '';
    coords.forEach((c) => {
      const enu = geodetic2enu(c, origins[epsg]);
      value += `${enu[0].toFixed(N_DECIMAL_PLACES)}:${enu[1].toFixed(
        N_DECIMAL_PLACES
      )}:`;
    });
    return seen.hasOwnProperty(value) ? false : (seen[value] = true);
  });
  return filtered_features;
};

export const fixDockDoors = (features) => {
  // Make sure dock doors are oriented correctly to the parking space
  var adjusted_features = features.map((f) => {
    if (f.properties.static_type === Const.StaticObjectType.DOCK) {
      const dock = f.geometry.coordinates;
      var parking = features.find(
        (f1) =>
          f1.properties.static_type === Const.StaticObjectType.PARKING &&
          f1.id === f.properties.parking_id
      );
      if (parking === undefined) {
        return f;
      }
      parking = parking.geometry.coordinates[0];
      // Flip the dock door if not correctly oriented
      if (
        turf.distance(dock[0], parking[1], { units: 'meters' }) >
        turf.distance(dock[0], parking[2], { units: 'meters' })
      ) {
        f.geometry.coordinates.reverse();
      }
    }
    return f;
  });
  return adjusted_features;
};

export const validate_map = (features) => {
  var required_static_types = [
    Const.StaticObjectType.DOCK,
    Const.StaticObjectType.DRIVABLE_AREA,
    Const.StaticObjectType.OBSTACLE,
    Const.StaticObjectType.PARKING,
    Const.StaticObjectType.PERIMETER,
  ];

  var missing_types = [];
  required_static_types.map((type) => {
    var has_type = features
      .filter((f) => f.properties.static_type === type)
      .some(Boolean);
    if (!has_type) missing_types.push(type);
  });
  if (missing_types.length === 0) return '';
  return `Missing ${missing_types.join(', ')} map objects.`;
};
