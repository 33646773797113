import * as Const from '../constants';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import {
  enu2geodetic,
  lineStringFeaturesToENU,
  polygonFeaturesToENU,
} from '../utilities/Geodetic';
import { v4 as uuidv4 } from 'uuid';

import * as turf from '@turf/turf';
import * as math from 'mathjs';

function FeatureException(message, value) {
  this.name = 'FeatureException';
  this.message = message;
}

export function create_feature_properties(static_type, props = {}) {
  const properties = { ...create_default_properties(), ...props };
  if (static_type === Const.StaticObjectType.BUILDING) {
    return create_building_properties(properties);
  } else if (static_type === Const.StaticObjectType.DOCK) {
    return create_dock_properties(properties);
  } else if (static_type === Const.StaticObjectType.DRIVABLE_AREA) {
    return create_drivable_area_properties(properties);
  } else if (static_type === Const.StaticObjectType.OBSTACLE) {
    return create_obstacle_properties(properties);
  } else if (static_type === Const.StaticObjectType.PARKING) {
    return create_parking_properties(properties);
  } else if (static_type === Const.StaticObjectType.PARKING_REGION) {
    return create_parking_region_properties(properties);
  } else if (static_type === Const.StaticObjectType.PERIMETER) {
    return create_perimeter_properties(properties);
  } else if (static_type === Const.StaticObjectType.REGION) {
    return create_region_properties(properties);
  } else if (static_type === Const.StaticObjectType.STOP) {
    return create_stop_properties(properties);
  } else if (static_type === Const.StaticObjectType.SURVEY) {
    return create_survey_properties(properties);
  } else if (static_type === Const.StaticObjectType.DEBUG) {
    return create_debug_properties(properties);
  }
  return props;
}

export function create_feature(static_type, lnglat, props = {}, id = null) {
  var geometry = {};
  if (static_type === Const.StaticObjectType.BUILDING) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.DOCK) {
    geometry.type = Constants.geojsonTypes.LINE_STRING;
  } else if (static_type === Const.StaticObjectType.DRIVABLE_AREA) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.OBSTACLE) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.PARKING) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.PARKING_REGION) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.PERIMETER) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.REGION) {
    geometry.type = Constants.geojsonTypes.POLYGON;
  } else if (static_type === Const.StaticObjectType.STOP) {
    geometry.type = Constants.geojsonTypes.LINE_STRING;
  } else if (static_type === Const.StaticObjectType.SURVEY) {
    geometry.type = Constants.geojsonTypes.POINT;
  } else if (static_type === Const.StaticObjectType.DEBUG) {
    geometry.type =
      lnglat.length === 1
        ? Constants.geojsonTypes.POLYGON
        : Constants.geojsonTypes.POINT;
  } else {
    throw new FeatureException(`Invalid static_type (${static_type})`);
  }

  // Set coordinates
  geometry.coordinates = lnglat;

  // Set properties
  var properties = create_feature_properties(static_type, props);

  var feature = turf.feature(geometry, properties);
  feature.id = id !== null ? id : uuidv4().replaceAll('-', '');

  return feature;
}

export function create_default_properties() {
  return {
    disabled: false,
    timestamp: new Date(),
  };
}

export function create_survey_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.SURVEY,
    annotated: true,
    removal: false,
    ignore: false,
    epsg: 9056,
    description: '',
    _coordinates: [],
    ...props,
  };
}

export function create_dock_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.DOCK,
    parking_id: '',
    dock_id: '',
    height: 2,
    ...props,
  };
}

export function create_obstacle_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.OBSTACLE,
    ...props,
  };
}

export function create_perimeter_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.PERIMETER,
    ...props,
  };
}

export function create_region_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.REGION,
    type: Const.RegionType.WALKWAY,
    ...props,
  };
}

export function create_building_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.BUILDING,
    height: 8,
    ...props,
  };
}

export function create_drivable_area_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.DRIVABLE_AREA,
    access: Const.DrivableAreaType.OPEN,
    ...props,
  };
}

export function create_stop_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.STOP,
    ...props,
  };
}

export function create_parking_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.PARKING,
    ...props,
  };
}

export function create_parking_region_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.PARKING_REGION,
    id_prefix: 'parking_', // TODO: Remove this once we move away from 'parking_' IDs
    start_id: 0,
    id_multiple: 1,
    reverse_ids: false,
    length: '',
    flip_direction: false,
    angle: 0,
    rough_width: 0,
    rotate: 0,
    ...props,
  };
}

export function create_debug_properties(props) {
  const default_props = create_default_properties();
  return {
    ...default_props,
    static_type: Const.StaticObjectType.DEBUG,
    _tmp: true,
    ...props,
  };
}

export function create_dock_for_parking_space(parking_space) {
  const properties = { parking_id: parking_space.properties.parking_id };
  // Calculate center coordinate of Dock by projecting to ENU, averaging,
  // then reprojecting to lat lng
  const origin = parking_space.geometry.coordinates[0][0];
  const enu = polygonFeaturesToENU([parking_space], origin);
  const center_enu = [
    (enu[0][1][0] + enu[0][2][0]) / 2.0,
    (enu[0][1][1] + enu[0][2][1]) / 2.0,
    (enu[0][1][2] + enu[0][2][2]) / 2.0,
  ];
  const center = enu2geodetic(center_enu, origin);
  // Grab the end points of the Dock
  var lnglat = parking_space.geometry.coordinates[0].slice(1, 3);
  // Insert the center point into the array
  lnglat.splice(1, 0, center);
  lnglat = lnglat.map((p) => p.slice(0, 2));
  var feature = create_feature(Const.StaticObjectType.DOCK, lnglat, properties);

  // TODO: Remove this once we move away from 'parking_' IDs
  feature.properties.dock_id =
    'dock_' + parking_space.properties.parking_id.split('_')[1];
  return feature;
}

export function create_parking_spaces_for_docks(docks) {
  var docks_copy = [...docks];
  const origin = docks[0].geometry.coordinates[0];
  var angles = [];
  const docks_enu = lineStringFeaturesToENU(docks, origin);
  for (var i = 0; i < docks.length; i++) {
    const angle = math.atan2(
      docks_enu[i][2][1] - docks_enu[i][0][1],
      docks_enu[i][2][0] - docks_enu[i][0][0]
    );
    angles.push(angle);
  }

  const average_angle = math.mean(angles) - math.pi / 2.0;

  const length = 15.0;
  var features = [];
  for (var i = 0; i < docks.length; i++) {
    const p2 = docks_enu[i][0];
    const p3 = docks_enu[i][2];
    const p1 = math.add(p2, [
      math.cos(average_angle) * length,
      math.sin(average_angle) * length,
      0.0,
    ]);
    const p4 = math.add(p3, [
      math.cos(average_angle) * length,
      math.sin(average_angle) * length,
      0.0,
    ]);
    var points = [p1, p2, p3, p4, p1];

    var lnglat = points.map((p) => enu2geodetic(p, origin));
    lnglat = lnglat.map((p) => p.slice(0, 2));

    var dock_id = docks[i].id;
    if (!dock_id.includes('dock_')) {
      dock_id = 'dock_' + dock_id;
    }
    var parking_id = dock_id.replace('dock', 'parking');
    var properties = create_parking_properties({
      dock_id: dock_id,
      parking_id: parking_id,
    });

    docks_copy[i].properties.dock_id = dock_id;
    docks_copy[i].properties.parking_id = parking_id;

    var feature = create_feature(
      Const.StaticObjectType.PARKING,
      [lnglat],
      properties
    );
    features.push(feature);
  }
  return [features, docks_copy];
}

export function merge(original, to_be_merged) {
  var merged = turf.featureCollection(original.features);
  to_be_merged.features.forEach((f1, i) => {
    var matched_original = original.features.filter((f2) =>
      turf.booleanEqual(f1, f2)
    );
    if (matched_original.length !== 0) {
      // console.log(matched_original);
    }
  });
  // https://stackoverflow.com/a/36744732
  //   obj.arr = obj.arr.filter((value, index, self) =>
  //   index === self.findIndex((t) => (
  //     t.place === value.place && t.name === value.name
  //   ))
  // )
}
