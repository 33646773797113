import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import useStateRef from 'react-usestateref';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { RulerControl, StylesControl } from 'mapbox-gl-controls';

import { TxRectMode } from 'mapbox-gl-draw-rotate-scale-rect-mode';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

import AppContext from './AppContext';
import Theme from './Theme';
import Toolbar from './Toolbar';
import Sidebar from './Sidebar';
import Layers from './Layers';
import SidebarProperties from './SidebarProperties';
import SidebarImportSurvey from './SidebarImportSurvey';
import { additional_map_layers } from './additional_map_layers';
import * as Const from '../../constants';

import ParkingDesignerMode from '../../modes/ParkingDesignerMode';
import PointMode from '../../modes/PointMode';
import PolygonMode from '../../modes/PolygonMode';
import LineStringMode from '../../modes/LineStringMode';
import SimpleSelectKeyboardInputMode from '../../modes/SimpleSelectKeyboardInputMode';
import DirectSelectKeyboardInputMode from '../../modes/DirectSelectKeyboardInputMode';

import ParkingSpace from '../../utilities/ParkingSpace';
import {
  geodetic2enu,
  enu2geodetic,
  polygonFeaturesToENU,
} from '../../utilities/Geodetic';
import {
  distance_between_points,
  point_to_line_distance,
  project_point_to_line,
  order_points_along_line,
  angle_diff,
} from '../../utilities/Geometry';
import {
  aisle_statistics,
  create_dock_door_line,
  create_parking_front_line,
} from '../../utilities/Map';
import { line_fit } from '../../utilities/LineFit';
import { groupBy } from '../../utilities/Utilities';
import { transform } from '../../utilities/Transform';
import { validateFeatures } from '../../utilities/Validator';
import * as math from 'mathjs';

import 'mapbox-gl/dist/mapbox-gl.css';
import '../css/Map.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl-controls/lib/controls.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = (props) => {
  const {
    isMapboxLoaded,
    setIsMapboxLoaded,
    map,
    setMap,
    draw,
    selectedFeatures,
    setSelectedFeatures,
    setDraw,
    historyState,
    setHistory,
    getHistory,
    historyStateIndex,
    historyStateLastIndex,
    historyLength,
  } = useContext(AppContext);

  const mapContainerRef = useRef(null);
  const [lastMapFeatures, setLastMapFeatures, lastMapFeaturesRef] = useStateRef(
    []
  );

  useEffect(() => {
    if (isMapboxLoaded) {
      if (historyStateIndex < historyStateLastIndex) {
        var history = getHistory(historyStateIndex + 1);
        if (history !== undefined) {
          if (
            history.reverse.type === 'create' ||
            history.reverse.type === 'update'
          ) {
            var features = history.reverse.features;
            draw.add(turf.featureCollection(features));
          } else if (history.reverse.type === 'delete') {
            var ids = history.reverse.features.map((f) => f.id);
            draw.delete(ids);
          }
        }
      } else if (historyStateIndex > historyStateLastIndex) {
        var history = getHistory(historyStateIndex);
        if (history !== undefined) {
          if (
            history.forward.type === 'create' ||
            history.reverse.type === 'update'
          ) {
            var features = history.forward.features;
            draw.add(turf.featureCollection(features));
          } else if (history.forward.type === 'delete') {
            var ids = history.forward.features.map((f) => f.id);
            draw.delete(ids);
          }
        }
      }
      if (historyStateIndex !== historyStateLastIndex) {
        update_additional_layers();
      }
    }
  }, [isMapboxLoaded, historyStateIndex]);

  // Initialize map when component mounts
  useEffect(() => {
    if (!isMapboxLoaded) {
      const map_ = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: Const.tileSources.filter((s) => s.label === 'Google')[0]
          .styleUrl,
        center: Const.defaultCenter,
        zoom: Const.defaultZoom,
        antialias: true,
      });

      const draw_ = new MapboxDraw({
        defaultMode: 'simple_select',
        displayControlsDefault: true,
        modes: {
          ...MapboxDraw.modes,
          [Constants.modes.SIMPLE_SELECT]: SimpleSelectKeyboardInputMode,
          [Constants.modes.DIRECT_SELECT]: DirectSelectKeyboardInputMode,
          [Constants.modes.DRAW_POINT]: PointMode,
          [Constants.modes.DRAW_LINE_STRING]: LineStringMode,
          [Constants.modes.DRAW_POLYGON]: PolygonMode,
          transform: TxRectMode,
          parking: ParkingDesignerMode,
        },
        userProperties: true,
        styles: Theme,
      });

      map_.addControl(draw_, 'top-left');

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        flyTo: { duration: 0 },
      });
      document.getElementById('geocoder').appendChild(geocoder.onAdd(map_));

      map_.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

      map_.addControl(
        new RulerControl({
          units: 'meters',
          labelFormat: (n) => `${n.toFixed(2)} m`,
        }),
        'bottom-left'
      );

      map_.addControl(
        new StylesControl({
          styles: Const.tileSources,
          // onChange: (style) => console.log(style),
        }),
        'bottom-right'
      );

      // Disable keyboard input for panning and tilt to allow for
      // keyboard input during simple_select and direct_select modes to move
      // features around
      map_.keyboard.disable();

      setMap(map_);
      setDraw(draw_);
      setIsMapboxLoaded(true);
    }

    if (isMapboxLoaded) {
      map.on('contextmenu', (e) => {
        navigator.clipboard.writeText(e.lngLat.lat + ',' + e.lngLat.lng);
      });

      map.on('load', () => {
        if (map.getSource('parking-details') === undefined) {
          add_additional_layers();
        }

        // add a sky layer that will show when the map is highly pitched
        map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          },
        });
      });

      map.on('draw.modechange', (e) => {
        console.log('draw.modechange', e);
      });

      map.on('ruler.on', () => draw.changeMode('simple_select'));

      map.on('styledata', () => {
        if (map.getSource('parking-details') === undefined) {
          add_additional_layers();
          update_additional_layers();
        }
      });

      map.on('draw.create', (e) => {
        console.log('draw.create', e);
        if (e.features.length > 0) {
          const features = e.features.map((f) => draw.get(f.id));
          var [validFeatures, invalidFeatures] = validateFeatures(features);
          if (invalidFeatures.length !== 0) {
            invalidFeatures.map((f) => draw.delete(f.id));
            console.error(
              `${invalidFeatures.length} invalid feature(s)! ${invalidFeatures
                .map((f) => f.id)
                .join(', ')}`
            );
            if (validFeatures.length === 0) return;
          }
          const feature = draw.get(validFeatures[0].id);
          if (
            feature.properties.static_type === Const.StaticObjectType.SURVEY ||
            feature.properties.static_type ===
              Const.StaticObjectType.PARKING_REGION
          ) {
            update_parking(feature);
          }
        }
        update_additional_layers();
        setHistory({
          forward: {
            type: 'create',
            features: e.features,
          },
          reverse: {
            type: 'delete',
            features: e.features,
          },
        });
        var all = draw.getAll();
        setLastMapFeatures(all.features);
      });

      map.on('draw.update', (e) => {
        if (e.features.length > 0) {
          const feature = draw.get(e.features[0].id);
          if (
            feature.properties.static_type === Const.StaticObjectType.SURVEY ||
            feature.properties.static_type ===
              Const.StaticObjectType.PARKING_REGION
          ) {
            update_parking(feature);
          }
        }
        update_additional_layers();
        var oldFeatures;
        if (
          historyStateIndex !== historyStateLastIndex &&
          e.features.length === 1
        ) {
          oldFeatures = getHistory(historyStateIndex + 1).forward.features;
        } else {
          var ids = e.features.map((f) => f.id);
          oldFeatures = lastMapFeaturesRef.current.filter((f) =>
            ids.includes(f.id)
          );
        }
        if (oldFeatures) {
          setHistory({
            forward: {
              type: 'update',
              features: e.features,
            },
            reverse: {
              type: 'update',
              features: oldFeatures,
            },
          });
        }
        var all = draw.getAll();
        setLastMapFeatures(all.features);
      });

      map.on('draw.selectionchange', (e) => {
        console.log('draw.selectionchange', e);
        if (e.features.length === 0) {
          props.setOpenSidebar(false);
          setSelectedFeatures(turf.featureCollection([]));
        } else if (e.features.length > 0) {
          setSelectedFeatures(turf.featureCollection(e.features));
          if (
            draw.getMode() === Constants.modes.DIRECT_SELECT ||
            e.features.length > 1 ||
            e.features[0].geometry.type === Constants.geojsonTypes.POINT
          ) {
            props.setOpenSidebar(true);
          }
          if (e.features.length > 0) {
            const feature = draw.get(e.features[0].id);
            if (
              feature.properties.static_type ===
              Const.StaticObjectType.PARKING_REGION
            ) {
              update_parking(feature);
            }
          }
        }
      });

      map.on('draw.delete', (e) => {
        console.log('draw.delete', e);
        if (e.features.length > 0) {
          const feature = e.features[0];
          if (
            feature.properties.static_type === Const.StaticObjectType.SURVEY
          ) {
            const f = get_parking_region_for_survey_feature(feature);
            if (f !== undefined) {
              const parking_region = draw.get(f.properties.id);
              update_parking(parking_region);
            }
          }
        }
        update_additional_layers();
        setHistory({
          forward: {
            type: 'delete',
            features: e.features,
          },
          reverse: {
            type: 'create',
            features: e.features,
          },
        });
        var all = draw.getAll();
        setLastMapFeatures(all.features);
      });
    }

    // Clean up on unmount
    return () => {
      if (isMapboxLoaded) {
        map.remove();
      }
    };
  }, [isMapboxLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const add_additional_layers = () => {
    additional_map_layers.forEach((source) => {
      map.addSource(source.name, {
        type: source.type,
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
      source.layers.forEach((layer) => {
        map.addLayer(layer);
      });
    });
  };

  const update_additional_layers = () => {
    var parking_label_features = [];
    var required_dock_line_features = [];
    var all = draw.getAll();
    if (all && all.features.length > 0) {
      all.features.forEach((feature) => {
        if (feature.properties.static_type === Const.StaticObjectType.PARKING) {
          var f = create_parking_front_line(feature);
          parking_label_features.push(...f);
        }

        if (feature.properties.static_type === Const.StaticObjectType.DOCK) {
          var parking_space = all.features.filter(
            (feat) =>
              feat.properties.static_type === Const.StaticObjectType.PARKING &&
              feat.properties.parking_id === feature.properties.parking_id
          );
          if (parking_space.length === 1) {
            var f = create_dock_door_line(feature, parking_space);
            parking_label_features.push(...f[0]);
            required_dock_line_features.push(...f[1]);
          }
        }
      });
    }

    map.getSource('parking-details').setData({
      type: 'FeatureCollection',
      features: parking_label_features,
    });

    map.getSource('dock-details').setData({
      type: 'FeatureCollection',
      features: required_dock_line_features,
    });

    if (draw.getMode() !== 'parking_region') {
      var aisle_features = aisle_statistics(all);
      map.getSource('aisle-details').setData({
        type: 'FeatureCollection',
        features: aisle_features,
      });
    }

    map.moveLayer('parking-front-line');
    map.moveLayer('parking-id-labels');
    map.moveLayer('aisle-line');
    map.moveLayer('aisle-labels');
    map.moveLayer('required-dock-measurements');
  };

  const update_parking = (feature) => {
    if (feature.properties.static_type === Const.StaticObjectType.SURVEY) {
      const parking_region = get_parking_region_for_survey_feature(feature);
      if (parking_region != null) {
        const points_in_parking_region = get_survey_features_for_parking_region(
          parking_region,
          feature
        );
        const parking_spaces_in_region =
          get_parking_spaces_for_parking_region(parking_region);
        parking_spaces_in_region.map((f) => {
          draw.delete(f.properties.id);
        });
        // navigator.clipboard.writeText(JSON.stringify(points_in_parking_region));
        const polygons = ParkingSpace.fit_parking_spaces(
          points_in_parking_region,
          parking_spaces_in_region,
          parking_region,
          parseFloat(parking_region.properties.user_rough_width),
          parseFloat(parking_region.properties.user_angle),
          parking_region.properties.user_id_prefix,
          parking_region.properties.user_start_id,
          parking_region.properties.user_id_multiple,
          parking_region.properties.user_reverse_ids,
          parseFloat(parking_region.properties.user_length),
          parking_region.properties.user_flip_direction,
          parseFloat(parking_region.properties.user_rotate)
        );
        var polygon_ids = [];
        for (var i = 0; i < polygons.length; i++) {
          const id_ = draw.add(polygons[i]);
          polygon_ids.push(id_);
        }
        const new_polygons = polygon_ids.map((i) => draw.get(i));
        map.fire('draw.create', {
          features: new_polygons,
        });
      }
    } else if (
      feature.properties.static_type === Const.StaticObjectType.PARKING_REGION
    ) {
      const parking_region = draw.get(feature.id);
      const points_in_parking_region =
        get_survey_features_for_parking_region(parking_region);
      const parking_spaces_in_region =
        get_parking_spaces_for_parking_region(parking_region);
      parking_spaces_in_region.map((f) => {
        draw.delete(f.properties.id);
      });
      // navigator.clipboard.writeText(JSON.stringify(points_in_parking_region));
      const polygons = ParkingSpace.fit_parking_spaces(
        points_in_parking_region,
        parking_spaces_in_region,
        parking_region,
        parseFloat(parking_region.properties.rough_width),
        parseFloat(parking_region.properties.angle),
        parking_region.properties.id_prefix,
        parking_region.properties.start_id,
        parking_region.properties.id_multiple,
        parking_region.properties.reverse_ids,
        parseFloat(parking_region.properties.length),
        parking_region.properties.flip_direction,
        parseFloat(parking_region.properties.rotate)
      );
      var polygon_ids = [];
      for (var i = 0; i < polygons.length; i++) {
        const id_ = draw.add(polygons[i]);
        polygon_ids.push(id_);
      }
      const new_polygons = polygon_ids.map((i) => draw.get(i));
      map.fire('draw.create', {
        features: new_polygons,
      });
    }
  };

  const get_parking_region_for_survey_feature = (feature) => {
    const point = turf.point(feature.geometry.coordinates);
    const sourceId = map.getLayer('gl-draw-point-static.cold').source;
    var parking_region;
    map.getSource(sourceId)._data.features.every(function (f) {
      if (
        f.geometry.type === Constants.geojsonTypes.POLYGON &&
        f.properties.user_static_type === Const.StaticObjectType.PARKING_REGION
      ) {
        const polygon = turf.polygon(f.geometry.coordinates);
        const isWithin = turf.booleanPointInPolygon(point, polygon);
        if (isWithin === true) {
          parking_region = f;
          return false;
        }
      }
      return true;
    });
    return parking_region;
  };

  const get_parking_spaces_for_parking_region = (parking_region) => {
    const sourceId = map.getLayer('gl-draw-point-static.cold').source;
    var parking_spaces = [];
    map.getSource(sourceId)._data.features.forEach(function (feature) {
      if (
        feature.geometry.type === Constants.geojsonTypes.POLYGON &&
        feature.properties.user_static_type === Const.StaticObjectType.PARKING
      ) {
        const points = turf.points(feature.geometry.coordinates[0]);
        const polygon = turf.polygon(parking_region.geometry.coordinates);
        const ptsWithin = turf.pointsWithinPolygon(points, polygon);
        if (ptsWithin.features.length > 2) {
          parking_spaces.push(feature);
        }
      }
    });
    return parking_spaces;
  };

  const get_survey_features_for_parking_region = (
    parking_region,
    feature = null
  ) => {
    const sourceId = map.getLayer('gl-draw-point-static.cold').source;
    var features = [];
    if (feature != null) {
      features.push(draw.get(feature.id));
    }
    map.getSource(sourceId)._data.features.forEach(function (feature) {
      if (
        feature.geometry.type === Constants.geojsonTypes.POINT &&
        feature.properties.user_static_type === Const.StaticObjectType.SURVEY &&
        !feature.properties.user_ignore &&
        !feature.properties.user_disabled
      ) {
        features.push(draw.get(feature.properties.id));
      }
    });
    const points = {
      type: 'FeatureCollection',
      features: features.map((f) =>
        turf.point(f.geometry.coordinates, f.properties, { id: f.id })
      ),
    };
    const polygon = turf.polygon(
      parking_region.geometry.coordinates,
      parking_region.properties,
      { id: parking_region.id }
    );
    const ptsWithin = turf.pointsWithinPolygon(points, polygon);
    return ptsWithin.features;
  };

  const update_features = (features) => {
    draw.add(turf.featureCollection(features));
    map.fire(Constants.events.UPDATE, {
      features: features,
    });
  };

  return (
    <div>
      <div id="map" className="min-100" ref={mapContainerRef} />

      <Toolbar />
      <Layers />
      {props.openSidebar && (
        <Sidebar open={props.openSidebar} setOpen={props.setOpenSidebar}>
          {selectedFeatures.features.length > 0 && (
            <SidebarProperties
              features={selectedFeatures.features}
              updateFeatures={update_features}
            />
          )}
          {selectedFeatures.features.length === 0 && (
            <SidebarImportSurvey
              open={props.openSidebar}
              setOpen={props.setOpenSidebar}
            />
          )}
        </Sidebar>
      )}
    </div>
  );
};

export default Map;
