import { useMemo, useState } from 'react';
import useStateRef from 'react-usestateref';
import isEqual from 'lodash/isEqual';

const init = {
  forward: { type: 'empty', features: [] },
  reverse: { type: 'empty', features: [] },
};

export default function useUndoableState() {
  const [states, setStates, statesRef] = useStateRef([init]); // Used to store history of all states
  const [index, setIndex, indexRef] = useStateRef(0); // Index of current state within `states`
  const [lastIndex, setLastIndex, lastIndexRef] = useStateRef(0); // Index of last state within `states`
  const state = useMemo(() => states[index], [states, index]); // Current state
  const setState = (value) => {
    // Use lodash isEqual to check for deep equality
    // If state has not changed, return to avoid triggering a re-render
    if (isEqual(statesRef.current[indexRef.current], value)) {
      return;
    }

    const copy = statesRef.current.slice(0, indexRef.current + 1); // This removes all future (redo) states after current index
    copy.push(value);

    setStates(copy);
    setLastIndex(copy.length - 1);
    setIndex(copy.length - 1);
  };

  // Clear all state history
  const resetState = () => {
    setIndex(0);
    setLastIndex(0);
    setStates([init]);
  };

  // Allows you to go back (undo) N steps
  const goBack = (steps = 1) => {
    setLastIndex(indexRef.current);
    setIndex(Math.max(0, Number(indexRef.current) - (Number(steps) || 1)));
  };

  // Allows you to go forward (redo) N steps
  const goForward = (steps = 1) => {
    setLastIndex(indexRef.current);
    setIndex(
      Math.min(
        states.length - 1,
        Number(indexRef.current) + (Number(steps) || 1)
      )
    );
  };

  const getState = (ind) => {
    return statesRef.current[ind];
  };

  return {
    state: statesRef.current[indexRef.current],
    setState,
    getState,
    resetState,
    index: indexRef.current,
    lastIndex: lastIndexRef.current,
    statesLength: statesRef.current.length,
    goBack,
    goForward,
  };
}
