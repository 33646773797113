import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';

const DirectSelectKeyboardInputMode = {
  ...MapboxDraw.modes.direct_select,
};

DirectSelectKeyboardInputMode.onSetup = function (opts) {
  const featureId = opts.featureId;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter direct_select mode');
  }

  if (feature.type === Constants.geojsonTypes.POINT) {
    throw new TypeError("direct_select mode doesn't handle point features");
  }

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
    isShiftHeldDown: false,
  };

  this.setSelectedCoordinates(
    this.pathsToCoordinates(featureId, state.selectedCoordPaths)
  );
  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true,
  });

  return state;
};

DirectSelectKeyboardInputMode.onKeyDown = function (state, e) {
  if (e.keyCode === 16) {
    // Shift
    state.isShiftHeldDown = true;
  }
  if (
    e.keyCode === 38 || // Up
    e.keyCode === 40 || // Down
    e.keyCode === 37 || // Left
    e.keyCode === 39 // Right
  ) {
    if (this.getSelected().length > 0) {
      const delta = this.setDelta(state, e);
      if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
      else this.dragFeature(state, e, delta);
      this.fireUpdate();
    }
  }
};

DirectSelectKeyboardInputMode.setDelta = function (state, e) {
  const offset = 0.0000001;
  var lat = 0;
  var lng = 0;
  switch (e.keyCode) {
    case 38: // Up
      lat += offset;
      break;
    case 40: // Down
      lat -= offset;
      break;
    case 37: // Left
      lng -= offset;
      break;
    case 39: // Right
      lng += offset;
      break;
    default:
      break;
  }
  var scale = state.isShiftHeldDown ? 1 : 10;
  return { lng: scale * lng, lat: scale * lat };
};

DirectSelectKeyboardInputMode.onKeyUp = function (state, e) {
  if (e.keyCode === 16) {
    // Shift
    state.isShiftHeldDown = false;
  }
};

export default DirectSelectKeyboardInputMode;
