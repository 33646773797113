import * as math from 'mathjs';
import * as turf from '@turf/turf';

import { enu2geodetic, geodetic2enu } from './Geodetic';
import {
  angle_diff,
  distance_between_points,
  order_points_along_line,
  point_to_line_distance,
  project_point_to_line,
} from './Geometry';
import * as Const from '../constants';

export function dock_door_lateral_offset(parking_space, dock) {
  var origin = parking_space.geometry.coordinates[0][1];
  var parking_space_enu = parking_space.geometry.coordinates[0].map((p) =>
    geodetic2enu(p, origin)
  );
  var dock_enu = dock.geometry.coordinates.map((p) => geodetic2enu(p, origin));
  var dock_center = math.mean([dock_enu[0], dock_enu[2]], 0);
  var [pt, t] = project_point_to_line(dock_center, [
    parking_space_enu[1],
    parking_space_enu[2],
  ]);
  var dist = distance_between_points(
    parking_space_enu[1],
    parking_space_enu[2]
  );
  var lateral_offset = (t - 0.5) * dist;
  return lateral_offset;
}

export function aisle_statistics(data) {
  var features = [];
  if (data.features.length !== 0) {
    var parking_space_regions = data.features.filter(
      (f) => f.properties.static_type === Const.StaticObjectType.PARKING_REGION
    );
    if (parking_space_regions.length > 0) {
      var front_lines = [],
        rear_lines = [];
      var origin;
      for (var i = 0; i < parking_space_regions.length; i++) {
        if (parking_space_regions[i].geometry.coordinates[0].length > 2) {
          var parking_spaces = data.features.filter(
            (f) =>
              f.properties.static_type === Const.StaticObjectType.PARKING &&
              f.geometry.coordinates[0]
                .map((p) => turf.inside(p, parking_space_regions[i]))
                .some(Boolean)
          );

          if (parking_spaces.length > 0) {
            if (origin === undefined) {
              origin = parking_spaces[0].geometry.coordinates[0][0];
            }
            var front_line_p1 = geodetic2enu(
              parking_spaces[0].geometry.coordinates[0][0],
              origin
            );
            var front_line_p2 = geodetic2enu(
              parking_spaces[parking_spaces.length - 1].geometry
                .coordinates[0][3],
              origin
            );
            var rear_line_p1 = geodetic2enu(
              parking_spaces[0].geometry.coordinates[0][1],
              origin
            );
            var rear_line_p2 = geodetic2enu(
              parking_spaces[parking_spaces.length - 1].geometry
                .coordinates[0][2],
              origin
            );
            front_lines.push([front_line_p1, front_line_p2]);
            rear_lines.push([rear_line_p1, rear_line_p2]);
          }
        }
      }

      var pairs = [];
      var dists = [];
      for (var i = 0; i < front_lines.length; i++) {
        var distances = [];
        const angle1 = Math.atan2(
          front_lines[i][1][1] - front_lines[i][0][1],
          front_lines[i][1][0] - front_lines[i][0][0]
        );
        for (var j = 0; j < front_lines.length; j++) {
          const angle2 = Math.atan2(
            front_lines[j][1][1] - front_lines[j][0][1],
            front_lines[j][1][0] - front_lines[j][0][0]
          );
          const ang_diff = angle_diff(angle1, angle2);
          if (i === j || Math.abs(ang_diff) > (5.0 * Math.PI) / 180.0) {
            distances.push(0);
            continue;
          }
          var [d1, t1] = point_to_line_distance(
            front_lines[i][0],
            front_lines[j]
          );
          var [d2, t2] = point_to_line_distance(
            front_lines[i][1],
            front_lines[j]
          );
          var distance = (d1 + d2) / 2.0;
          distances.push(distance);
        }

        var test = distances.filter((d) => d > 1.0);
        if (test.length === 0) continue;
        var closest_ind = distances.indexOf(math.min(test));
        if (closest_ind === -1) continue;
        const pair = [i, closest_ind];
        if (
          !pairs.some((a) => pair.every((v, k) => v === a[k])) &&
          !pairs.some((a) => pair.reverse().every((v, k) => v === a[k]))
        ) {
          pairs.push(pair);
          dists.push(distances[closest_ind]);
        }
      }

      var associated_lines = [];
      for (var i = 0; i < pairs.length; i++) {
        var [d1, t1] = point_to_line_distance(
          front_lines[pairs[i][0]][0],
          rear_lines[pairs[i][1]]
        );
        var [d2, t2] = point_to_line_distance(
          front_lines[pairs[i][0]][1],
          rear_lines[pairs[i][1]]
        );
        const distance1 = (d1 + d2) / 2.0;
        var [d1, t3] = point_to_line_distance(
          rear_lines[pairs[i][0]][0],
          front_lines[pairs[i][1]]
        );
        var [d2, t4] = point_to_line_distance(
          rear_lines[pairs[i][0]][1],
          rear_lines[pairs[i][1]]
        );
        const distance2 = (d1 + d2) / 2.0;
        var [d1, t5] = point_to_line_distance(
          rear_lines[pairs[i][0]][0],
          rear_lines[pairs[i][1]]
        );
        var [d2, t6] = point_to_line_distance(
          rear_lines[pairs[i][0]][1],
          rear_lines[pairs[i][1]]
        );
        const distance3 = (d1 + d2) / 2.0;
        if (
          dists[i] < distance1 &&
          dists[i] < distance2 &&
          dists[i] < distance3
        )
          associated_lines.push([
            front_lines[pairs[i][0]],
            front_lines[pairs[i][1]],
          ]);
        if (
          distance1 < dists[i] &&
          distance1 < distance2 &&
          distance1 < distance3
        )
          associated_lines.push([
            front_lines[pairs[i][0]],
            rear_lines[pairs[i][1]],
          ]);
        if (
          distance2 < dists[i] &&
          distance2 < distance1 &&
          distance2 < distance3
        )
          associated_lines.push([
            rear_lines[pairs[i][0]],
            front_lines[pairs[i][1]],
          ]);
        if (
          distance3 < dists[i] &&
          distance3 < distance1 &&
          distance3 < distance2
        )
          associated_lines.push([
            rear_lines[pairs[i][0]],
            rear_lines[pairs[i][1]],
          ]);
      }

      // Find middle of overlap between associated lines
      for (var i = 0; i < associated_lines.length; i++) {
        const [p1, t1] = project_point_to_line(
          associated_lines[i][0][0],
          associated_lines[i][1]
        );
        const [p2, t2] = project_point_to_line(
          associated_lines[i][0][1],
          associated_lines[i][1]
        );
        const [p3, t3] = project_point_to_line(
          associated_lines[i][1][0],
          associated_lines[i][0]
        );
        const [p4, t4] = project_point_to_line(
          associated_lines[i][1][1],
          associated_lines[i][0]
        );
        const ordered_points1 = order_points_along_line(
          [associated_lines[i][1][0], associated_lines[i][1][1], p1, p2],
          associated_lines[i][1]
        );
        const ordered_points2 = order_points_along_line(
          [associated_lines[i][0][0], associated_lines[i][0][1], p3, p4],
          associated_lines[i][0]
        );
        var start = math.divide(
          math.add(ordered_points1.points[1], ordered_points1.points[2]),
          2.0
        );
        var end = math.divide(
          math.add(ordered_points2.points[1], ordered_points2.points[2]),
          2.0
        );
        var label_center = math.divide(math.add(start, end), 2.0);
        var aisle_width = distance_between_points(start, end);

        if (aisle_width > 1.0 && aisle_width < 50) {
          var f = turf.point(enu2geodetic(label_center, origin));
          f.properties.label = aisle_width.toFixed(1);
          f.properties.aisle = true;
          features.push(f);

          f = turf.lineString([
            enu2geodetic(start, origin),
            enu2geodetic(end, origin),
          ]);
          f.properties.aisle = true;
          features.push(f);
        }
      }
    }
  }
  return features;
}

export function create_parking_front_line(feature) {
  var parking_label_features = [];
  var f = turf.center(feature);
  // TODO: Replace this once we move away from 'parking_' for IDs
  if (feature.properties.parking_id === undefined) {
    f.properties.label = feature.id.replace('parking_', '');
  } else {
    f.properties.label = feature.properties.parking_id.replace('parking_', '');
  }
  f.properties.parking_detail = true;
  parking_label_features.push(f);

  f = turf.lineString([
    feature.geometry.coordinates[0][0].slice(0, 2),
    feature.geometry.coordinates[0][3].slice(0, 2),
  ]);
  f.properties.parking_detail = true;
  parking_label_features.push(f);
  return parking_label_features;
}

export function create_dock_door_line(dock, parking_space) {
  var minimum_offset = 0.05;
  var parking_label_features = [];
  var required_dock_line_features = [];

  var origin = parking_space[0].geometry.coordinates[0][1];
  var parking_space_enu = parking_space[0].geometry.coordinates[0].map((p) =>
    geodetic2enu(p, origin)
  );
  var dock_enu = dock.geometry.coordinates.map((p) => geodetic2enu(p, origin));

  // If the rear parking space corners align with the dock door
  // ends, then we want to mark it as not updated/refined with lidar.
  if (
    distance_between_points(parking_space_enu[1], dock_enu[0]) <
      minimum_offset &&
    distance_between_points(parking_space_enu[2], dock_enu[2]) < minimum_offset
  ) {
    var f = turf.lineString(dock.geometry.coordinates);
    required_dock_line_features.push(f);
  } else {
    // Otherwise, we want to show the lateral offset of the dock
    // relative to the rear of the parking space.
    var lateral_offset = dock_door_lateral_offset(parking_space[0], dock);
    if (Math.abs(lateral_offset) > 0.01) {
      var f = turf.center(dock);
      f.properties.label = (lateral_offset * 100).toFixed(0) + 'cm';
      f.properties.parking_detail = true;
      parking_label_features.push(f);
    }
  }
  return [parking_label_features, required_dock_line_features];
}

export function sort_points(features) {
  var roughly_sorted_features = [...features].sort(function (f1, f2) {
    if (f1.geometry.coordinates[1] == f2.geometry.coordinates[1])
      return f1.geometry.coordinates[0] - f2.geometry.coordinates[0];
    return f1.geometry.coordinates[1] - f2.geometry.coordinates[1];
  });

  var sorted_features = [roughly_sorted_features.pop()];
  var current_feature = sorted_features[0];
  while (roughly_sorted_features.length > 0) {
    var distances = roughly_sorted_features.map((f) =>
      turf.distance(f, current_feature, { units: 'meters' })
    );
    var ind = distances.indexOf(Math.min(...distances));
    // if (distances[ind] > 0.0) {
    sorted_features.push(roughly_sorted_features.splice(ind, 1)[0]);
    current_feature =
      roughly_sorted_features[roughly_sorted_features.length - 1];
    // } else {
    //   roughly_sorted_features.splice(ind, 1);
    //   current_feature =
    //     roughly_sorted_features[roughly_sorted_features.length - 1];
    // }
  }
  return sorted_features;
}
