import * as math from 'mathjs';

export function rotation(angle, axis) {
  const cos_angle = Math.cos(angle);
  const sin_angle = Math.sin(angle);
  var R = math.identity(4);
  if (axis === 0) {
    R.subset(math.index(1, 1), cos_angle);
    R.subset(math.index(2, 2), cos_angle);
    R.subset(math.index(1, 2), sin_angle);
    R.subset(math.index(2, 1), -sin_angle);
  } else if (axis === 1) {
    R.subset(math.index(0, 0), cos_angle);
    R.subset(math.index(2, 2), cos_angle);
    R.subset(math.index(0, 2), -sin_angle);
    R.subset(math.index(2, 0), sin_angle);
  } else if (axis === 2) {
    R.subset(math.index(0, 0), cos_angle);
    R.subset(math.index(1, 1), cos_angle);
    R.subset(math.index(1, 0), -sin_angle);
    R.subset(math.index(0, 1), sin_angle);
  }
  return R;
}

export function translation(x, y, z) {
  var T = math.identity(4);
  T.subset(math.index(0, 3), -x);
  T.subset(math.index(1, 3), -y);
  T.subset(math.index(2, 3), -z);
  return T;
}

// import { Matrix } from 'ml-matrix';

// export function rotation(angle, axis) {
//   const cos_angle = Math.cos(angle);
//   const sin_angle = Math.sin(angle);
//   var R = Matrix.eye(4, 4);
//   if (axis === 0) {
//     R.set(1, 1, cos_angle);
//     R.set(2, 2, cos_angle);
//     R.set(1, 2, sin_angle);
//     R.set(2, 1, -sin_angle);
//   } else if (axis === 1) {
//     R.set(0, 0, cos_angle);
//     R.set(2, 2, cos_angle);
//     R.set(0, 2, -sin_angle);
//     R.set(2, 0, sin_angle);
//   } else if (axis === 2) {
//     R.set(0, 0, cos_angle);
//     R.set(1, 1, cos_angle);
//     R.set(1, 0, -sin_angle);
//     R.set(0, 1, sin_angle);
//   }
//   return R;
// }

// export function translation(x, y, z) {
//   var T = Matrix.eye(4, 4);
//   T.set(0, 2, x);
//   T.set(1, 2, y);
//   T.set(2, 2, z);
//   return T;
// }

// export function transform(x, y, z, roll, pitch, yaw) {
//   var T = translation(x, y, z);
//   var Rx = rotation(roll, 0);
//   var Ry = rotation(pitch, 1);
//   var Rz = rotation(yaw, 2);
//   return Rx.mmul(Ry.mmul(Rz.mmul(T)));
// }
