import React, { useContext, useEffect } from 'react';
import AppContext from './AppContext';

import * as math from 'mathjs';
import * as turf from '@turf/turf';
import * as clustering from 'density-clustering';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import * as Const from '../../constants';
import {
  pointFeaturesToENU,
  polygonFeaturesToENU,
} from '../../utilities/Geodetic';
import { distance_between_points } from '../../utilities/Geometry';
import { sortStringNumbers } from '../../utilities/Utilities';

import '../css/Statistics.css';

const NEIGHBORHOOD_RADIUS = 3.65 * 6;
const MIN_NUMBER_OF_POINTS = 1;

const Statistics = (props) => {
  const { isMapboxLoaded, draw } = useContext(AppContext);

  const parkingSpaceStatistics = () => {
    var data = draw.getAll();

    var parking_spaces = data.features.filter(
      (f) => f.properties.static_type === Const.StaticObjectType.PARKING
    );

    if (parking_spaces.length > 0) {
      var origin = parking_spaces[0].geometry.coordinates[0][0];

      var bearings = parking_spaces.map((p) =>
        turf.bearing(
          turf.point(p.geometry.coordinates[0][0]),
          turf.point(p.geometry.coordinates[0][1])
        )
      );

      var centers = parking_spaces.map(
        (p) => pointFeaturesToENU([turf.center(p)], origin)[0]
      );

      var widths = parking_spaces.map((p) =>
        turf.length(
          turf.lineString([
            p.geometry.coordinates[0][0],
            p.geometry.coordinates[0][3],
          ]),
          { units: 'meters' }
        )
      );

      var lengths = parking_spaces.map((p) =>
        turf.length(
          turf.lineString([
            p.geometry.coordinates[0][0],
            p.geometry.coordinates[0][1],
          ]),
          { units: 'meters' }
        )
      );

      var has_dock = parking_spaces.map((p) => {
        var features = data.features.filter(
          (f) =>
            f.properties.static_type === Const.StaticObjectType.DOCK &&
            f.properties.parking_id === p.id
        );
        return features.length === 1;
      });

      var dataset = parking_spaces.map((_, i) => [
        bearings[i],
        lengths[i],
        widths[i],
        has_dock[i],
        centers[i][0],
        centers[i][1],
      ]);
      var dbscan = new clustering.DBSCAN();
      var clusters = dbscan.run(
        dataset,
        NEIGHBORHOOD_RADIUS,
        MIN_NUMBER_OF_POINTS
      );

      var parking_space_regions = clusters.map((cluster) =>
        cluster.map((i) => parking_spaces[i])
      );
      // Sort the regions alphabetically
      parking_space_regions = parking_space_regions.sort((a, b) => {
        return sortStringNumbers(
          a[0].id.replace('parking_', ''),
          b[0].id.replace('parking_', '')
        );
      });

      return parking_space_regions.map((parking_spaces, i) => {
        var widths = [];
        var lengths = [];
        var parking_ids = parking_spaces
          .map((p) => p.id.replace('parking_', ''))
          .sort(sortStringNumbers);

        if (parking_spaces.length > 0) {
          const origin = parking_spaces[0].geometry.coordinates[0][0];
          var enu_features = polygonFeaturesToENU(parking_spaces, origin);
          widths = enu_features.map((p) => distance_between_points(p[0], p[3]));
          lengths = enu_features.map((p) =>
            distance_between_points(p[0], p[1])
          );
        }

        return (
          parking_spaces.length > 0 && (
            <div key={i}>
              <h5>
                {parking_ids[0]} - {parking_ids[parking_ids.length - 1]} (
                {parking_spaces.length} spaces)
              </h5>
              <Table
                key={i}
                className="statistics"
                size="sm"
                bordered
                responsive
                striped
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Mean</th>
                    <th>Mininum</th>
                    <th>Maximum</th>
                    <th>Median</th>
                    <th>Std Dev (1-&sigma;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="right">Width (m)</td>
                    <td>{math.mean(widths).toFixed(3)}</td>
                    <td>{math.min(widths).toFixed(3)}</td>
                    <td>{math.max(widths).toFixed(3)}</td>
                    <td>{math.median(widths).toFixed(3)}</td>
                    <td>{math.std(widths).toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td align="right">Length (m)</td>
                    <td>{math.mean(lengths).toFixed(3)}</td>
                    <td>{math.min(lengths).toFixed(3)}</td>
                    <td>{math.max(lengths).toFixed(3)}</td>
                    <td>{math.median(lengths).toFixed(3)}</td>
                    <td>{math.std(lengths).toFixed(3)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )
        );
      });
    }
  };

  return (
    isMapboxLoaded &&
    props.show && (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Statistics
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{parkingSpaceStatistics()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default Statistics;
