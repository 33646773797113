import React, { useContext, useState } from 'react';
import * as turf from '@turf/turf';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

import AppContext from './AppContext';
import { create_feature } from '../../utilities/Features';
import * as Const from '../../constants';

import { GlobalHotKeys, configure } from 'react-hotkeys';

configure({
  // ignoreTags: ['input', 'select', 'textarea'],
  // ignoreEventsCondition: (event) => { return false; }
});

// TODO: Using getElementById and click() are not the correct way to handle
// programmaticaly clicking buttons in React

const KeyboardShortcuts = () => {
  const {
    map,
    draw,
    setSelectedToolbarButton,
    setSelectedMenuButton,
    selectedFeatures,
    setSelectedFeatures,
  } = useContext(AppContext);

  const keyMap = {
    CREATE_OBSTACLE: { name: 'Create Obstacle', sequences: ['o'] },
    CREATE_SURVEY: { name: 'Create Survey Point', sequences: ['s'] },
    SELECT: { name: 'Select', sequences: ['escape'] },
    CREATE_PERIMETER: { name: 'Create Perimeter', sequences: ['p'] },
    CREATE_BUILDING: { name: 'Create Building', sequences: ['b'] },
    CREATE_PARKING_REGION: { name: 'Create Parking Region', sequences: ['r'] },
    CREATE_PARKING: { name: 'Create Parking', sequences: ['m'] },
    CREATE_DRIVABLE_AREA: { name: 'Create Drivable Area', sequences: ['a'] },
    CREATE_DOCK: { name: 'Create Dock', sequences: ['d'] },
    // CREATE_PATH: { name: 'Create Path', sequences: ['n'] },
    // MEASURE: { name: 'Measure', sequences: ['m'] },
    CUT: { name: 'Cut', sequences: ['meta+x'] },
    COPY: { name: 'Copy', sequences: ['meta+c'] },
    PASTE: { name: 'Paste', sequences: ['meta+v'] },
    SAVE: { name: 'Save', sequences: ['meta+s'] },
    OPEN: { name: 'Open (not available)', sequences: ['meta+o'] },
    EXPORT: { name: 'Export', sequences: ['meta+e'] },
    SELECT_ALL: { name: 'Select All', sequences: ['meta+a'] },
    UNDO: { name: 'Undo', sequences: ['meta+z'] },
    REDO: { name: 'Redo', sequences: ['meta+y'] },
  };

  const preventDefaultHandlers = (handlers) => {
    const newHandlers = {};
    for (const [action, handler] of Object.entries(handlers)) {
      newHandlers[action] = (event) => {
        if (event) {
          event.preventDefault();
        }
        handler();
      };
    }
    return newHandlers;
  };

  const changeObstacleMode = (e) => {
    setSelectedToolbarButton('obstacle');
  };

  const changeSurveyMode = (e) => {
    setSelectedToolbarButton('survey');
  };

  const changeSelectMode = (e) => {
    setSelectedToolbarButton('select');
  };

  const changePerimeterMode = (e) => {
    setSelectedToolbarButton('perimeter');
  };

  const changeBuildingMode = (e) => {
    setSelectedToolbarButton('building');
  };

  const changeParkingMode = (e) => {
    setSelectedToolbarButton('parking');
  };

  const changeParkingRegionMode = (e) => {
    setSelectedToolbarButton('parking_region');
  };

  const changeDrivableAreaMode = (e) => {
    setSelectedToolbarButton('drivable_area');
  };

  const changeDockMode = (e) => {
    setSelectedToolbarButton('dock');
  };

  const changePathMode = (e) => {
    setSelectedToolbarButton('path');
  };

  const changeMeasureMode = (e) => {
    setSelectedToolbarButton('measure');
  };

  const selectAllMode = (e) => {
    const fc = draw.getAll();
    if (fc.features.length === 0) return;
    var featureIds = fc.features.map((f) => f.id);
    setSelectedFeatures(fc);
    draw.changeMode('simple_select', { featureIds: featureIds });
    map.fire('draw.selectionchange', {
      features: fc.features,
    });
  };

  const saveMode = (e) => {
    setSelectedMenuButton('save');
  };

  const openMode = (e) => {
    setSelectedMenuButton('open');
  };

  const exportMode = (e) => {
    setSelectedMenuButton('export');
  };

  const cutMode = (e) => {
    setSelectedMenuButton('cut');
  };

  const copyMode = (e) => {
    setSelectedMenuButton('copy');
  };

  const pasteMode = (e) => {
    setSelectedMenuButton('paste');
  };

  const undoMode = (e) => {
    setSelectedMenuButton('undo');
  };

  const redoMode = (e) => {
    setSelectedMenuButton('redo');
  };

  const handlers = preventDefaultHandlers({
    CREATE_OBSTACLE: changeObstacleMode,
    CREATE_SURVEY: changeSurveyMode,
    SELECT: changeSelectMode,
    CREATE_PERIMETER: changePerimeterMode,
    CREATE_BUILDING: changeBuildingMode,
    CREATE_PARKING: changeParkingMode,
    CREATE_PARKING_REGION: changeParkingRegionMode,
    CREATE_DRIVABLE_AREA: changeDrivableAreaMode,
    CREATE_DOCK: changeDockMode,
    // CREATE_PATH: changePathMode,
    CUT: cutMode,
    COPY: copyMode,
    PASTE: pasteMode,
    SAVE: saveMode,
    OPEN: openMode,
    EXPORT: exportMode,
    SELECT_ALL: selectAllMode,
    UNDO: undoMode,
    REDO: redoMode,
  });

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />;
};

export default KeyboardShortcuts;
