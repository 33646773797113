import * as Const from '../../constants';

export const additional_map_layers = [
  {
    name: 'parking-details',
    type: 'geojson',
    layers: [
      {
        id: 'parking-front-line',
        source: 'parking-details',
        type: 'line',
        filter: [
          'all',
          ['==', '$type', 'LineString'],
          ['has', 'parking_detail'],
        ],
        paint: {
          'line-color': Const.Color.GREEN,
          'line-width': 2,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
      },
      // TODO: "TypeError: undefined is not an object (evaluating 'e.layout.get'" is caused by any 'symbol' layers (see below as well)
      {
        id: 'parking-id-labels',
        source: 'parking-details',
        type: 'symbol',
        filter: ['all', ['==', '$type', 'Point'], ['has', 'parking_detail']],
        paint: {
          'text-color': 'black',
          'text-halo-color': 'white',
          'text-halo-width': 1,
        },
        layout: {
          'text-field': '{label}',
          'text-size': 12,
        },
      },
    ],
  },
  {
    name: 'aisle-details',
    type: 'geojson',
    layers: [
      {
        id: 'aisle-line',
        source: 'aisle-details',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString'], ['has', 'aisle']],
        paint: {
          'line-color': '#333',
          'line-width': 1,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
      },
      {
        id: 'aisle-labels',
        source: 'aisle-details',
        type: 'symbol',
        filter: ['all', ['==', '$type', 'Point'], ['has', 'aisle']],
        paint: {
          'text-color': 'black',
          'text-halo-color': 'white',
          'text-halo-width': 1,
        },
        layout: {
          'text-field': '{label}m',
          'text-size': 12,
        },
      },
    ],
  },
  {
    name: 'dock-details',
    type: 'geojson',
    layers: [
      {
        id: 'required-dock-measurements',
        source: 'dock-details',
        type: 'line',
        filter: ['==', '$type', 'LineString'],
        paint: {
          'line-color': Const.Color.ORANGE,
          'line-width': 5,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
      },
    ],
  },
  {
    name: 'map-compare',
    type: 'geojson',
    layers: [
      {
        id: 'map-compare-line',
        source: 'map-compare',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString'], ['has', 'compare']],
        paint: {
          'line-color': [
            'case',
            ['==', ['get', 'compare'], true],
            Const.CompareMapColor.FIRST,
            Const.CompareMapColor.SECOND,
          ],
          'line-width': 4,
        },
      },
      {
        id: 'map-compare-polygon-fill',
        source: 'map-compare',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon'], ['has', 'compare']],
        paint: {
          'fill-color': [
            'case',
            ['==', ['get', 'compare'], true],
            Const.CompareMapColor.FIRST,
            Const.CompareMapColor.SECOND,
          ],
          'fill-outline-color': [
            'case',
            ['==', ['get', 'compare'], true],
            Const.CompareMapColor.FIRST,
            Const.CompareMapColor.SECOND,
          ],
          'fill-opacity': 0.1,
        },
      },
      {
        id: 'map-compare-polygon-stroke',
        source: 'map-compare',
        type: 'line',
        filter: ['all', ['==', '$type', 'Polygon'], ['has', 'compare']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': [
            'case',
            ['==', ['get', 'compare'], true],
            Const.CompareMapColor.FIRST,
            Const.CompareMapColor.SECOND,
          ],
          'line-width': 4,
        },
      },
    ],
  },
];
