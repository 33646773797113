import { Matrix, SingularValueDecomposition, determinant } from 'ml-matrix';
import {
  createTree,
  distance_between_points,
  nearest_neighbor,
} from './Geometry';
import { rmse } from './Utilities';

// https://github.com/ClayFlannigan/icp/blob/master/icp.py
export function icp(
  A,
  B,
  init_pose = Matrix.eye(3, 3),
  max_iterations = 20,
  tolerance = 0.01
) {
  var A = new Matrix(A);
  var B = new Matrix(B);
  const m = A.columns;

  // Make points homogeneous, copy them to maintain the originals
  var src = Matrix.ones(m + 1, A.rows);
  var dst = Matrix.ones(m + 1, B.rows);
  src.setSubMatrix(A.transpose(), 0, 0);
  dst.setSubMatrix(B.transpose(), 0, 0);

  // Apply initial pose
  if (init_pose.rows === src.rows) {
    src = init_pose.mmul(src);
  }

  var previous_error = 0.0;
  for (var i = 0; i < max_iterations; i++) {
    // Find the nearest neighbors betweent he current source and destination points
    var nn = _nearest_neighbor(
      src.subMatrix(0, m - 1, 0, src.columns - 1).transpose(),
      dst.subMatrix(0, m - 1, 0, dst.columns - 1).transpose()
    );

    // Compute the transformation between the current source and nearest destination points
    var src_tmp = src.subMatrixColumn(nn.src_indicies, 0, src.rows - 1);
    var dst_tmp = dst.subMatrixColumn(nn.dst_indicies, 0, dst.rows - 1);
    var transform = best_fit_transform(
      src_tmp.subMatrix(0, m - 1, 0, src_tmp.columns - 1).transpose(),
      dst_tmp.subMatrix(0, m - 1, 0, dst_tmp.columns - 1).transpose()
    );

    // Update the current source
    src = transform.transform.mmul(src);

    // Check error
    const mean_error = rmse(nn.distances);
    // if (Math.abs(previous_error - mean_error) < tolerance) {
    //   break;
    // }
    previous_error = mean_error;
  }

  // Calculate final transform
  var transform = best_fit_transform(
    A,
    src.subMatrix(0, m - 1, 0, src.columns - 1).transpose()
  );

  var nn = _nearest_neighbor(
    src.subMatrix(0, m - 1, 0, src.columns - 1).transpose(),
    dst.subMatrix(0, m - 1, 0, dst.columns - 1).transpose()
  );

  // Update the current source
  // src = transform.transform.mmul(src);

  return {
    transform: transform.transform,
    transformed_points: src
      .subMatrix(0, m - 1, 0, src.columns - 1)
      .transpose()
      .to2DArray(),
    distances: nn.distances,
    src_indicies: nn.src_indicies,
    dst_indicies: nn.dst_indicies,
  };
}

function _nearest_neighbor(src, dst) {
  var src2d = src.to2DArray();
  var dst2d = dst.to2DArray();
  const tree = createTree(dst2d);
  var dst_indicies = src2d.map(
    (p) => nearest_neighbor(p, tree, 1, Infinity)[0]
  );
  var distances = src2d.map((p, i) =>
    distance_between_points(p, dst2d[dst_indicies[i]])
  );
  tree.dispose();

  var src_indicies = [...Array(src2d.length).keys()];

  var result = _remove_duplicates(src_indicies, dst_indicies, distances);

  return {
    src_indicies: result.src_indicies,
    dst_indicies: result.dst_indicies,
    distances: result.distances,
  };
}

function best_fit_transform(A, B) {
  const m = A.columns;

  // Translate points to their centroids
  const centroid_A = A.mean('column');
  const centroid_B = B.mean('column');
  const AA = Matrix.sub(A, Matrix.ones(A.rows, m).mulRowVector(centroid_A));
  const BB = Matrix.sub(B, Matrix.ones(B.rows, m).mulRowVector(centroid_B));

  // Rotation matrix
  const H = AA.transpose().mmul(BB);
  const result = new SingularValueDecomposition(H);
  var Vt = result.V.transpose();
  var U = result.U;
  var R = Vt.transpose().mmul(U.transpose());

  // Special reflection case
  if (determinant(R) < 0) {
    Vt = Vt.mulRow(m - 1, -1);
    R = Vt.transpose().mmul(U.transpose());
  }

  // Translation
  const t = Matrix.columnVector(centroid_B).sub(
    R.mmul(Matrix.columnVector(centroid_A))
  );

  // Homogeneous transformation
  var transform = Matrix.eye(m + 1, m + 1);
  transform.setSubMatrix(R, 0, 0);
  transform.setSubMatrix(t, 0, m);
  return { transform: transform, rotation: R, translation: t };
}

Array.prototype.get_duplicates = function () {
  var duplicates = {};
  for (var i = 0; i < this.length; i++) {
    if (duplicates.hasOwnProperty(this[i])) {
      duplicates[this[i]].push(i);
    } else if (this.lastIndexOf(this[i]) !== i) {
      duplicates[this[i]] = [i];
    }
  }
  return duplicates;
};

function _remove_duplicates(src_indicies, dst_indicies, distances) {
  // Remove duplicate associations
  // This could probably be improved
  var duplicates = dst_indicies.get_duplicates();
  var inds_to_remove = [];
  if (Object.keys(duplicates).length !== 0) {
    Object.keys(duplicates).map((key) => {
      var dists = duplicates[key].map((i) => distances[i]);
      var ind_smallest = dists.indexOf(Math.min(...dists));
      inds_to_remove.push(
        ...duplicates[key].filter((_, i) => i !== ind_smallest)
      );
    });
    src_indicies = src_indicies.filter((_, i) => !inds_to_remove.includes(i));
    dst_indicies = dst_indicies.filter((_, i) => !inds_to_remove.includes(i));
    distances = distances.filter((_, i) => !inds_to_remove.includes(i));
  }
  return {
    src_indicies: src_indicies,
    dst_indicies: dst_indicies,
    distances: distances,
  };
}
