function shuffle(unshuffled, n) {
  var shuffled = unshuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled.slice(0, n);
}

export function ransac(
  data,
  weights,
  model_class,
  min_samples = 2,
  threshold = 0.1,
  max_trials = 10
) {
  var best_model = null;
  var best_error = Infinity;
  var best_inlier_num = 0;
  var best_inliers = [];
  var best_outliers = [];
  var data_idx = [...Array(data.length).keys()];
  min_samples = min_samples <= data.length ? min_samples : data.length;

  // desired_propability = 0.99  // probability of outlier-free fit
  // allowable_outlier_percentage = 0.2
  // s = 2.0
  // n = np.log(1.0 - desired_propability) / np.log(
  //     1.0 - (allowable_outlier_percentage) ** s
  // )
  // max_trials = int(n) if n < max_trials else max_trials

  for (var i = 0; i < max_trials; i++) {
    var inds = shuffle(data_idx, min_samples);
    var sample = inds.map((i) => data[i]);
    var sample_weights = inds.map((i) => weights[i]);

    var sample_model = model_class.fit(sample, sample_weights);
    var sample_model_residuals = model_class.residuals(sample_model, data);
    var sample_model_inliers = data.filter(
      (_, i) => sample_model_residuals[i] < threshold
    );
    var sample_model_inlier_weights = weights.filter(
      (_, i) => sample_model_residuals[i] < threshold
    );
    var sample_model_error = Math.sqrt(
      Math.pow(
        sample_model_residuals.reduce((a, b) => a + b) /
          sample_model_residuals.length,
        2
      )
    );
    var inlier_num = sample_model_inliers.length;
    if (inlier_num > best_inlier_num && inlier_num >= min_samples) {
      best_model = model_class.fit(
        sample_model_inliers,
        sample_model_inlier_weights
      );
      best_inlier_num = inlier_num;
    }
  }

  if (best_model !== null) {
    var best_model_residuals = model_class.residuals(best_model, data);
    best_inliers = data.filter((_, i) => best_model_residuals[i] < threshold);
    best_outliers = data.filter((_, i) => best_model_residuals[i] >= threshold);
    best_error = Math.sqrt(
      Math.pow(
        best_model_residuals.reduce((a, b) => a + b) /
          best_model_residuals.length,
        2
      )
    );
  }

  return {
    model: best_model,
    inliers: best_inliers,
    outliers: best_outliers,
    error: best_error,
  };
}
