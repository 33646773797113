import { getApplicationKeyMap } from 'react-hotkeys';
import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

const KeyboardShortcutsModal = (props) => {
  const keyMap = getApplicationKeyMap();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Keyboard Shortcuts
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Action</th>
              <th>Key Combination</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(keyMap).map((actionName) => {
              return (
                <tr key={keyMap[actionName].name || actionName}>
                  <td>{keyMap[actionName].name}</td>
                  <td>
                    {keyMap[actionName].sequences.map(({ sequence }) => (
                      <span key={sequence}>{sequence}</span>
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KeyboardShortcutsModal;
