import React, { useContext } from 'react';
import useStateRef from 'react-usestateref';
import AppContext from './AppContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

import * as Const from '../../constants';

import '../css/Layers.css';

const Layers = () => {
  const { map } = useContext(AppContext);

  var temp = { All: true };
  Object.values(Const.StaticObjectType).map((s) => (temp[s] = true));
  delete temp[Const.StaticObjectType.DEBUG];
  const [layers, setLayers, layersRef] = useStateRef(temp);

  const handleFilterClick = ({ target }) => {
    setLayers((s) => ({ ...s, [target.id]: !s[target.id] }));

    if (target.id === 'All') {
      const layerCopy = { ...layersRef.current };
      Object.keys(layerCopy).forEach(
        (key) => (layerCopy[key] = target.checked)
      );
      setLayers(layerCopy);
    } else {
      const visibleLayers = Object.keys(layersRef.current)
        .filter((k) => layersRef.current[k])
        .filter((l) => l !== 'All');
      if (visibleLayers.length < Object.keys(layersRef.current).length - 1) {
        setLayers((s) => ({ ...s, All: false }));
      } else {
        setLayers((s) => ({ ...s, All: true }));
      }
    }

    setFilter();
  };

  const setFilter = () => {
    const layer_ids = map.getStyle().layers.map((l) => l.id);
    for (var i = 0; i < layer_ids.length; i++) {
      var currentFilters = map.getFilter(layer_ids[i]);
      if (currentFilters !== undefined) {
        var newFilter = currentFilters;
        if (newFilter[0] === '==') {
          newFilter = ['all', ...[newFilter]];
        }

        Object.keys(layersRef.current).forEach((key) => {
          if (key !== 'All') {
            if (layersRef.current[key] === false) {
              var found = false;
              newFilter.forEach((f) => {
                if (f.length === 3 && f[2] === key) {
                  found = true;
                }
              });
              if (found === false) {
                newFilter.push(['!=', 'user_static_type', key]);
              }
              if (
                ((layer_ids[i].includes('parking-id-labels') ||
                  layer_ids[i].includes('parking-front-line')) &&
                  key === Const.StaticObjectType.PARKING) ||
                (layer_ids[i].includes('required-dock-measurements') &&
                  key === Const.StaticObjectType.DOCK)
              ) {
                newFilter.push(['==', 'show', 'false']);
              }
            } else {
              var ind = -1;
              newFilter.forEach((f, i) => {
                if (f.length === 3 && f[2] === key) {
                  ind = i;
                }
              });
              if (ind !== -1) {
                newFilter.splice(ind, 1);
              }
              if (
                ((layer_ids[i].includes('parking-id-labels') ||
                  layer_ids[i].includes('parking-front-line')) &&
                  key === Const.StaticObjectType.PARKING) ||
                (layer_ids[i].includes('required-dock-measurements') &&
                  key === Const.StaticObjectType.DOCK)
              ) {
                var ind = -1;
                newFilter.forEach((f, i) => {
                  if (f.length === 3 && f[1] === 'show') {
                    ind = i;
                  }
                });
                if (ind !== -1) {
                  newFilter.splice(ind, 1);
                }
              }
            }
          }
        });

        if (layer_ids[i] !== 'background' && layer_ids[i] !== 'satellite') {
          map.setFilter(layer_ids[i], newFilter);
        }
      }
    }
  };

  /* When the user clicks on the button, 
  toggle between hiding and showing the dropdown content */
  const showLayersMenu = () => {
    document.getElementById('layersDropdown').classList.toggle('show');
  };

  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName('dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  };

  const button = (type) => {
    var id = Object.keys(Const.StaticObjectType).find(
      (v) => Const.StaticObjectType[v] === type
    );
    var color = Const.FeatureColor[id];
    var box = { width: '50px', length: '50px' };
    if (color !== undefined) {
      box.color = Const.FeatureColor[id.toUpperCase()];
    }
    return box;
  };

  return (
    <div className="dropdown layers">
      <button onClick={showLayersMenu} className="dropbtn">
        <FontAwesomeIcon icon={faLayerGroup} />
      </button>
      <div id="layersDropdown" className="dropdown-content">
        {Object.keys(layers).map((key) => (
          <label key={key}>
            <input
              key={key}
              id={key}
              type="checkbox"
              checked={layers[key]}
              onChange={handleFilterClick}
            />{' '}
            {key}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Layers;
