import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

import * as Const from '../constants';
import { create_feature } from '../utilities/Features';

// This is "subclassed" to allow adding additional properties
// and to set the default cursor to be crosshairs making it easier
// to pinpoint specific locations. This also updates the _coordinates property
// and forces the mode back to this so we can create multiple.

const PointMode = { ...MapboxDraw.modes.draw_point };

PointMode.onSetup = function (static_type) {
  var feature = create_feature(static_type, [], {});
  const point = this.newFeature(feature);

  this.addFeature(point);

  this.clearSelectedFeatures();
  // this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.map.getCanvas().style.cursor = 'crosshair';
  this.activateUIButton(Constants.types.POINT);

  this.setActionableState({
    trash: true,
  });

  return { point, static_type };
};

PointMode.onTap = PointMode.onClick = function (state, e) {
  this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat);
  state.point.properties._coordinates = [e.lngLat.lng, e.lngLat.lat];
  this.map.fire(Constants.events.CREATE, {
    features: [state.point.toGeoJSON()],
  });
  // Skip jumping back to simple_select and instead set the mode back to survey
  // this.changeMode(Constants.modes.SIMPLE_SELECT, {
  //   featureIds: [state.point.id],
  // });
  this.changeMode(Constants.modes.DRAW_POINT, state.static_type);
};

PointMode.onStop = function (state) {
  this.map.getCanvas().style.cursor = 'unset';
  this.activateUIButton();
  if (!state.point.getCoordinate().length) {
    this.deleteFeature([state.point.id], { silent: true });
  }
};

export default PointMode;
