import * as math from 'mathjs';
import * as turf from '@turf/turf';
import createKDTree from 'static-kdtree';

export function project_point_to_line(point, line, angle = 0, direction = 1) {
  var ab = math.subtract(line[1], line[0]);
  var ap = math.subtract(point, line[0]);
  var t = math.divide(math.dot(ap, ab), math.dot(ab, ab));
  var point_on_line = math.add(line[0], math.multiply(ab, t));
  t -=
    (direction *
      distance_between_points(point, point_on_line) *
      math.tan(angle) *
      math.norm(ab)) /
    math.dot(ab, ab);
  point_on_line = math.add(line[0], math.multiply(ab, t));
  return [point_on_line, t];
}

export function project_points_to_line(points, line, angle = 0, direction = 1) {
  if (points.length === 0) return [];
  var output = points.map(
    (p) => project_point_to_line(p, line, angle, direction)[0]
  );
  return output;
}

export function point_to_line_distance(point, line) {
  const [point_on_line, t] = project_point_to_line(point, line);
  return [distance_between_points(point_on_line, point), t];
}

export function project_offset_from_line(offset, line, t = 0) {
  var ab = math.subtract(line[1], line[0]);
  ab[2] = 0.0;
  var n = [-ab[1], ab[0], 0.0];
  var norm_length = math.norm(n);
  n = math.divide(n, norm_length);
  var p = math.add(line[0], math.multiply(ab, t));
  return math.add(p, math.multiply(offset, n));
}

export function distance_between_points(point1, point2) {
  if (point1.length === 2) {
    return Math.sqrt(
      Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2)
    );
  } else {
    return Math.sqrt(
      Math.pow(point1[0] - point2[0], 2) +
        Math.pow(point1[1] - point2[1], 2) +
        Math.pow(point1[2] - point2[2], 2)
    );
  }
}

export function interpolate_along_line(distance, line) {
  const vector = math.subtract(line[1], line[0]);
  const magnitude = math.norm(vector);
  return math.add(line[0], math.multiply(distance / magnitude, vector));
}

export function is_angle_between(target, angle1, angle2) {
  const angle_diff =
    (((angle2 - angle1) % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
  if (angle_diff >= Math.PI) {
    const tmp = angle1;
    angle1 = angle2;
    angle2 = tmp;
  }
  if (angle1 <= angle2) {
    return angle1 <= target && target <= angle2;
  } else {
    return target >= angle1 || target <= angle2;
  }
}

export function angle_diff(angle1, angle2) {
  return Math.atan2(Math.sin(angle1 - angle2), Math.cos(angle1 - angle2));
}

export function angle_between(line1, line2) {
  const vector1 = math.subtract(line1[1], line1[0]);
  const vector2 = math.subtract(line2[1], line2[0]);
  var a =
    math.dot(vector1, vector2) / (math.norm(vector1) * math.norm(vector2));
  a = Math.min(a, 1.0);
  return Math.acos(a);
}

export function percentage_along_line(point, line) {
  var ab = math.subtract(line[1], line[0]);
  var ap = math.subtract(point, line[0]);
  return math.divide(math.dot(ap, ab), math.dot(ab, ab));
}

export function order_points_along_line(points, line, weights = []) {
  if (points.length === 0) {
    return { points: points, weights: weights };
  }
  const projected_points = project_points_to_line(points, line);

  var result = Array.from(Array(projected_points.length).keys()).sort((a, b) =>
    percentage_along_line(projected_points[a], line) <
    percentage_along_line(projected_points[b], line)
      ? -1
      : (percentage_along_line(projected_points[b], line) <
          percentage_along_line(projected_points[a], line)) |
        0
  );
  var ordered_points = [];
  var ordered_weights = [];
  for (var i = 0; i < result.length; i++) {
    ordered_points.push(points[result[i]]);
    ordered_weights.push(weights[result[i]]);
  }
  return { points: ordered_points, weights: ordered_weights };
}

export function create_grid(line1, line2, width, angle) {
  var grid = [];
  var region_length =
    line2 !== undefined
      ? Math.max(
          distance_between_points(line1[0], line1[1]),
          distance_between_points(line2[0], line2[1])
        )
      : distance_between_points(line1[0], line1[1]);
  var corrected_width = width / Math.cos(angle);
  var n_spaces = parseInt(Math.ceil(region_length / corrected_width));
  for (var i = 0; i <= n_spaces; i++) {
    var point1 = interpolate_along_line(i * corrected_width, line1);
    grid.push(point1);
  }
  var point2 = project_points_to_line(grid, line2, angle);
  grid.push(...point2);
  return [grid, n_spaces];
}

export function estimate_angle(line1, line2, line1_points, line2_points) {
  const angle_decimation = (5.0 * Math.PI) / 180.0;
  const min_angle = (-60.0 * Math.PI) / 180.0;
  const max_angle = (60.0 * Math.PI) / 180.0;
  var max_count = 0;
  var min_std = Infinity;
  var angle = 0;
  for (var ang = min_angle; ang <= max_angle; ang += angle_decimation) {
    const projected_points = project_points_to_line(line2_points, line1, ang);
    const tree = createTree(line1_points);
    var closest_points = projected_points.map((p) =>
      nearest_neighbor(p, tree, 1, 3.0)
    );
    var count = closest_points.filter((inds) => inds.length === 1).length;
    if (count >= max_count) {
      var angles = line2_points.map((p, j) => {
        if (closest_points[j].length === 1) {
          return (
            Math.PI / 2 -
            angle_between([p, line1_points[closest_points[j][0]]], line1)
          );
        } else {
          return angle;
        }
      });
      angles = angles.filter((a) => a !== null);
      if (angles.length !== 0) {
        var std = math.std(angles);
        if (count !== max_count || (count === max_count && std < min_std)) {
          min_std = std;
          max_count = count;
          angle = math.median(angles);
        }
      }
    }
    tree.dispose();
  }
  return angle;
}

export function nearest_neighbor(point, tree, k = 1, max_distance = Infinity) {
  return tree.knn(point, k, max_distance);
}

export function createTree(points) {
  return createKDTree(points);
}
