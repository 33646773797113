import React, { useContext, useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as turf from '@turf/turf';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { DateTime } from 'react-datetime-bootstrap';

import AppContext from './AppContext';
import * as Const from '../../constants';
import { isValid, isBoolean } from '../../utilities/Validator';
import {
  create_feature,
  create_feature_properties,
} from '../../utilities/Features';
import { commonKeys } from '../../utilities/Utilities';
import { sort_points } from '../../utilities/Map';

const SidebarProperties = (props) => {
  const [rows, setRows] = useState([]);
  const [features, setFeatures] = useState(props.features);

  const { draw, map } = useContext(AppContext);

  useEffect(() => {
    setFeatures(props.features);
    var featureRows = getUniqueProperties(props.features)
      .map((r, i) => ({
        id: r,
        key: r,
        value: props.features[0].properties[r],
      }))
      .sort((a, b) => (a.key > b.key ? 1 : -1));
    if (
      props.features.every(
        (f) =>
          props.features[0].properties.static_type === f.properties.static_type
      )
    ) {
      const static_type_index = featureRows.findIndex(
        (r) => r.key === 'static_type'
      );
      if (static_type_index !== -1)
        featureRows.splice(0, 0, featureRows.splice(static_type_index, 1)[0]);
    } else {
      const static_type_index = featureRows.findIndex(
        (r) => r.key === 'static_type'
      );
      if (static_type_index !== -1) featureRows.splice(static_type_index, 1);
    }
    featureRows = [
      { id: rows.length, key: 'id', value: props.features[0].id },
      ...featureRows,
    ];
    setRows(featureRows);
  }, [props.features]);

  const getUniqueProperties = (features) => {
    var keys = [];
    if (features.length > 0) {
      keys = Object.keys(features[0].properties);
      for (var i = 1; i < features.length; i++) {
        keys = keys.filter((k) =>
          Object.keys(features[i].properties).includes(k)
        );
      }
      // Hide hidden properties
      keys = keys.filter((k) => k[0] !== '_');
    }
    return keys;
  };

  const onKeyChange = (e) => {
    let index = e.target.attributes.index.value;
    let fieldValue = e.target.value;

    const tempRows = [...rows];
    const tempObj = rows[index];
    if (tempObj.key !== fieldValue && tempObj.value !== '') {
      removeProperty(index);
    }
    tempObj.key = fieldValue;

    tempRows[index] = tempObj;
    setRows(tempRows);

    if (tempObj.key !== '') {
      for (var i = 0; i < props.features.length; i++) {
        if (tempObj.value !== '') {
          changeProperty(i, tempObj.key, tempObj.value);
        }
      }
    }
  };

  const onValueChange = (e) => {
    let index = e.target.attributes.index.value;
    var fieldValue = e.target.value;

    if (e.currentTarget.type === 'checkbox') fieldValue = e.target.checked;

    if (fieldValue === 'true' || fieldValue === 'false') {
      fieldValue = fieldValue === 'true';
    }

    const tempRows = [...rows];
    const tempObj = rows[index];

    tempObj.value = fieldValue;

    tempRows[index] = tempObj;
    setRows(tempRows);

    if (tempObj.key !== '') {
      for (var i = 0; i < props.features.length; i++) {
        changeProperty(i, tempObj.key, tempObj.value);
      }
    }
  };

  const changeProperty = (i, name, value) => {
    var featuresCopy = [...props.features];
    featuresCopy[i].properties[name] = value;
    if (isValid(featuresCopy[i])) {
      if (name === 'static_type')
        featuresCopy[i].properties = create_feature_properties(value);
      if (!isNaN(parseFloat(value))) {
        value = parseFloat(value);
      }
      if (isValid(featuresCopy[i])) {
        setFeatures(featuresCopy);
        draw.setFeatureProperty(featuresCopy[i].id, name, value);
        props.updateFeatures(featuresCopy);
      }
    } else {
      alert(
        'Not a valid ' + featuresCopy[i].properties.static_type + ' feature!'
      );
    }
  };

  const addProperty = () => {
    const item = {
      id: rows.length,
      key: '',
      value: '',
    };
    setRows([...rows, item]);
  };

  const removePropertyAtIndex = (e) => {
    let index = e.target.attributes.index.value;
    removeProperty(index);
  };

  const removeProperty = (index) => {
    const tempRows = [...rows];
    var key = rows[index].key;
    tempRows.splice(index, 1);
    setRows(tempRows);

    var featuresCopy = [...props.features];
    for (var i = 0; i < featuresCopy.length; i++) {
      delete featuresCopy[i].properties[key];
    }

    if (featuresCopy.map((f) => isValid(f)).every((v) => v === true)) {
      setFeatures(featuresCopy);
      props.updateFeatures(featuresCopy);
    }
  };

  const createPropertyControl = (
    i,
    static_type,
    key,
    value,
    disabled = false
  ) => {
    if (key === 'id') {
      return (
        <Form.Control
          index={i}
          type="text"
          size="sm"
          value={props.features.length === 1 ? value : 'Multiple Selected'}
          disabled={true}
        />
      );
      // } else if (key === 'timestamp') {
      //   return (
      //     <Form.Control
      //       type="date"
      //       size="sm"
      //       value={value}
      //       index={i}
      //       onChange={onValueChange}
      //     />
      //   );
      // return (
      //   <DateTime
      //     pickerOptions={{ format: 'YYYY-MM-DD hh:mm:ss:SSS a' }}
      //     value={value}
      //     index={i}
      //     onChange={onValueChange}
      //   />
      // );
    } else if (key === 'static_type') {
      return (
        <Form.Select
          type="select"
          title="static_type"
          value={value}
          onChange={onValueChange}
          disabled={disabled}
          index={i}
          size="sm"
        >
          {Object.keys(Const.StaticObjectType).map((type_key) => {
            return (
              <option key={type_key} value={Const.StaticObjectType[type_key]}>
                {Const.StaticObjectType[type_key]}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (
      static_type === Const.StaticObjectType.DRIVABLE_AREA &&
      key === 'access'
    ) {
      return (
        <Form.Select
          type="select"
          title="access"
          value={value}
          onChange={onValueChange}
          disabled={disabled}
          index={i}
          size="sm"
        >
          {Object.keys(Const.DrivableAreaType).map((type_key) => {
            return (
              <option key={type_key} value={Const.DrivableAreaType[type_key]}>
                {Const.DrivableAreaType[type_key]}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (
      static_type === Const.StaticObjectType.REGION &&
      key === 'type'
    ) {
      return (
        <Form.Select
          type="select"
          title="access"
          value={value}
          onChange={onValueChange}
          disabled={disabled}
          index={i}
          size="sm"
        >
          {Object.keys(Const.RegionType).map((type_key) => {
            return (
              <option key={type_key} value={Const.RegionType[type_key]}>
                {Const.RegionType[type_key]}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (isBoolean(value) === true) {
      return (
        <Form.Check
          type="checkbox"
          checked={value}
          onChange={onValueChange}
          index={i}
          disabled={disabled}
        />
      );
    } else {
      return (
        <Form.Control
          type="text"
          size="sm"
          value={value}
          onChange={onValueChange}
          index={i}
          disabled={disabled}
        />
      );
    }
  };

  const createRow = (i, static_type, key, value, disabled = false) => {
    return (
      <Form.Group as={Row} className="mb-2" controlId={key} key={i}>
        <Col xs={5}>
          <Form.Control
            type="text"
            size="sm"
            index={i}
            value={key}
            onChange={onKeyChange}
            disabled={key === 'id' ? true : false}
          />
          {/*{key}*/}
        </Col>
        <Col xs>
          {createPropertyControl(
            i,
            props.features[0].properties.static_type,
            key,
            value
          )}
        </Col>
        <Col xs={1}>
          {key !== 'id' && (
            <CloseButton size="sm" index={i} onClick={removePropertyAtIndex} />
          )}
        </Col>
      </Form.Group>
    );
  };

  const form = () => {
    return (
      <div>
        <h2>Properties</h2>
        <Form>
          <Form.Group as={Row} className="mb-2">
            <Col xs={5}>Name</Col>
            <Col xs>Value</Col>
            <Col xs={1}></Col>
          </Form.Group>
          {rows.map((row, i) => {
            return createRow(
              i,
              props.features[0].properties.static_type,
              row.key,
              row.value
            );
          })}
          <Button onClick={addProperty}>Add Property</Button>
          {props.features.every(
            (f) =>
              f.properties.static_type ===
              props.features[0].properties.static_type
          ) && (
            <QuickActionButtons
              static_type={props.features[0].properties.static_type}
              features={props.features}
            />
          )}
        </Form>
      </div>
    );
  };

  return rows.length > 0 && form();
};

const QuickActionButtons = (props) => {
  const { draw } = useContext(AppContext);

  const add_center_line = () => {
    const sorted_points = sort_points(props.features);
    var polygons = [];
    for (var i = 1; i < sorted_points.length; i++) {
      var line = turf.lineString([
        sorted_points[i - 1].geometry.coordinates,
        sorted_points[i].geometry.coordinates,
      ]);
      var offsetLine1 = turf.lineOffset(line, -Const.laneCenterlineWidth / 2, {
        units: 'meters',
      });
      var offsetLine2 = turf.lineOffset(line, Const.laneCenterlineWidth / 2, {
        units: 'meters',
      });
      var fc = turf.featureCollection([
        turf.point(offsetLine1.geometry.coordinates[0]),
        turf.point(offsetLine1.geometry.coordinates[1]),
        turf.point(offsetLine2.geometry.coordinates[0]),
        turf.point(offsetLine2.geometry.coordinates[1]),
      ]);
      var polygon = turf.convex(fc);

      var obstacle = create_feature(
        Const.StaticObjectType.OBSTACLE,
        polygon.geometry.coordinates
      );
      polygons.push(obstacle);
    }
    polygons.forEach((f) => draw.add(f));
  };

  if (
    props.static_type === Const.StaticObjectType.SURVEY &&
    props.features.length > 2
  ) {
    return (
      <>
        <Button onClick={() => document.getElementById('obstacle').click()}>
          Create Obstacle
        </Button>
        <Button onClick={() => add_center_line()}>Create Center Line</Button>
      </>
    );
  }
  if (props.static_type === Const.StaticObjectType.PERIMETER) {
    return (
      <Button onClick={() => document.getElementById('obstacle').click()}>
        Create Obstacle Border
      </Button>
    );
  }
  if (props.static_type === Const.StaticObjectType.PARKING) {
    return (
      <Button onClick={() => document.getElementById('dock').click()}>
        Create Dock(s)
      </Button>
    );
  }
  if (props.static_type === Const.StaticObjectType.DOCK) {
    return (
      <Button onClick={() => document.getElementById('parking').click()}>
        Create Parking Space(s)
      </Button>
    );
  }
  return <></>;
};

export default SidebarProperties;
