import * as Const from '../constants';

export async function convert_to_latlng(
  data,
  input_epsg,
  output_epsg = Const.defaultEPSG
) {
  data.input_epsg = parseInt(input_epsg);
  data.output_epsg = parseInt(output_epsg);
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': process.env.REACT_APP_BACKEND_URL,
    },
    body: JSON.stringify(data),
  };
  const url = process.env.REACT_APP_BACKEND_URL + '/api/convert/';
  return fetch(url, requestOptions).then((response) => response.json());
}
