export const autoSaveDuration = 60000; // milliseconds
export const zoomToMapOffset = {
  padding: {
    top: 100,
    right: 50,
    bottom: 50,
    left: 50,
  },
};
export const defaultEPSG = 9056; // 9057?

export const perimeterObstacleWidth = 2.0;
export const laneCenterlineWidth = 0.2;

export const defaultCenter = [-98.15866350172729, 39.68918276116969];
export const defaultZoom = 4;

export const Color = {
  RED: '#e84118',
  PINK: '#ff00ff',
  GREEN: '#44bd32',
  NEON_GREEN: '#c8ff00',
  BLUE: '#487eb0',
  ORANGE: '#ff9f43',
  PURPLE: '#8c7ae6',
  GRAY: '#2f3640',
  YELLOW: '#fbc531',
  BLACK: '#000',
  WHITE: '#FFF',
};

export const StaticObjectType = {
  BUILDING: 'Building',
  DOCK: 'Dock',
  DRIVABLE_AREA: 'DrivableArea',
  OBSTACLE: 'Obstacle',
  PATH: 'Path',
  PARKING: 'Parking',
  PARKING_REGION: 'ParkingRegion',
  PERIMETER: 'Perimeter',
  REGION: 'Region',
  SURVEY: 'Survey',
  STOP: 'Stop',
  DEBUG: 'Debug',
};

export const DrivableAreaType = {
  OPEN: 'open',
  RESTRICTED: 'restricted',
};

export const RegionType = {
  WALKWAY: 'walkway',
};

export const FeatureColor = {
  BUILDING: Color.PURPLE,
  DOCK: Color.BLUE,
  DRIVABLE_AREA: Color.GREEN,
  OBSTACLE: Color.BLUE,
  PARKING: Color.RED,
  PARKING_REGION: Color.GRAY,
  PATH: Color.GRAY,
  PERIMETER: Color.YELLOW,
  REGION: Color.ORANGE,
  SURVEY: Color.RED,
  STOP: Color.RED,
  DEBUG: Color.ORANGE,
  DEFAULT: Color.YELLOW,
};

export const CompareMapColor = {
  FIRST: Color.BLUE,
  SECOND: Color.RED,
};

export const STOP_NUMBER_OF_POINTS = 2;
export const DOCK_NUMBER_OF_POINTS = 3;
export const PARKING_NUMBER_OF_POINTS = 5;
export const SURVEY_NUMBER_OF_POINTS = 1;
export const POLYGON_MINIMUM_NUMBER_OF_POINTS = 3;

const HERE_ACCESS_TOKEN = process.env.REACT_APP_HERE_ACCESS_TOKEN;
export const tileSources = [
  {
    label: 'Mapbox',
    styleName: 'Mapbox',
    styleUrl: 'mapbox://styles/mapbox/satellite-streets-v11',
  },
  {
    label: 'Google',
    styleName: 'Google',
    styleUrl: {
      version: 8,
      sources: {
        google: {
          type: 'raster',
          tiles: [
            'https://mt.google.com/vt/lyrs=y@134&hl=en&src=api&x={x}&y={y}&z={z}',
          ],
          tileSize: 256,
          minzoom: 0,
          maxzoom: 22,
        },
      },
      layers: [
        {
          id: 'google',
          type: 'raster',
          source: 'google',
        },
      ],
      sprite: 'mapbox://sprites/mapbox/satellite-streets-v11',
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    },
  },
  {
    label: 'Bing',
    styleName: 'Bing',
    styleUrl: {
      version: 8,
      sources: {
        bing: {
          type: 'raster',
          tiles: [
            'https://ecn.t0.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
            'https://ecn.t1.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
            'https://ecn.t2.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
            'https://ecn.t3.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
          ],
          tileSize: 256,
          minzoom: 0,
          maxzoom: 20,
        },
      },
      layers: [
        {
          id: 'bing',
          type: 'raster',
          source: 'bing',
        },
      ],
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    },
  },
  {
    label: 'HERE',
    styleName: 'HERE',
    styleUrl: {
      version: 8,
      sources: {
        here: {
          type: 'raster',
          tiles: [
            `https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=${HERE_ACCESS_TOKEN}`,
          ],
          tileSize: 256,
          minzoom: 0,
          maxzoom: 20,
        },
      },
      layers: [
        {
          id: 'here',
          type: 'raster',
          source: 'here',
        },
      ],
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    },
  },
  {
    label: 'ArcGIS',
    styleName: 'ArcGIS',
    styleUrl: {
      version: 8,
      sources: {
        arcgis: {
          type: 'raster',
          tiles: [
            `https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
          ],
          tileSize: 256,
          minzoom: 0,
          maxzoom: 19,
        },
      },
      layers: [
        {
          id: 'arcgis',
          type: 'raster',
          source: 'arcgis',
        },
      ],
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    },
  },
];
