import { useState } from 'react';
import AppContext from './components/js/AppContext';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import Header from './components/js/Header';
import Map from './components/js/Map';
import KeyboardShortcuts from './components/js/KeyboardShortcuts';
import useUndoableState from './components/js/UndoableState';

import * as turf from '@turf/turf';

import * as Const from './constants';

const App = () => {
  const [isMapboxLoaded, setIsMapboxLoaded] = useState(false);
  const [selectedToolbarButton, setSelectedToolbarButton] = useState('select');
  const [selectedMenuButton, setSelectedMenuButton] = useState('');
  const [map, setMap] = useState(null);
  const [draw, setDraw] = useState(null);
  const [autoSave, setAutoSave] = useState(false);
  const [currentFileName, setCurrentFileName] = useState('');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState(
    turf.featureCollection([])
  );

  const {
    state: historyState,
    setState: setHistory,
    getState: getHistory,
    resetState: resetHistory,
    index: historyStateIndex,
    lastIndex: historyStateLastIndex,
    statesLength: historyLength,
    goBack: undoHistory,
    goForward: redoHistory,
  } = useUndoableState();

  const userSettings = {
    isMapboxLoaded: isMapboxLoaded,
    map: map,
    draw: draw,
    selectedToolbarButton: selectedToolbarButton,
    selectedMenuButton: selectedMenuButton,
    selectedFeatures: selectedFeatures,
    autoSave: autoSave,
    currentFileName: currentFileName,
    openSidebar: openSidebar,
    setIsMapboxLoaded: setIsMapboxLoaded,
    setMap: setMap,
    setDraw: setDraw,
    setSelectedToolbarButton: setSelectedToolbarButton,
    setSelectedMenuButton: setSelectedMenuButton,
    setSelectedFeatures: setSelectedFeatures,
    setAutoSave: setAutoSave,
    setCurrentFileName: setCurrentFileName,
    setOpenSidebar: setOpenSidebar,
    historyState: historyState,
    setHistory: setHistory,
    getHistory: getHistory,
    resetHistory: resetHistory,
    historyStateIndex: historyStateIndex,
    historyStateLastIndex: historyStateLastIndex,
    historyLength: historyLength,
    undoHistory: undoHistory,
    redoHistory: redoHistory,
  };

  return (
    <AppContext.Provider value={userSettings}>
      {
        <Container className="p-0 d-flex flex-column min-100" fluid>
          <KeyboardShortcuts />
          <Row className="g-0">
            <Header setOpenSidebar={setOpenSidebar} />
          </Row>
          <Row className="g-0 flex-grow-1">
            <Map openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
          </Row>
        </Container>
      }
    </AppContext.Provider>
  );
};

export default App;
