export function readFile(file) {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.onload = (x) => resolve(fr.result);
    fr.onerrror = reject;
    fr.readAsText(file);
  });
}

export function commonKeys(a, b) {
  return Object.keys(a).filter(function (key) {
    return b.hasOwnProperty(key);
  });
}

export function rmse(array) {
  const square = array.map((val) => val * val);

  // Function reduce the array to a value
  // Here, all the elements gets added to the first
  // element which acted as the accumulator initially.
  const sum = square.reduce((acum, val) => acum + val);

  const mean = sum / array.length;
  return Math.sqrt(mean);
}

export function groupBy(array, prop) {
  const get = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);
  return array.reduce((acc, d) => {
    if (Object.keys(acc).includes(get(d, prop))) return acc;
    acc[get(d, prop)] = array.filter((g) => get(g, prop) === get(d, prop));
    return acc;
  }, {});
}

export function sortStringNumbers(a, b) {
  return Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]);
}
