import * as proj4 from 'proj4-fully-loaded';
import * as math from 'mathjs';
import * as turf from '@turf/turf';

import { rotation } from './Transform';
import * as Const from '../constants';

proj4.defs([
  [
    'EPSG:4326',
    '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
  ],
  [
    'EPSG:4978',
    '+title=WGS 84 (long/lat) +proj=geocent +ellps=WGS84 +datum=WGS84 +units=m',
  ],
]);

export function geodetic2enu(lng_lat_alt, origin) {
  const lng_lat_alt_ = [
    ...lng_lat_alt,
    ...(lng_lat_alt.length === 2 ? [0.0] : []),
  ];
  const origin_ = [...origin, ...(origin.length === 2 ? [0.0] : [])];

  const ecef_origin = proj4.default('EPSG:4326', 'EPSG:4978').forward(origin_);
  const ecef = proj4.default('EPSG:4326', 'EPSG:4978').forward(lng_lat_alt_);
  var diffxyz = math.subtract(ecef, ecef_origin);
  diffxyz = [...diffxyz, 1.0];

  const R1 = rotation(turf.degreesToRadians(90 + origin_[0]), 2);
  const R2 = rotation(turf.degreesToRadians(90 - origin_[1]), 0);
  const R = math.multiply(R2, R1);
  const enu = math.multiply(R, diffxyz)._data.slice(0, 3);
  return enu;
}

export function enu2geodetic(enu, origin) {
  const enu_ = [
    ...enu,
    ...(enu.length === 2 ? [0.0, 1.0] : []),
    ...(enu.length === 3 ? [1.0] : []),
  ];
  const origin_ = [...origin, ...(origin.length === 2 ? [0.0] : [])];

  var ecef_origin = proj4.default('EPSG:4326', 'EPSG:4978').forward(origin_);
  ecef_origin = [...ecef_origin, 1.0];
  const R1 = rotation(turf.degreesToRadians(90 + origin_[0]), 2);
  const R2 = rotation(turf.degreesToRadians(90 - origin_[1]), 0);
  const R = math.multiply(R2, R1);
  const ecef = math
    .add(ecef_origin, math.multiply(math.inv(R), enu_)._data)
    .slice(0, 3);
  const geodetic = proj4.default('EPSG:4326', 'EPSG:4978').inverse(ecef);
  return geodetic;
}

export function pointFeaturesToENU(features, origin) {
  var points = [];
  for (var i = 0; i < features.length; i++) {
    const point = geodetic2enu(features[i].geometry.coordinates, origin);
    points.push(point);
  }
  return points;
}

export function lineStringFeaturesToENU(features, origin) {
  var lines = [];
  for (var i = 0; i < features.length; i++) {
    const line = features[i].geometry.coordinates.map((c) =>
      geodetic2enu(c, origin)
    );
    lines.push(line);
  }
  return lines;
}

export function polygonFeaturesToENU(features, origin) {
  var polygons = [];
  for (var i = 0; i < features.length; i++) {
    const polygon = features[i].geometry.coordinates[0].map((c) =>
      geodetic2enu(c, origin)
    );
    polygons.push(polygon);
  }
  return polygons;
}
