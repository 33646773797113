import React, { useContext, useRef, useEffect } from 'react';
import * as turf from '@turf/turf';

import AppContext from './AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMousePointer,
  faStopCircle,
  faMapPin,
  faParking,
  faObjectGroup,
  faObjectUngroup,
  faTrash,
  faBuilding,
  faDrawPolygon,
  faBezierCurve,
  faTruckPickup,
  faTruckLoading,
  faTimesCircle,
  faSyncAlt,
  faArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';

import { TxCenter } from 'mapbox-gl-draw-rotate-scale-rect-mode';
import { smallestSurroundingRectangleByArea } from 'geojson-minimum-bounding-rectangle';

import {
  create_dock_for_parking_space,
  create_parking_spaces_for_docks,
  create_feature,
} from '../../utilities/Features';
import { geodetic2enu, enu2geodetic } from '../../utilities/Geodetic';
import { project_offset_from_line } from '../../utilities/Geometry';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as Const from '../../constants';
import '../css/Toolbar.css';

const Toolbar = () => {
  const {
    isMapboxLoaded,
    map,
    draw,
    selectedToolbarButton,
    setSelectedToolbarButton,
  } = useContext(AppContext);

  useEffect(() => {
    if (isMapboxLoaded) {
      var button = primaryButtons.filter((b) => b.id === selectedToolbarButton);
      if (button.length === 0) {
        button = secondaryButtons.filter((b) => b.id === selectedToolbarButton);
      }
      button[0].ref.current.click();
    }
  }, [selectedToolbarButton]);

  const handleSelectClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(Constants.modes.SIMPLE_SELECT, {});
  };

  const handleObstacleClick = (e) => {
    const ids = draw.getSelectedIds();
    // Just create an obstacle like normal
    if (ids.length === 0) {
      setSelectedToolbarButton(e.target.id);
      draw.changeMode(
        Constants.modes.DRAW_POLYGON,
        Const.StaticObjectType.OBSTACLE
      );
      // Check if the user has selected a Perimeter type
    } else {
      const perimeter_ids = ids.filter(
        (i) =>
          draw.get(i).properties.static_type ===
          Const.StaticObjectType.PERIMETER
      );
      const survey_ids = ids.filter(
        (i) =>
          draw.get(i).properties.static_type === Const.StaticObjectType.SURVEY
      );
      const building_ids = ids.filter(
        (i) =>
          draw.get(i).properties.static_type === Const.StaticObjectType.BUILDING
      );
      const obstacle_ids = ids.filter(
        (i) =>
          draw.get(i).properties.static_type ===
            Const.StaticObjectType.OBSTACLE &&
          (draw.get(i).properties.disabled === false ||
            draw.get(i).properties.disabled === undefined)
      );
      if (perimeter_ids.length === 1) {
        if (window.confirm('Create Obstacle(s) around Perimeter?')) {
          var all = draw.getAll();
          var current_selected_obstacles = obstacle_ids.map((i) => draw.get(i));
          const perimeter = draw.get(perimeter_ids[0]);
          const perimeter_as_linestring = turf.polygonToLine(
            turf.rewind(perimeter)
          );
          var polygons = [];
          for (
            var i = 1;
            i < perimeter_as_linestring.geometry.coordinates.length;
            i++
          ) {
            var origin = perimeter_as_linestring.geometry.coordinates[i - 1];
            var p1 = geodetic2enu(
              perimeter_as_linestring.geometry.coordinates[i - 1],
              origin
            );
            var p2 = geodetic2enu(
              perimeter_as_linestring.geometry.coordinates[i],
              origin
            );
            var line = [p1, p2];
            var p3 = project_offset_from_line(
              -Const.perimeterObstacleWidth,
              line,
              0.0
            );
            var p4 = project_offset_from_line(
              -Const.perimeterObstacleWidth,
              line,
              1.0
            );

            var pts = [p1, p2, p3, p4].map((p) =>
              turf.point(enu2geodetic(p, origin).slice(0, 2))
            );
            var fc = turf.featureCollection(pts);
            var polygon = turf.convex(fc);
            var obstacle = create_feature(
              Const.StaticObjectType.OBSTACLE,
              polygon.geometry.coordinates
            );
            if (obstacle_ids.length > 0) {
              var overlaps = current_selected_obstacles.some(
                (f) =>
                  turf.booleanOverlap(f, obstacle) &&
                  !turf.booleanContains(perimeter, obstacle)
              );
              if (overlaps) {
                polygons.push(obstacle);
              }
            } else {
              polygons.push(obstacle);
            }
          }

          var current_obstacles;
          if (obstacle_ids.length > 0) {
            current_obstacles = current_selected_obstacles;
          } else {
            current_obstacles = all.features.filter(
              (f) =>
                f.properties.static_type === Const.StaticObjectType.OBSTACLE &&
                (f.properties.disabled === false ||
                  f.properties.disabled === undefined)
            );
          }
          var ids_to_remove = current_obstacles
            .filter((f1) =>
              polygons.some((f2) => {
                var overlap = null;
                if (turf.booleanOverlap(f1, f2)) {
                  var intersection = turf.intersect(f1, f2);
                  if (intersection !== null) {
                    overlap = turf.area(intersection) / turf.area(f1);
                  }
                }
                return overlap !== null && overlap > 0.8;
              })
            )
            .map((f) => f.id);

          draw.delete(ids_to_remove);
          polygons.forEach((f) => draw.add(f));
        }
      }
      if (survey_ids.length >= 3 && obstacle_ids.length === 0) {
        const objects = survey_ids.map((i) => draw.get(i));
        const fc = turf.featureCollection(objects);
        const polygon = turf.convex(fc, { maxEdge: 1 });
        var obstacle = create_feature(
          Const.StaticObjectType.OBSTACLE,
          polygon.geometry.coordinates
        );
        draw.add(obstacle);
      }
      if (building_ids.length === 1) {
        if (window.confirm('Create Obstacle for Building?')) {
          const objects = building_ids.map((i) => draw.get(i));
          const fc = turf.featureCollection(objects);
          const polygon = smallestSurroundingRectangleByArea(fc);
          var obstacle = create_feature(
            Const.StaticObjectType.OBSTACLE,
            polygon.geometry.coordinates
          );
          draw.add(obstacle);
        }
      }
    }
  };

  const handlePerimeterClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(
      Constants.modes.DRAW_POLYGON,
      Const.StaticObjectType.PERIMETER
    );
  };

  const handleRegionClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(
      Constants.modes.DRAW_POLYGON,
      Const.StaticObjectType.REGION
    );
  };

  const handleBuildingClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(
      Constants.modes.DRAW_POLYGON,
      Const.StaticObjectType.BUILDING
    );
  };

  const handleDrivableAreaClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(
      Constants.modes.DRAW_POLYGON,
      Const.StaticObjectType.DRIVABLE_AREA
    );
  };

  const handlePathClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    // draw.changeMode('path');
  };

  const handleDockClick = (e) => {
    const ids = draw.getSelectedIds();
    // Just create a dock like normal
    if (ids.length === 0) {
      setSelectedToolbarButton(e.target.id);
      draw.changeMode(Constants.modes.DRAW_LINE_STRING, {
        static_type: Const.StaticObjectType.DOCK,
        point_limit: Const.DOCK_NUMBER_OF_POINTS,
      });
      // Check if the user has selected either parking region(s) or specific parking spaces
    } else {
      const parking_region_ids = ids.filter(
        (i) =>
          draw.get(i).properties.static_type ===
          Const.StaticObjectType.PARKING_REGION
      );
      var parking_spaces = [];
      // If they have selected one or more parking regions, select the parking spaces within them
      if (parking_region_ids.length !== 0) {
        const parking_regions = parking_region_ids.map((i) => draw.get(i));
        for (var i = 0; i < parking_regions.length; i++) {
          const p_spaces = draw
            .getAll()
            .features.filter(
              (f) =>
                f.properties.static_type === Const.StaticObjectType.PARKING &&
                turf.booleanContains(parking_regions[i], f)
            );
          parking_spaces.push(...p_spaces);
        }
        // Otherwise the user has selected parking spaces. Select those features.
      } else {
        const parking_space_ids = ids.filter(
          (i) =>
            draw.get(i).properties.static_type ===
            Const.StaticObjectType.PARKING
        );
        parking_spaces = parking_space_ids.map((i) => draw.get(i));
      }
      // Now create Dock features for each selected parking space.
      if (parking_spaces.length !== 0) {
        if (
          window.confirm(
            'Create ' +
              parking_spaces.length +
              ' Dock(s)? This will replace current docks for selected features.'
          )
        ) {
          // Remove old Docks for selected parking spaces
          const parking_ids = parking_spaces.map((p) => p.id);
          draw.getAll().features.map((f) => {
            if (
              f.properties.static_type === Const.StaticObjectType.DOCK &&
              parking_ids.includes(f.properties.parking_id) &&
              f.properties.disabled === false
            ) {
              draw.delete(f.id);
            }
            // Correct Dock door coordinates to parking space
            // if (f.properties.static_type === Const.StaticObjectType.DOCK && parking_ids.includes(f.properties.parking_id)) {
            //   const parking_space = draw.get(f.properties.parking_id);
            //   // if (turf.distance(parking_space.geometry.coordinates[0][1], f.geometry.coordinates[0], { 'units': 'meters' }) <
            //   //     turf.distance(parking_space.geometry.coordinates[0][1], f.geometry.coordinates[2], { 'units': 'meters' })) {
            //   //   console.log("reversing")
            //     f.geometry.coordinates.reverse();
            //     draw.add(f);
            //   // }
            // }
          });

          var dock_ids = [];
          for (var i = 0; i < parking_spaces.length; i++) {
            var dock = create_dock_for_parking_space(parking_spaces[i]);
            const id_ = draw.add(dock);
            dock_ids.push(id_);
          }
          const docks = dock_ids.map((i) => draw.get(i));
          map.fire(Constants.events.CREATE, {
            features: docks,
          });
        }
      }
    }
  };

  const handleStopClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(Constants.modes.DRAW_LINE_STRING, {
      static_type: Const.StaticObjectType.STOP,
      point_limit: Const.STOP_NUMBER_OF_POINTS,
    });
  };

  const handleParkingDesignerClick = (e) => {
    const ids = draw.getSelectedIds();
    // Just enter the parking designer like normal
    if (ids.length === 0) {
      setSelectedToolbarButton(e.target.id);
      draw.changeMode('parking', Const.StaticObjectType.PARKING_REGION);
      // Check if the user has selected dock doors
    } else {
      const dock_ids = ids.filter(
        (i) =>
          draw.get(i).properties.static_type === Const.StaticObjectType.DOCK
      );
      // If they have selected one or more docks, generate parking spaces
      const docks = dock_ids.map((i) => draw.get(i));
      if (docks.length !== 0) {
        var result = create_parking_spaces_for_docks(docks);
        var parking_spaces = result[0];
        var updated_docks = result[1];
        var new_ids = [];
        for (var i = 0; i < parking_spaces.length; i++) {
          var id_ = draw.add(parking_spaces[i]);
          new_ids.push(id_);
          var id_ = draw.add(updated_docks[i]);
          new_ids.push(id_);
        }
        const new_features = new_ids.map((i) => draw.get(i));
        map.fire(Constants.events.CREATE, {
          features: new_features,
        });
      }
    }
  };

  const handleSurveyClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(Constants.modes.DRAW_POINT, Const.StaticObjectType.SURVEY);
  };

  const handleParkingRegionClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    draw.changeMode(
      Constants.modes.DRAW_POLYGON,
      Const.StaticObjectType.PARKING_REGION
    );
  };

  const handleRotateClick = (e) => {
    const _ids = draw.getSelectedIds();
    if (_ids.length === 1) {
      setSelectedToolbarButton(e.target.id);
      try {
        draw.changeMode('transform', {
          featureId: _ids[0],
          canScale: true,
          canRotate: true, // only rotation enabled
          canTrash: false, // disable feature delete
          rotatePivot: TxCenter.Center, // rotate around center
          scaleCenter: TxCenter.Opposite, // scale around opposite vertex
          singleRotationPoint: true, // only one rotation point
          rotationPointRadius: 1.2, // offset rotation point
          canSelectFeatures: true,
        });
      } catch (err) {
        alert('Select features to rotate');
        console.error(err);
      }
    }
  };

  const handleTransformClick = (e) => {
    setSelectedToolbarButton(e.target.id);
    // draw.changeMode('transform');
  };

  const handleTrashClick = (e) => {
    const _id = draw.getSelectedIds();
    draw.delete(_id);
  };

  const primaryButtons = [
    {
      id: 'select',
      title: 'Select',
      onClick: handleSelectClick,
      icon: faMousePointer,
      ref: useRef(null),
    },
    {
      id: 'obstacle',
      title: 'Create Obstacle',
      onClick: handleObstacleClick,
      icon: faTimesCircle,
      ref: useRef(null),
    },
    {
      id: 'perimeter',
      title: 'Create Perimeter',
      onClick: handlePerimeterClick,
      icon: faDrawPolygon,
      ref: useRef(null),
    },
    {
      id: 'region',
      title: 'Create Region',
      onClick: handleRegionClick,
      icon: faObjectUngroup,
      ref: useRef(null),
    },
    {
      id: 'building',
      title: 'Create Building',
      onClick: handleBuildingClick,
      icon: faBuilding,
      ref: useRef(null),
    },
    {
      id: 'drivable_area',
      title: 'Create Drivable Area',
      onClick: handleDrivableAreaClick,
      icon: faTruckPickup,
      ref: useRef(null),
    },
    {
      id: 'dock',
      title: 'Create Dock',
      onClick: handleDockClick,
      icon: faTruckLoading,
      ref: useRef(null),
    },
    {
      id: 'stop',
      title: 'Create Stop',
      onClick: handleStopClick,
      icon: faStopCircle,
      ref: useRef(null),
    },
    {
      id: 'parking',
      title: 'Parking Designer',
      onClick: handleParkingDesignerClick,
      icon: faParking,
      ref: useRef(null),
    },
    {
      id: 'survey',
      title: 'Create Survey Point',
      onClick: handleSurveyClick,
      icon: faMapPin,
      ref: useRef(null),
    },
    {
      id: 'parking_region',
      title: 'Create Parking Region',
      onClick: handleParkingRegionClick,
      icon: faObjectGroup,
      ref: useRef(null),
    },
  ];

  const secondaryButtons = [
    {
      id: 'rotate',
      title: 'Rotate',
      onClick: handleRotateClick,
      icon: faSyncAlt,
      ref: useRef(null),
    },
    // {
    //   id: 'transform',
    //   title: 'Transform',
    //   onClick: handleTransformClick,
    //   icon: faArrowsAlt,
    //   ref: useRef(null),
    // },
    {
      id: 'trash',
      title: 'Trash',
      onClick: handleTrashClick,
      icon: faTrash,
      ref: useRef(null),
    },
  ];

  return (
    <div className="toolbar-container">
      <div className="toolbar">
        {primaryButtons.map((button, key) => {
          return (
            <div
              key={key}
              className={
                selectedToolbarButton === button.id ? 'toolbar-active' : ''
              }
            >
              <button
                id={button.id}
                title={button.title}
                onClick={button.onClick}
                href="#"
                ref={button.ref}
              >
                <FontAwesomeIcon icon={button.icon} />
              </button>
            </div>
          );
        })}
      </div>
      <div className="toolbar">
        {secondaryButtons.map((button, key) => {
          return (
            <div
              key={key}
              className={
                selectedToolbarButton === button.id ? 'toolbar-active' : ''
              }
            >
              <button
                id={button.id}
                title={button.title}
                onClick={button.onClick}
                href="#"
                ref={button.ref}
              >
                <FontAwesomeIcon icon={button.icon} />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Toolbar;
