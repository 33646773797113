import React, { useContext, useEffect, useState, useRef } from 'react';
import useStateRef from 'react-usestateref';
import AppContext from './AppContext';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Card from 'react-bootstrap/Card';

import { convert_to_latlng } from '../../utilities/Survey';
import { create_feature, merge } from '../../utilities/Features';
import { readFile } from '../../utilities/Utilities';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as Const from '../../constants';

import * as turf from '@turf/turf';
import { parse } from 'csv-parse/browser/esm/sync';

const HEADER_FIELDS = {
  easting: ['east', 'easting', 'x'],
  northing: ['north', 'northing', 'y'],
  height: ['height', 'z'],
  description: ['name'],
  date: ['date'],
  time: ['time'],
};

const SidebarImportSurvey = (props) => {
  const { isMapboxLoaded, map, draw } = useContext(AppContext);

  const [state, setState] = useState({
    input_epsg: null,
    date: '',
    data: [],
    headers: [],
    columns: {
      description: null,
      easting: null,
      northing: null,
      height: null,
      date: null,
      time: null,
    },
  });

  const [epsgOptions, setEPSGOptions] = useState({
    isLoading: false,
    options: [],
  });

  useEffect(() => {
    if (isMapboxLoaded) {
      if (
        state.input_epsg !== null &&
        state.columns.easting !== null &&
        state.columns.northing !== null &&
        state.columns.height !== null
      ) {
        preview();
      } else {
        removeTempFeatures();
      }
    }
  }, [state.input_epsg, state.data, state.date, state.columns]);

  useEffect(() => () => removeTempFeatures(), []);

  const setColumns = (headers) => {
    var columns = {
      description: null,
      easting: null,
      northing: null,
      height: null,
      date: null,
      time: null,
    };
    Object.entries(HEADER_FIELDS).forEach(([key, names]) => {
      headers.forEach((header, i) => {
        var match = names.includes(header);
        if (names.includes(header.toLowerCase())) {
          columns[key] = header;
        }
      });
    });
    return columns;
  };

  const openFile = async (ev) => {
    ev.preventDefault();

    var headers = [];
    var columns = {};
    setState({
      input_epsg: null,
      date: '',
      data: [],
      headers: [],
      columns: {
        description: null,
        easting: null,
        northing: null,
        height: null,
        date: null,
        time: null,
      },
    });
    Promise.all(Array.from(ev.currentTarget.files).map(readFile))
      .then((result) => {
        var data = [];
        var headers = [];
        result.map((r) => {
          const d = parse(r, {
            skip_empty_lines: true,
          });

          // Check to see if first row only contains string values
          var has_header = d[0]
            .map((h) => !isNaN(h) && !isNaN(parseFloat(h)))
            .every((v) => v === false);

          if (headers.length === 0) {
            if (has_header) {
              headers = d[0];
            } else {
              headers = d[0].map((_, i) => `Column_${i + 1}`);
            }
          }

          if (has_header === true) {
            d.shift();
          }

          data.push(...d);
        });

        if (data.length > 0) {
          headers = headers.map((header, index) => {
            return {
              value: index,
              label: header,
            };
          });

          // Transpose data so each array represents a column of data
          data = data.reduce(
            (prev, next) =>
              next.map((item, i) => (prev[i] || []).concat(next[i])),
            []
          );

          // Convert each array to Numeric if numbers
          data.forEach((row, i) => {
            if (!isNaN(row[0]) && !isNaN(parseFloat(row[0]))) {
              data[i] = data[i].map((d) => parseFloat(d));
            }
          });

          // Create dictionary
          var tmp = {};
          headers.forEach((h, i) => {
            tmp[h.label] = data[i];
          });
          data = tmp;

          var columns = setColumns(Object.keys(data));

          setState({
            ...state,
            data: data,
            headers: headers,
            columns: columns,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const parseData = (data) => {
    var body = {
      x: [],
      y: [],
      z: [],
      time: [],
      description: [],
      timestamp: [],
    };
    var n = data[Object.keys(data)[0]].length;
    for (var i = 0; i < n; i++) {
      if (state.columns.description !== null) {
        body.description.push(data[state.columns.description][i]);
      }

      var timestamp = state.date !== '' ? new Date(state.date) : new Date();
      if (state.columns.date !== null && state.columns.time !== null) {
        const timeFix = data[state.columns.time][i].split(':');
        const dateFix = data[state.columns.date][i].split('-');
        timestamp = new Date(
          dateFix[0],
          dateFix[1] - 1,
          dateFix[2],
          timeFix[0],
          timeFix[1],
          timeFix[2]
        );
      } else if (state.columns.date !== null) {
        timestamp = Date.parse(data[state.columns.date][i]);
      }

      const t = parseFloat(
        timestamp.getFullYear().toString() +
          '.' +
          timestamp.getMonth().toString()
      );

      body.x.push(parseFloat(data[state.columns.easting][i]));
      body.y.push(parseFloat(data[state.columns.northing][i]));
      body.z.push(parseFloat(data[state.columns.height][i]));
      body.time.push(t);
      body.timestamp.push(timestamp);
    }
    return body;
  };

  const processFile = async () => {
    var body = parseData(state.data);

    return convert_to_latlng(body, parseInt(state.input_epsg))
      .then((coordinates) => {
        var features = [];
        if (coordinates.x !== undefined) {
          for (var i = 0; i < coordinates.x.length; i++) {
            var point = create_feature(
              Const.StaticObjectType.SURVEY,
              [coordinates.x[i], coordinates.y[i]],
              {
                annotated: false,
                timestamp: new Date(body.timestamp[i]),
                epsg: parseInt(state.input_epsg),
                description:
                  body.description.length > 0 ? body.description[i] : '',
                _coordinates: [body.x[i], body.y[i], body.z[i]],
              }
            );
            features.push(point);
          }
          return features;
        }
      })
      .catch((error) => console.error(error));
  };

  const zoomToMap = (collection = null) => {
    var bounds = turf.bbox(collection === null ? draw.getAll() : collection);
    map.fitBounds(bounds, {
      ...Const.zoomToMapOffset,
      animate: false,
    });
  };

  const handleChange = (e) => {
    var { name, value } = e.target;
    if (name === 'date') value = new Date(value);
    setState({ ...state, [name]: value });
  };

  const onAddClicked = () => {
    removeTempFeatures();
    processFile()
      .then((features) => {
        if (features !== undefined) {
          var collection = turf.featureCollection(features);
          var ids = draw.add(collection);
          map.fire('draw.create', {
            features: draw.getAll().features.filter((i) => ids.includes(i)),
          });
          props.setOpen(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const preview = () => {
    removeTempFeatures();
    processFile()
      .then((features) => {
        if (features !== undefined) {
          features.forEach((f) => {
            f.properties._tmp = true;
            f.properties.disabled = true;
          });
          var collection = turf.featureCollection(features);
          var ids = draw.add(collection);
          zoomToMap(collection);
        }
      })
      .catch((error) => console.error(error));
  };

  const onClearClicked = () => {
    removeTempFeatures();
  };

  const removeTempFeatures = () => {
    var to_remove = draw
      .getAll()
      .features.filter((f) => '_tmp' in f.properties);
    draw.delete(to_remove.map((f) => f.id));
  };

  const onMergeClicked = () => {
    removeTempFeatures();
    processFile()
      .then((features) => {
        if (features !== undefined) {
          const current_features = draw.getAll().features;
          features.forEach((f1, i) => {
            var match = current_features.filter(
              (f2) =>
                f2.geometry.type === Constants.geojsonTypes.POINT &&
                f2.properties.annotated === false &&
                turf.distance(
                  turf.point(f1.geometry.coordinates),
                  turf.point(f2.geometry.coordinates),
                  { units: 'meters' }
                ) < 0.05
            );

            if (match.length === 1) {
              const ignore = match[0].properties.ignore;
              match[0].properties = f1.properties;
              match[0].properties.ignore = ignore;
              draw.add(match[0]);
            } else {
              draw.add(f1);
            }
          });
          props.setOpen(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const onEPSGSearch = (query) => {
    setEPSGOptions({ isLoading: true });
    fetch(`https://epsg.io/?q=${query}&format=json`)
      .then((resp) => resp.json())
      .then((json) => {
        setEPSGOptions({
          isLoading: false,
          options: json.results,
        });
      });
  };

  const onEPSGChange = (selected) => {
    setState({
      ...state,
      input_epsg: selected.length !== 0 ? selected[0].code : null,
    });
  };

  const ColumnSelection = (props) => {
    var title = props.name;
    if (props.optional === true) {
      title += ' (optional)';
    }
    var selectedHeader = state.headers.filter(
      (h) => h.label === state.columns[props.name.toLowerCase()]
    );

    return (
      <Form.Floating className="mb-3">
        <FloatingLabel label={title}>
          <Form.Select
            value={selectedHeader.length !== 0 ? selectedHeader[0].value : ''}
            onChange={(selected) => {
              var selectedColumn = state.headers.filter(
                (h) => h.value === parseInt(selected.target.value)
              );
              setState({
                ...state,
                columns: {
                  ...state.columns,
                  [props.name.toLowerCase()]:
                    selectedColumn.length !== 0
                      ? selectedColumn[0].label
                      : null,
                },
              });
            }}
          >
            <option>Not Set</option>
            {state.headers.map((h) => {
              return (
                <option key={h.label} value={h.value}>
                  {h.label} - {state.data[h.label][0]}
                </option>
              );
            })}
          </Form.Select>
        </FloatingLabel>
      </Form.Floating>
    );
  };

  return (
    <div>
      <h2>Import Survey</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Control
            type="file"
            onChange={openFile}
            accept="text/csv,text/plain"
            multiple
          />
        </Form.Group>
        <fieldset disabled={state.data.length === 0}>
          <Form.Group className="mb-3">
            <AsyncTypeahead
              id="epsg"
              isLoading={epsgOptions.isLoading}
              placeholder="Search for EPSG by name or code"
              promptText="Search for EPSG by name or code"
              labelKey={(option) => `${option.code} - ${option.name}`}
              onSearch={onEPSGSearch}
              filterBy={['code', 'name', 'area']}
              options={epsgOptions.options}
              onChange={onEPSGChange}
              clearButton={true}
            />
          </Form.Group>
          <Form.Floating className="mb-3">
            <FloatingLabel label="Date Collected">
              <Form.Control
                type="date"
                name="date"
                value={
                  state.date !== '' ? state.date.toISOString().slice(0, 10) : ''
                }
                onChange={handleChange}
                size="sm"
              />
            </FloatingLabel>
          </Form.Floating>
          <Card>
            <Card.Header>Data Fields</Card.Header>
            <Card.Body>
              <ColumnSelection name="Easting" optional={false} />
              <ColumnSelection name="Northing" optional={false} />
              <ColumnSelection name="Height" optional={false} />
              <ColumnSelection name="Description" optional={true} />
            </Card.Body>
          </Card>
          <div className="mt-2">
            <Button
              variant="primary"
              onClick={onAddClicked}
              disabled={
                state.data.length === 0 ||
                state.input_epsg === null ||
                state.easting === null ||
                state.northing === null ||
                state.height === null
              }
            >
              Add
            </Button>
            {/*{' '}<Button
              variant="primary"
              onClick={onMergeClicked}
              disabled={
                state.data.length === 0 ||
                state.input_epsg === null ||
                state.easting === null ||
                state.northing === null ||
                state.height === null
              }
            >
              Merge
            </Button>*/}
          </div>
        </fieldset>
      </Form>
    </div>
  );
};

export default SidebarImportSurvey;
